import React, {Fragment} from 'react';
import "../style/style.css";
import { TransitionGroup, CSSTransition} from "react-transition-group";


class ApartmentMainImage extends React.Component{
    state = {
        inProp: false,
    };
    goHome = (event) => {
        event.preventDefault();
        this.props.history.push("/");
    };
    render(){

        return(
            <div style={{backgroundColor: 'black'}}>
                <TransitionGroup>
                    <CSSTransition classNames="apartmentSectionTitle0"
                                   key={"apartmentMainImage0"}
                                   timeout={{ enter: 1000, exit: 1000 }}
                                   in = {this.state.inProp}
                                   unmountOnExit={true}
                                   appear
                    ><div className="apartmentMainImage1"></div>
                    </CSSTransition>
                </TransitionGroup>
                <TransitionGroup>
                    <CSSTransition classNames="apartmentSectionTitle1"
                                   key={"apartmentMainImage1"}
                                   timeout={{ enter: 1000, exit: 1000 }}
                                   in = {this.state.inProp}
                                   unmountOnExit={true}
                                   appear
                    ><div className="apartmentMainImage2"></div>
                    </CSSTransition>
                </TransitionGroup>
                <TransitionGroup>
                    <CSSTransition classNames="apartmentSectionTitle2"
                                   key={"apartmentMainImage2"}
                                   timeout={{ enter: 1000, exit: 1000 }}
                                   in = {this.state.inProp}
                                   unmountOnExit={true}
                                   appear
                    ><div className="apartmentMainImage3"></div>
                    </CSSTransition>
                </TransitionGroup>
                <TransitionGroup>
                    <CSSTransition classNames="apartmentSectionTitle3"
                                   key={"apartmentMainImage3"}
                                   timeout={{ enter: 1000, exit: 1000 }}
                                   in = {this.state.inProp}
                                   unmountOnExit={true}
                                   appear
                    ><div className="apartmentMainImage4"></div>
                    </CSSTransition>
                </TransitionGroup>
                <TransitionGroup>
                    <CSSTransition classNames="apartmentSectionTitle4"
                                   key={"apartmentMainImage4"}
                                   timeout={{ enter: 1000, exit: 1000 }}
                                   in = {this.state.inProp}
                                   unmountOnExit={true}
                                   appear
                    ><div className="apartmentMainImage5"></div>
                    </CSSTransition>
                </TransitionGroup>
            </div>


        )
    }
}

export default ApartmentMainImage;