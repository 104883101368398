import React, {Fragment} from 'react';
import "../style/style.css";
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { isMobile } from 'react-device-detect';
import Expandable from '../shared/Expandable';


class TitleAndText extends React.Component{
    state = {
        inProp: false,
        fetching: true,
        sottopagine: [],
        expandText: false
    };

    async componentDidMount() {
        try {
            const result = await axios({
                url: 'https://backoffice.arboutiqueapartments.it/graphql',
                method: 'post',
                data: {
                    query: `
                        query {
                            sottopaginas {
                                id
                                Titolo
                                descrizione
                                identificativo
                                Titolo_en
                                descrizione_en
                            }
                        }
                    `
                }
            })
            this.setState({
                fetching: false,
                sottopagine: result.data.data.sottopaginas
            })
        } catch (err) {
            // console.log('error: ', err)
        }
    }

    componentDidUpdate(oldProps, oldState) {
        if (!this.state.fetching && this.state.fetching != oldState.fetching) {
            if (!isMobile) {
                this.setState({
                    divTopPosition: 
                    (window.innerHeight / 2) - (
                        ((this.divTitle.clientHeight + this.divText.clientHeight) + (this.divTitle2 ? (this.divTitle2.clientHeight + this.divText2.clientHeight) : 0)) / 2
                    )  
                })
            }
        }
    }

    toggleSubTitle = () => {
		this.setState({
			showSubTitle: !this.state.showSubTitle
		})
	}

    renderContent = (sectionName) => {
        const sottopagina = this.state.sottopagine.find(sottopagina => sottopagina.identificativo === sectionName)
        if (sottopagina) {
            if (sectionName === 'Servizi3' || sectionName === 'Servizi4' || sectionName === 'Servizi5') {
                const sections = sottopagina["descrizione".concat(this.props.lan === 'en' ? "_en" : "")].split("/ split /")
                const second = sections[1].split("</title>")
                if (isMobile) {
                    return (
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            {!this.state.expandText1 && <div
                                style={{color: 'white', textAlign: 'center', fontSize: 28, padding: 24}}
                            >
                                {sottopagina["Titolo".concat(this.props.lan === 'en' ? "_en" : "")]}
                            </div>}
                            <Expandable id={this.props.id+'_1'} in={this.state.expandText1}>
                                <div
                                    style={{padding: '36px 24px',
                                        fontSize: 16,
                                        backgroundColor: 'white', 
                                        color: '#91855D'}}
                                    dangerouslySetInnerHTML={{ __html: sections[0] }}
                                />
                            </Expandable>
                            <div
                                key={`expand_button1_${this.props.id}`}
                                onClick={() => this.setState({expandText1: !this.state.expandText1})}
                                style={{
                                    color: 'white',
                                    fontSize: 56,
                                    height: 56,
                                    width: 56,
                                    textAlign: 'center',
                                    transform: this.state.expandText1 ? 'rotate(45deg)' : 'rotate(90deg)',
                                    transition: 'transform 500ms ease-in-out',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginBottom: this.state.expandText1 ? 8 : 0,
                                    marginTop: this.state.expandText1 ? 8 : 0,
                                }}>
                                <img src={require("../img/plus.png")} style={{width: 40}} />
                            </div>
                            {!this.state.expandText2 && <div
                                ref={(divTitle) => this.divTitle2 = divTitle}
                                style={{color: 'white', textAlign: 'center', fontSize: 28, padding: 24}}>
                                {second[0].replace('<title>', '')}
                            </div>}
                            <Expandable id={this.props.id+'_2'} in={this.state.expandText2}>
                                <div
                                    style={{padding: '36px 24px',
                                        fontSize: 16,
                                        backgroundColor: 'white', 
                                        color: '#91855D'}}
                                    dangerouslySetInnerHTML={{ __html: second[1] }}
                                />
                            </Expandable>
                            <div
                                key={`expand_button2_${this.props.id}`}
                                onClick={() => this.setState({expandText2: !this.state.expandText2})}
                                style={{
                                    color: 'white',
                                    fontSize: 56,
                                    height: 56,
                                    width: 56,
                                    textAlign: 'center',
                                    transform: this.state.expandText2 ? 'rotate(45deg)' : 'rotate(90deg)',
                                    transition: 'transform 500ms ease-in-out',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: this.state.expandText2 ? 8 : 0,
                                }}>
                                <img src={require("../img/plus.png")} style={{width: 40}} />
                            </div>
                        </div>
                    )
                }
                return (
                    <div
                        className="particularSectionRightContainer"
                        style={{top: this.state.divTopPosition ? (isMobile ? this.state.divTopPosition - 32 : this.state.divTopPosition) : '35%'}}>
                        <div
                            ref={(divTitle) => this.divTitle = divTitle}
                            className="particularSectionTitle">
                            {sottopagina["Titolo".concat(this.props.lan === 'en' ? "_en" : "")]}
                        </div>
                        <div
                            ref={(divText) => this.divText = divText}
                            className="particularSectionText double"
                            style={{paddingTop: 24, color: isMobile ? '#91855D' : '#3f3f3f'}}
                            dangerouslySetInnerHTML={{ __html: sections[0] }}
                        />
                        <div
                            ref={(divTitle) => this.divTitle2 = divTitle}
                            className="particularSectionTitle"
                            style={{paddingTop: isMobile ? 24 : 48}}>
                            {second[0].replace('<title>', '')}
                        </div>
                        <div
                            ref={(divText) => this.divText2 = divText}
                            className="particularSectionText double"
                            style={{paddingTop: 24, color: isMobile ? '#91855D' : '#3f3f3f'}}
                            dangerouslySetInnerHTML={{ __html: second[1] }}
                        />
                    </div>
                )
            } else {
                if (isMobile) {
                    return (
                        <div key={`title_text_inner_${this.props.id}`}
                            style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            {!this.state.expandText && <div
                                key={`title_text_inner2_${this.props.id}`}
                                style={{color: 'white', textAlign: 'center', fontSize: 28, padding: 24}}
                            >
                                {sottopagina["Titolo".concat(this.props.lan === 'en' ? "_en" : "")]}
                            </div>}
                            <Expandable key={`expandable_${this.props.id}`} id={this.props.id} in={this.state.expandText}>
                                <div
                                    key={`expandable_text_${this.props.id}`}
                                    // ref={(divText) => this.divText = divText}
                                    // className="particularSectionText"
                                    style={{
                                        fontSize: 16,
                                        padding: '36px 24px',
                                        backgroundColor: 'white', 
                                        color: '#91855D'}}
                                    dangerouslySetInnerHTML={{
                                        __html: sottopagina["descrizione".concat(this.props.lan === 'en' ? "_en" : "")]
                                    }} />
                            </Expandable>
                            <div
                                key={`expand_button_${this.props.id}`}
                                onClick={() => this.setState({expandText: !this.state.expandText})}
                                style={{
                                    color: 'white',
                                    fontSize: 56,
                                    height: 56,
                                    width: 56,
                                    textAlign: 'center',
                                    transform: this.state.expandText ? 'rotate(45deg)' : 'rotate(90deg)',
                                    transition: 'transform 500ms ease-in-out',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: this.state.expandText ? 8 : 0,
                                }}>
                                <img src={require("../img/plus.png")} style={{width: 40}} />
                            </div>
                        </div>    
                    )
                }
                return (
                    <div
                        className="particularSectionRightContainer"
                        style={{top: this.state.divTopPosition ? (isMobile ? this.state.divTopPosition - 32 : this.state.divTopPosition) : '35%'}}>
                        <div
                            ref={(divTitle) => this.divTitle = divTitle}
                            className="particularSectionTitle">
                            {sottopagina["Titolo".concat(this.props.lan === 'en' ? "_en" : "")]}
                        </div>
                        {/* {!this.state.showSubTitle && <div
                            // className="particularSectionText"
                            onClick={this.toggleSubTitle}
                            style={{color: 'white', fontSize: 56, lineHeight: '32px', textAlign: 'center', marginTop: 160}}>
                            +
                        </div>} */}
                        {/* <Expandable in={this.state.showSubTitle}> */}
                            <div
                                ref={(divText) => this.divText = divText}
                                className="particularSectionText"
                                style={{
                                    paddingTop: isMobile ? 40 : 24,
                                    color: isMobile ? '#91855D' : '#3f3f3f'}}
                                dangerouslySetInnerHTML={{
                                    __html: sottopagina["descrizione".concat(this.props.lan === 'en' ? "_en" : "")]
                                }} />
                        {/* </Expandable> */}
                    </div>
                )
            }
        } else {
            return (
                <div>Testo non trovato</div>
            )
        }
    }

    render(){
        // console.log('section pres', this.props)
        // console.log('section pres', this.state)
        const { sectionName } = this.props
        const { fetching } = this.state
        return(
            <Fragment>
                {fetching
                    ? <div className="particularSectionPresentationRightText">
                        <Loader
                            type="Rings"
                            color="#91855D"
                            height={100}
                            width={100}
                        />
                    </div>
                    : <Fragment>
                        {this.renderContent(sectionName)}
                        
                </Fragment>}
            </Fragment>

        )
    }
}

export default TitleAndText;