import React, { Fragment } from "react";
import "../style/style.css";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "../style/test.css";
import Collapsible from "react-collapsible";
import { isMobile } from "react-device-detect";

class Apartment extends React.Component {
  state = {
    inProp: true,
  };
  goApt = (id) => {
    // event.preventDefault();
    this.props.history.push("/apartment/" + id);
  };
  render() {
    const { inProp } = this.state;
    const { apartments, show, triggerShow, index } = this.props;
    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition
            classNames={"apartmentSectionTitle" + this.props.index}
            key={"Apartments"}
            timeout={{ enter: 1000, exit: 1000 }}
            in={this.state.inProp}
            appear
            exit
            unmountOnExit={true}
          >
            {!isMobile ? (
              <Collapsible
                open={show === index}
                handleTriggerClick={() => triggerShow(index)}
                containerElementProps={this.props.floorName.replace(/\s+/g, "")}
                trigger={this.props.floorName}
                classParentString={this.props.floorName.replace(/\s+/g, "")}
                openedClassName={"pianoOpened"}
                transitionTime={300}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 200,
                    paddingBottom: 50,
                  }}
                >
                  <div
                    style={{ flex: 1, display: "flex", justifyContent: "space-around", width: 800 }}
                  >
                    {apartments.map((apartment) => (
                      <div style={{ flex: 1 }}>
                        <div
                          className="singleApartment"
                          onClick={() => this.goApt(apartment.identificativo)}
                          {...this.props}
                          backArrow={this.props.backArrow}
                        >
                          {apartment.tipo.split("cale")[0] + " " + apartment.identificativo}
                        </div>
                        <div
                          className="singleApartment"
                          style={{ textDecoration: "none", lineHeight: "20px" }}
                        >
                          {apartment.disponibile
                            ? this.props.lan === "en"
                              ? "Available"
                              : "Disponibile"
                            : ""}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Collapsible>
            ) : (
              <Collapsible
                open={show === index}
                handleTriggerClick={() => triggerShow(index)}
                trigger={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    {this.props.floorName}
                    <div
                      style={{
                        color: show === index ? "white" : "#91855d",
                        fontSize: 56,
                        textAlign: "center",
                        transform: show === index ? "rotate(45deg)" : "rotate(0deg)",
                        transition: "transform 500ms ease-in-out",
                      }}
                    >
                      +
                    </div>
                  </div>
                }
                classParentString={`pianoMobile`}
                openedClassName={"pianoOpened"}
                transitionTime={300}
              >
                {apartments.map((apartment) => (
                  <div style={{ flex: 1, marginBottom: 24 }}>
                    <div
                      className="singleApartmentMobile"
                      onClick={() => this.goApt(apartment.identificativo)}
                      {...this.props}
                      backArrow={this.props.backArrow}
                    >
                      {apartment.tipo.split("cale")[0] + " " + apartment.identificativo}
                    </div>
                    <div
                      className="singleApartmentMobile"
                      style={{ textDecoration: "none", lineHeight: "20px" }}
                    >
                      {apartment.disponibile ? "Disponibile" : ""}
                    </div>
                  </div>
                ))}
              </Collapsible>
            )}
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}

export default Apartment;
