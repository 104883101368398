import React, {Fragment} from 'react';
import Header from "./Header";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import "../style/style.css"
import RightArrow from "./RightArrow";
import { TransitionGroup, CSSTransition} from "react-transition-group";
import Logo from "./Logo";
import DownArrow from "./DownArrow";
import BackArrow from "./BackArrow";
import GoogleMapReact from "google-map-react"
import MapMenu from "./MapMenu";
import TitleAndText from "./TitleAndText";
import Map from './Map';
import LanguageSelectorRelative from "./LanguageSelectorRelative";
import axios from 'axios';
import Loader from 'react-loader-spinner';
import places from '../json config file/places.json';
import MainHeader from './MainHeader'
import Footer from './Footer'
import { isMobile } from 'react-device-detect';


class CioCheServe extends React.Component {
    static defaultProps = {
        center: {
            lat: 45.690910,
            lng: 9.672650
        },
        zoom: 11
    };
    state = {
        previousPage: this.props.previousPage,
        nextPage: this.props.nextPage,
        backArrow :this.props.BackArrow,
        downArrow: this.props.downArrow,
        loading: true,
        tipo: 'places',
        places: [],
        banche: [],
        supermarkets: [],
        farmacie: [],
        ospedali: [],
        cinema: []
    };

    nextIndex = () => {
        const { nextPage } = this.props;
        this.props.history.push("/"+nextPage)
    };

    prevIndex = () => {
        const { previousPage } = this.props;
        this.props.history.push("/"+previousPage)
    };

    async componentDidMount() {
        this.setState({
            places: [
                ...places.banche,
                ...places.farmacie,
                ...places.cinemas,
                ...places.ospedali,
                ...places.supermarkets
            ],
            banche: places.banche,
            supermarkets: places.supermarkets,
            farmacie: places.farmacie,
            ospedali: places.ospedali,
            cinemas: places.cinemas,
        })
        try {
            const result = await axios({
                url: 'https://backoffice.arboutiqueapartments.it/graphql',
                method: 'post',
                data: {
                    query: `
                        query {
                            sottopaginas (
                                where: {
                                    identificativo: "VicinoANoi1"
                                }
                            ) {
                                id
                                identificativo
                                Titolo
                                descrizione
                                Titolo_en
                                descrizione_en
                            }
                        }
                    `
                }
            })
            this.setState({
                loading: false,
                texts: result.data.data.sottopaginas[0]
            })
        } catch (err) {
            console.log('err', err)
        }
    }

    changeLocationType = (type) => {
        this.setState({
            tipo: type
        })
    }

    render(){
        return (

            <Fragment>
                <TransitionGroup>
                    <CSSTransition
                        classNames="fade"
                        key={this.props.sectionName}
                        timeout={{ enter: 1000, exit: 1000 }}
                        in = {this.state.inProp}
                        unmountOnExit={true}
                        appear
                    >
                        <div>
                            <MainHeader
                                {...this.props}
                                inverted
                                withBack={isMobile}
                                backTarget="VicinoANoi#Vicino a noi"
                                background='white' />
                            <div className="cioCheServeUpperWhitePart"></div>
                            <div className="cioCheServeMainTitle">
                                {this.state.texts
                                    ? this.state.texts["Titolo".concat(this.props.lan === 'en' ? "_en" : "")]
                                    : 'Ciò che serve'}
                            </div>
                            <div
                                className="cioCheServeSubTitle"
                                dangerouslySetInnerHTML={{ __html: this.state.texts
                                    ? this.state.texts["descrizione".concat(this.props.lan === 'en' ? "_en" : "")]
                                    : 'AR: Boutique Apartments è nella posizione perfetta per i nostri ospiti che hanno bisogno di usufruire di tutti i comfort quotidiani: supermercati, banche, farmacie e ospedali'}} />
                            <MapMenu
                                selected={this.state.tipo}
                                changeLocationType={this.changeLocationType}
                            />

                            <div className="mapCentered">
                                <div className="innerMapCentered" >
                                    <Map
                                        places={this.state[this.state.tipo]}
                                        defaultCenter={this.props.center}
                                        height={"48vh"}
                                        width={isMobile ? '100vw' : "75vw"}
                                    />
                                </div>
                            </div>
                            {!isMobile && <div style={{marginTop: '5vh'}}>
                                <Footer {...this.props} />
                            </div>}
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}

export default CioCheServe;