import React, {Fragment} from 'react';
import Header from "./Header";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import "../style/style.css"
import RightArrow from "./RightArrow";
import { TransitionGroup, CSSTransition} from "react-transition-group";
import Logo from "./Logo";
import DownArrow from "./DownArrow";
import BackArrow from "./BackArrow";
import pageText from "../json config file/textFile";
import TitleAndText from "./TitleAndText";
import LanguageSelector from "./LanguageSelector";
import MainHeader from './MainHeader'
import { isMobile } from 'react-device-detect';
import SfondoConcept1 from "../img/riempitivi/WEB/concept1.jpg";
import * as _ from 'underscore'
import ArrowRight from '../img/Export_Asset/Forward_B_1.png'
import ArrowDownMobile from '../img/arrow_down_mobile.png'

class ParticularSectionPresentation extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            previousPage: this.props.previousPage,
            nextPage: this.props.nextPage,
            backArrow :this.props.BackArrow,
            downArrow: this.props.downArrow,
            inProp: true,
            imageTransition: "sectionTitleDelayed",
            textTransition: "apartmentSectionTitle0",
            downArrowTransiton: "menuDownButtonDecentered",
            showLogoMobile: true
        };
        this.onScrollDebounced = _.debounce(this.onScroll, 100)
    }

    componentDidMount () {
        window.addEventListener('scroll', this.onScrollDebounced)
        if (isMobile) {
            // console.log(this.props.history)
            const hash = this.props.history.location.pathname.substring(1)
            // console.log('hash', hash)
            if (!!hash && hash !== 'Concept1') {
                const div = document.getElementById("section_2")
                // console.log('div', div)
                if (!!div) {
                    div.scrollIntoView({
                        behavior: 'smooth'
                    });
                    this.setState({ fixHeader: true }) 
                }
            }
        }
    }
    
    componentWillUnmount () {
        window.removeEventListener('scroll', this.onScrollDebounced)
    }

    componentDidUpdate (oldProps, oldState) {
        if (oldState.position < this.state.position) {
            if (!this.state.fixHeader) {
                this.setState({showLogoMobile: false})
            } else {
                this.setState({fixHeader: false})
            }
        }
        if (oldState.position > this.state.position) {
            this.setState({showLogoMobile: true})
        }
    }

    onScroll = () => {
        this.setState({
            position: window.pageYOffset
        })
    }

    nextIndex = () => {
        const { nextPage } = this.props;
        this.state.inProp = false;
        this.setState({imageTransition: "fadeLong"});
        this.setState({textTransition: "fadeLong"});
        this.setState({downArrowTransiton: "fadeLong"});
        this.props.history.push("/"+nextPage)
    };

    prevIndex = () => {
        const { previousPage } = this.props;
        this.setState({imageTransition: "fadeLong"});
        this.setState({textTransition: "fadeLong"});
        this.setState({downArrowTransiton: "fadeLong"});
        this.props.history.push("/"+previousPage)
    };

    goDown = (pageDown) => {

        const { previousPage } = this.props;
        this.setState({imageTransition: "fadeLong"});
        this.setState({textTransition: "fadeLong"});
        this.setState({downArrowTransiton: "fadeLong"});
        this.props.history.push("/"+pageDown);
    };


    render(){
        // console.log('section name', this.props.sectionName)
        // console.log('props', this.props)
        if (isMobile) {
            const data = [
                { name: "Concept1", backgroundImage: `url(${SfondoConcept1})`},
                { name: "Concept2", backgroundImage: `url(${require("../img/riempitivi/WEB/Vertical WEB/alcoholic-beverages-beverage-bottleneck-373067.jpg")})`},
                { name: "Servizi1", backgroundImage: `url(${require("../img/riempitivi/WEB/Servizi/taisiia-stupak-DhVOFR_Hmac-unsplash.jpg")})`},
                { name: "Servizi2", backgroundImage: `url(${require("../img/riempitivi/WEB/Servizi/casual-close-up-colors-1954524.jpg")})`},
                { name: "Servizi3", backgroundImage: `url(${require("../img/riempitivi/WEB/Servizi/aromatherapy-ayurveda-b-3188.jpg")})`},
                { name: "Servizi4", backgroundImage: `url(${require("../img/riempitivi/WEB/Servizi/baskets-cabinet-clean-271711.jpg")})`},
                { name: "Servizi5", backgroundImage: `url(${require("../img/riempitivi/WEB/Servizi/rosebox-BFdSCxmqvYc-unsplash.jpg")})`},
                { name: "Servizi6", backgroundImage: `url(${require("../img/riempitivi/WEB/Servizi/drew-beamer-Se7vVKzYxTI-unsplash.jpg")})`}
            ]
            return (
                <div style={{height: window.innerHeight, overflow: 'scroll', scrollSnapType: 'y mandatory'}}>
                    <MainHeader
                        {...this.props}
                        // animated
                        // show={this.state.showLogoMobile}
                        withBack
                        backTarget={this.props.location.pathname === '/Concept1' ? 'Concept#Concept' : 'Servizi#Servizi'}
                        textColor='#91855d'
                        menuColor={isMobile ? 'white' : '#91855d'}
                        background='transparent' />
                    {data.map((section, i) => <section
                        key={`section_${i}`}
                        id={`section_${i}`}
                        style={{
                            backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), ${section.backgroundImage}`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center', width: '100vw', height: window.innerHeight, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', scrollSnapAlign: 'start'}}>
                        <div style={{flex: 1}}></div>
                        <TitleAndText
                            id={`title_text_${i}`}
                            key={`title_text_${i}`}
                            lan={this.props.lan}
                            className={section.name.replace(/\s+/g, '')}
                            sectionName={section.name} />
                        <div style={{flex: 1, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                            {i < (data.length - 1) && <img
                                className="bounceOnHover"
                                src={ArrowDownMobile}
                                style={{marginBottom: 60}} />}
                        </div>
                    </section>)}
                </div>
            )
        }
        return (

            <Fragment>

                <MainHeader
                    {...this.props}
                    withBack
                    backTarget={this.props.previousPage}
                    textColor='#91855d'
                    menuColor={isMobile ? 'white' : '#91855d'}
                    background='transparent' />
                {/* <Header {...this.props} colorBurger ="#91855d"/>
                <LanguageSelector defaultColor="#91855d"/>
                <Logo logoPosition = "logoUpAndLeft" {...this.props}/> */}
                <TransitionGroup>
                    <CSSTransition
                        classNames={this.state.imageTransition}
                        key={this.props.sectionName}
                        timeout={{ enter: 10000, exit: 10000 }}
                        in = {this.state.inProp}
                        unmountOnExit={true}
                        appear
                    >
                        <div>
                            <div className={this.props.sectionName.replace(/\s+/g, '')}></div>
                        </div>
                    </CSSTransition>
                </TransitionGroup>

                <TransitionGroup>
                    <CSSTransition
                        classNames={this.state.textTransition}
                        key={this.props.sectionName}
                        timeout={{ enter: 1000, exit: 1000 }}
                        in = {this.state.inProp}
                        unmountOnExit={true}
                        appear
                    >
                        <div>
                            <TitleAndText
                                lan={this.props.lan}
                                className={this.props.sectionName.replace(/\s+/g, '')}
                                sectionName={this.props.sectionName} />
                        </div>
                    </CSSTransition>
                </TransitionGroup>

                <TransitionGroup>
                    <CSSTransition
                        classNames={this.state.downArrowTransiton}
                        key={this.props.sectionName}
                        timeout={{ enter: 1000, exit: 1000 }}
                        in = {this.state.inProp}
                        unmountOnExit={true}
                        appear
                    >
                        <div>
                            {isMobile
                                ?   <div
                                        onClick={() => this.goDown(this.props.downArrow)}
                                        className="downArrowMobile"
                                        style={{marginBottom: 60, marginLeft: '-40px'}}>
                                        <img
                                            style={{width: 80}}
                                            className="downArrow"
                                            src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAS4AAAA8CAYAAADYKVdnAAAABHNCSVQICAgIfAhkiAAABOxJREFUeF7t3U/opVMcx/H340/EgiwYSUmaFCkLqSnZsLYgRUhNlJ2kTM1vRIxGkp2iKSFKWdhKymaQheykwV6xl9JHz687P/f3m3t/95zn361733d9zvfM8zqnT+c88zzPr8GfAgoo0EEgyVvAX03TnOnQvVeXpldvOyugwNYKzILrReBE0zRvTglhcE2p7VgKbJhAkveBZ6YOL4NrwxaSl6PA1AJJPgKenDK8DK6pZ9nxFNgwgSRtjnwJPDhVeBlcG7aIvBwF1iGQ5CrgO+CuKcLL4FrHLDumAhsokOQG4CfgyNjhZXBt4ALykhRYl0CS+4FvZuOP9r+NBte6ZthxFdhQgSSfAo+NGV4G14YuHi9LgXUJJLkO+A24dqzwMrjWNbuOq8AGCyR5Dnh37hJ3mqY5PdQlG1xDSVpHAQX2BJJcApwHbp1jGeyel8HlYlNAgVEEkjwOfHKg+CDhZXCNMmUWVUCBViDJz8DtQ4eXweX6UkCB0QSSPA18sGCAXjsvg2u0KbOwAgokuaL99A1w9ZDhZXC5thRQYFSBJGeB40sG6bTzMrhGnTKLK6BAkmPAuUMkTjVN83qNlMFVo2VbBRSoFph9PeJPoH0wddmvaudlcFVPgx0UUKBWIMnnwMMr+hWHl8FVOwO2V0CBaoEFT9L32nkZXNVTYAcFFKgVSHIU+KWw38p7XgZXoaTNFFCgn0CSv4H28YiS36HHRoOrhNA2CijQWyDJj8DdFYWWhpfBVaFoUwUU6C6QpH1vsX1/sea3MLwMrhpC2yqgQGeBJDvAax0KXHTPy+DqoGgXBRSoF0jyLPBefc/dHvt2Xk2SVzoWspsCCihQI3AH8EhNhwNt98KrDa70KGRXBRRQYEqB3fAyuKYkdywFFBhC4JTBNQSjNRRQYEqBEwbXlNyOpYACfQX2jorenO9LaX8FFCgRuLPgRevD6vx/c75kNNsooIACfQV6Pg6x71kun+PqOxv2V0CBIoEkZ4CXihrvb3TR0/MGVwdFuyigQL1Aki+Ahyp7+spPJZjNFVBgQIElf6qs6J7WwUbuuAacGEspoMBigdlftv4HuLTQyM/aFELZTAEFRhJIci/wfWH5lZ9wdsdVKGkzBRToLpDkVeDlggorQ6utYXAVSNpEAQX6CST5AbhnRZWi0DK4+s2FvRVQoEAgyfXAHyuarvzO/Hx/d1wF8DZRQIHuAkleAN4+pELxTutCDYOr+3zYUwEFCgSSnAduW9K0OrQ8Khag20QBBboLJDkGnBsytAyu7vNhTwUUKBBI8iHw1IKmO03TnC4osbCJR8WucvZTQIFDBZLcDPwOXHagYafj4XwNg8vFp4ACowgkOQscHzq0PCqOMl0WVUCBJbut3jutC7LuuFxjCigwuECSj4En5gqfbJrmjaEGMriGkrSOAgrsCiR5APhqjmOwnZY7LheZAgoMLpDkSuBX4KZZ8cFDy3tcg0+bBRXYboEk7wDPzxSqXuOpkfOoWKNlWwUUWCqQ5FHgszF3Wh4VXYAKKDCYQJL7gK+By4FRjofz/1h3XINNnYUU2E6BJEeB9rM11wCjHQ8Nru1cX161AoMLJLkF+Ba4cYqdlkfFwafQggpsl0CSI7OdVvtqz+jHQ3dc27W+vFoFRhGY+1zNJMdDg2uUabSoAtslkOQk8G/TNO0fep309x/j0Cs5myh5dQAAAABJRU5ErkJggg=="} />
                                    </div>
                                :   <DownArrow
                                        {...this.props}
                                        downArrow={this.props.downArrow}
                                        downArrowWrite={this.props.downArrowWrite}
                                        classType ="menuDownButtonDecentered"
                                        goDown={this.goDown}/>}
                        </div>
                    </CSSTransition>
                </TransitionGroup>

                {!isMobile && <BackArrow {...this.props} backArrow={this.props.backArrow} classButtonType="menuBackButton" classWriteType="menuBackButtonWrite"/>}

                <ReactScrollWheelHandler
                    timeout={1000}
                    upHandler={this.prevIndex}
                    downHandler={this.nextIndex}
                    customStyle={{
                        width: "100%",
                        height: "100vh",
                    }}
                />
            </Fragment>
        );
    }
}

export default ParticularSectionPresentation;