import React, {Fragment} from 'react';
import "../style/style.css"

class BackArrow extends React.Component{
    state = {
        backArrow: this.props.backArrow,
    };

    goBack = (event) => {
        event.preventDefault();
        const backArrow = this.state.backArrow;
        this.props.history.push("/"+this.props.backArrow);
    };
    render(){
        return(
            <Fragment>
                <div className={this.props.classWriteType} onClick={this.goBack}>
                    {this.props.lan === "en" ? "Go Back" : "Indietro"}
                </div>
                <div className={this.props.classButtonType} onClick={this.goBack}></div>
            </Fragment>
        )
    }
}

export default BackArrow;