import React from 'react';
import "../style/style.css";
import { TransitionGroup, CSSTransition} from "react-transition-group";


class Logo extends React.Component{
    state = {
        inProp: false,
    };
    goHome = (event) => {
        event.preventDefault();
        this.props.history.push("/");
    };
    render(){

        return(
            <TransitionGroup>
                <CSSTransition classNames={this.props.animation}
                               key={this.props.sectionName}
                               timeout={{ enter: 1000, exit: 1000 }}
                               in = {this.state.inProp}
                               unmountOnExit={true}
                >{this.props.contact
                    ? <span className="logoUpLeftInvertedColor" onClick={this.goHome}/>
                    : <div className={this.props.logoPosition} onClick={this.goHome}/>
                }
                </CSSTransition>
            </TransitionGroup>

        )
    }
}

export default Logo;