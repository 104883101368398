import React, {Fragment} from 'react';
import Header from "./Header";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import "../style/style.css"
import RightArrow from "./RightArrow";
import { TransitionGroup, CSSTransition} from "react-transition-group";
import Logo from "./Logo";
import DownArrow from "./DownArrow";
import BackArrow from "./BackArrow";
import LanguageSelector from "./LanguageSelector";


class ParticularSectionPresentationTwoText extends React.Component {
    state = {
        previousPage: this.props.previousPage,
        nextPage: this.props.nextPage,
        backArrow :this.props.BackArrow,
        downArrow: this.props.downArrow,
        inProp: true,
    };

    nextIndex = () => {
        const { nextPage } = this.props;
        this.state.inProp = false;
        this.props.history.push("/"+nextPage)
    };

    prevIndex = () => {
        const { previousPage } = this.props;
        this.props.history.push("/"+previousPage)
    };


    render(){
        return (

            <Fragment>
                <div className={this.props.sectionName.replace(/\s+/g, '')}></div>
                <Header {...this.props} colorBurger ="#91855d"/>
                <LanguageSelector/>
                <Logo logoPosition = "logoUpAndLeft"/>
                <TransitionGroup>
                    <CSSTransition classNames="fade"
                                   key={this.props.sectionName}
                                   timeout={{ enter: 1000, exit: 1000 }}
                                   in = {this.state.inProp}
                                   unmountOnExit={true}
                    >
                        <div className={this.props.sectionName}>
                            <div className="menuGreyOverlaySecondaryMenu"></div>
                            <div className="particularSectionPresentationRightText1">HOLAAAAAAAAAAAAAAAAAAAAAA</div>
                            <div className="particularSectionPresentationRightText2">HOLAAAAAAAAAAAAAAAAAAAAAA</div>
                            <DownArrow {...this.props} downArrow={this.props.downArrow} downArrowWrite={this.props.downArrowWrite} classType ="menuDownButtonDecentered"/>

                        </div>
                    </CSSTransition>
                </TransitionGroup>

                <BackArrow {...this.props} backArrow={this.props.backArrow} classButtonType="menuBackButton" classWriteType="menuBackButtonWrite"/>

                <ReactScrollWheelHandler
                    timeout={0}
                    upHandler={this.prevIndex}
                    downHandler={this.nextIndex}
                    customStyle={{
                        width: "100%",
                        height: "100vh",
                    }}
                />
            </Fragment>
        );
    }
}

export default ParticularSectionPresentationTwoText;