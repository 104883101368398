import React, {Fragment} from 'react';
import "../style/style.css";
import { TransitionGroup, CSSTransition} from "react-transition-group";


class LanguageSelector extends React.Component{
    state = {
        languageSelected: "It",
    };
    setIt = () => {
        this.setState({languageSelected: "It"});
    };
    setEn = () => {
        this.setState({languageSelected: "En"});
    };
    renderSelectorUpdated = () => {
            if(this.state.languageSelected === "It") {
                return (
                    <Fragment>
                        <span className="italianLanguageIt" onClick={this.setIt}>It</span>
                        <hr className="languageVerticalLine"></hr>
                        <span className="englishLanguageIt" onClick={this.setEn} style={{color: this.props.defaultColor}}>En</span>
                    </Fragment>
            )

            }
            else if(this.state.languageSelected === "En"){
                return(
                <Fragment>
                    <span className="italianLanguageEn" onClick={this.setIt} style={{color: this.props.defaultColor}}>It</span>
                    <hr className="languageVerticalLine"></hr>
                    <span className="englishLanguageEn" onClick={this.setEn}>En</span>
                </Fragment>
                )
            }


    }
    render(){

        return(
            <Fragment>
                <div>{this.renderSelectorUpdated()}</div>
            </Fragment>

        )
    }
}

export default LanguageSelector;