import React, {Fragment} from 'react';
import "../style/style.css";
import { TransitionGroup, CSSTransition} from "react-transition-group";


class BottomMenu extends React.Component{
    state = {
        inProp: false,
    };
    goToPage = (page) => {
        this.props.history.push("/"+page);
    };
    goHome = () => {
        if(this.props.sectionPresentation)
            this.props.goHome();
        else
            this.props.history.push("/");
    };
    render(){

        return(
            <Fragment>
            <div className= "bottomMenu">
                <hr className="miniHrVertical"/>
                <span className="bottomMenuItem" onClick={() => this.goHome()}>Home</span>
                <span className="bottomMenuItem" onClick={() => this.goToPage("Concept")}>Concept</span>
                <span className="bottomMenuItem" onClick={() => this.goToPage("Servizi")}>Servizi</span>
                <span className="bottomMenuItem" onClick={() => this.goToPage("Appartamenti")}>Appartamenti</span>
                <span className="bottomMenuItem" onClick={() => this.goToPage("Eventi")}>Eventi</span>
                <span className="bottomMenuItem" onClick={() => this.goToPage("VicinoANoi")}>Vicino a noi</span>
                <span className="bottomMenuItem" onClick={() => this.goToPage("Contact")}>Contattaci</span>
                <span className="privacyPolicy">Privacy policy</span>
                <span className="instagramIcon"></span>
                <span className="facebookIcon"></span>
            </div>
                <div className="whiteBottomSpace"></div>
            </Fragment>


        )
    }
}

export default BottomMenu;