import React, { Fragment } from "react";
import "../style/style.css";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Event from "./Event";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import Logo from "./Logo";
import ReactIScroll from "react-iscroll";
import Header from "./Header";
import ScrollAnimation from "react-animate-on-scroll";
import LanguageSelector from "./LanguageSelector";
import PageTransition from "react-router-page-transition";
import axios from "axios";
import Loader from "react-loader-spinner";
import CalendarioEventi from "./CalendarioEventi";
import Evento from "./Evento";
import MainHeader from "./MainHeader";
import { isMobile } from "react-device-detect";
import SectionPresentation from "./SectionPresentation";
import ArrowDownMobile from "../img/arrow_down_mobile.png";

class EventPicker extends React.Component {
  state = {
    previousPage: this.props.previousPage,
    nextPage: this.props.nextPage,
    inProp: true,
    rightArrow: this.props.rightArrow,
    loading: true,
    showCalendario: false,
    showEvent: false,
    showHeader: true,
    position: 0,
    showScrollHint: true,
    selectedEvent: 0,
    eventAnimation: "sectionTitle",
  };

  options = {
    mouseWheel: true,
    scrollbars: true,
    freeScroll: true,
    invertWheelDirection: true,
    momentum: false,
  };

  async componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
    try {
      const result = await axios({
        url: "https://backoffice.arboutiqueapartments.it/graphql",
        method: "post",
        data: {
          query: `
						query {
								eventos (
									sort: "data:asc"
								) {
										id
										data
										titolo
										titolo_en
										sottotitolo
										sottotitolo_en
										descrizione
										descrizione_en
										galleria {
												id
												url
										}
										sfondo {
												id
												url
										}
								}
						}
				`,
        },
      });
      const events = result.data.data.eventos;
      this.setState({
        loading: false,
        events,
      });
    } catch (err) {
      console.log("err", err);
    }
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldState.position < this.state.position) {
      this.setState({ showHeader: false });
    }
    if (oldState.position > this.state.position) {
      const triggerColor = window.innerHeight;
      if (this.state.position >= triggerColor) {
        this.setState({ showHeader: true, headerInverted: true });
      } else {
        this.setState({ showHeader: true, headerInverted: false });
      }
    }
  }

  nextIndex = () => {
    const { nextPage } = this.props;
    this.state.inProp = false;
    this.props.history.push("/" + nextPage);
  };

  prevIndex = () => {
    const { previousPage } = this.props;

    this.props.history.push("/" + previousPage);
  };

  onScrollStart = () => {
    // console.log("iScroll starts scrolling");
  };

  toggleCalendario = () => {
    // event.preventDefault();
    // this.props.history.push("/CalendarioEventi");
    this.setState({
      showCalendario: !this.state.showCalendario,
    });
  };

  goToEvent = (id, margin) => {
    // this.props.history.push("/Aperitivo");
    // console.log("clicked 3");
    if (!id) {
      this.setState({ showEvent: false });
    } else {
      this.setState({ showEvent: id, margin });
    }
  };

  onScroll = () => {
    this.setState({
      position: window.pageYOffset,
    });
  };

  hideScrollHint = () => {
    this.setState({ showScrollHint: false });
  };

  nextEvent = () => {
    if (!(this.state.selectedEvent >= this.state.events.length)) {
      this.setState({
        eventAnimation: "sectionTitle",
        selectedEvent: this.state.selectedEvent + 1,
      });
    }
  };

  prevEvent = () => {
    if (this.state.selectedEvent !== 0) {
      this.setState({
        eventAnimation: "sectionTitleReverse",
        selectedEvent: this.state.selectedEvent - 1,
      });
    }
  };

  render() {
    const { loading, events, showCalendario, showEvent, showHeader } = this.state;
    // console.log("events", events);
    if (!!isMobile) {
      let data = [
        {
          id: "xxx",
          name: this.props.lan === "en" ? "Calendar" : "Calendario Eventi",
          eventName: "evento1",
          evento: "CalendarioEventi",
          eventPage: "CalendarioEventi",
        },
      ];
      if (!loading) {
        data = [
          ...data,
          ...events.map((event) => {
            return {
              id: event.id,
              name: event[`titolo${this.props.lan === 'en' ? '_en' : ''}`],
              date: new Date(event.data).toLocaleDateString(),
              image: "https://backoffice.arboutiqueapartments.it" + event.sfondo.url,
              eventName: "evento2",
              eventPage: "Aperitivo",
            };
          }),
        ];
      }
      const event = data[this.state.selectedEvent];
      return (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            overflow: "scroll",
            scrollSnapType: "y mandatory",
          }}
        >
          <MainHeader
            {...this.props}
            // inverted={this.state.headerInverted}
            withBack
            backTarget="Eventi#Eventi"
            background="transparent"
          />
          {loading ? (
            <div
              style={{
                width: "100vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <Loader type="Rings" color="#91855D" height={100} width={100} />
            </div>
          ) : (
            // :   <Event
            //         {...this.props}
            //         animation={this.state.eventAnimation}
            //         id={event.id}
            //         last={this.state.selectedEvent === (events.length )}
            //         name={event.name}
            //         date={event.date || null}
            //         image={event.image || null}
            //         eventName={event.eventName}
            //         evento={event.evento}
            //         eventPage={event.eventPage}
            //         goToNextEvent={this.nextEvent} />
            data.map((event, i) => (
              <Event
                key={event.id}
                {...this.props}
                animation={this.state.eventAnimation}
                id={event.id}
                last={i === data.length - 1}
                name={event.name}
                date={event.date || null}
                image={event.image || null}
                eventName={event.eventName}
                evento={event.evento}
                eventPage={event.eventPage}
                nextEvent={!!data[i + 1] ? data[i + 1].id : ""}
                goToNextEvent={this.nextEvent}
              />
            ))
          )}
          {/* <ReactScrollWheelHandler
                        timeout={1000}
                        upHandler={this.prevEvent}
                        downHandler={this.nextEvent}
                        customStyle={{
                            width: "100%",
                            height: "100vh",
                            zIndex: "800",
                            position: "absolute",
                            top: 0,
                            right: 0
                        }}
                    /> */}
        </div>
      );
    } else {
      return (
        <Fragment>
          {showHeader && (
            <MainHeader
              {...this.props}
              inverted={this.state.headerInverted}
              background="transparent"
            />
          )}
          <PageTransition>
            <div
              style={{
                position: "absolute",
                width: "20%",
                height: "100vh",
                borderRight: "3px solid #e6ce3c",
                zIndex: 1,
              }}
            >
              <Event
                goToCalendario={this.toggleCalendario}
                id="xxx"
                name={this.props.lan === "en" ? "Calendar" : "Calendario Eventi"}
                eventName="evento1"
                evento="CalendarioEventi"
                eventPage="CalendarioEventi"
              />
            </div>
          </PageTransition>

          {this.state.showScrollHint && (
            <div className="scrollArrowContainer">
              <div
                onClick={() => this.props.goToEvent()}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <div style={{ color: "white", marginBottom: 4 }}>
                  {this.props.lan === "en" ? "Scroll Right" : "Scorri a destra"}
                </div>
                <img
                  className="scrollArrow"
                  style={{ width: 180 }}
                  src={
                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAS4AAAA8CAYAAADYKVdnAAAABHNCSVQICAgIfAhkiAAABOxJREFUeF7t3U/opVMcx/H340/EgiwYSUmaFCkLqSnZsLYgRUhNlJ2kTM1vRIxGkp2iKSFKWdhKymaQheykwV6xl9JHz687P/f3m3t/95zn361733d9zvfM8zqnT+c88zzPr8GfAgoo0EEgyVvAX03TnOnQvVeXpldvOyugwNYKzILrReBE0zRvTglhcE2p7VgKbJhAkveBZ6YOL4NrwxaSl6PA1AJJPgKenDK8DK6pZ9nxFNgwgSRtjnwJPDhVeBlcG7aIvBwF1iGQ5CrgO+CuKcLL4FrHLDumAhsokOQG4CfgyNjhZXBt4ALykhRYl0CS+4FvZuOP9r+NBte6ZthxFdhQgSSfAo+NGV4G14YuHi9LgXUJJLkO+A24dqzwMrjWNbuOq8AGCyR5Dnh37hJ3mqY5PdQlG1xDSVpHAQX2BJJcApwHbp1jGeyel8HlYlNAgVEEkjwOfHKg+CDhZXCNMmUWVUCBViDJz8DtQ4eXweX6UkCB0QSSPA18sGCAXjsvg2u0KbOwAgokuaL99A1w9ZDhZXC5thRQYFSBJGeB40sG6bTzMrhGnTKLK6BAkmPAuUMkTjVN83qNlMFVo2VbBRSoFph9PeJPoH0wddmvaudlcFVPgx0UUKBWIMnnwMMr+hWHl8FVOwO2V0CBaoEFT9L32nkZXNVTYAcFFKgVSHIU+KWw38p7XgZXoaTNFFCgn0CSv4H28YiS36HHRoOrhNA2CijQWyDJj8DdFYWWhpfBVaFoUwUU6C6QpH1vsX1/sea3MLwMrhpC2yqgQGeBJDvAax0KXHTPy+DqoGgXBRSoF0jyLPBefc/dHvt2Xk2SVzoWspsCCihQI3AH8EhNhwNt98KrDa70KGRXBRRQYEqB3fAyuKYkdywFFBhC4JTBNQSjNRRQYEqBEwbXlNyOpYACfQX2jorenO9LaX8FFCgRuLPgRevD6vx/c75kNNsooIACfQV6Pg6x71kun+PqOxv2V0CBIoEkZ4CXihrvb3TR0/MGVwdFuyigQL1Aki+Ahyp7+spPJZjNFVBgQIElf6qs6J7WwUbuuAacGEspoMBigdlftv4HuLTQyM/aFELZTAEFRhJIci/wfWH5lZ9wdsdVKGkzBRToLpDkVeDlggorQ6utYXAVSNpEAQX6CST5AbhnRZWi0DK4+s2FvRVQoEAgyfXAHyuarvzO/Hx/d1wF8DZRQIHuAkleAN4+pELxTutCDYOr+3zYUwEFCgSSnAduW9K0OrQ8Khag20QBBboLJDkGnBsytAyu7vNhTwUUKBBI8iHw1IKmO03TnC4osbCJR8WucvZTQIFDBZLcDPwOXHagYafj4XwNg8vFp4ACowgkOQscHzq0PCqOMl0WVUCBJbut3jutC7LuuFxjCigwuECSj4En5gqfbJrmjaEGMriGkrSOAgrsCiR5APhqjmOwnZY7LheZAgoMLpDkSuBX4KZZ8cFDy3tcg0+bBRXYboEk7wDPzxSqXuOpkfOoWKNlWwUUWCqQ5FHgszF3Wh4VXYAKKDCYQJL7gK+By4FRjofz/1h3XINNnYUU2E6BJEeB9rM11wCjHQ8Nru1cX161AoMLJLkF+Ba4cYqdlkfFwafQggpsl0CSI7OdVvtqz+jHQ3dc27W+vFoFRhGY+1zNJMdDg2uUabSoAtslkOQk8G/TNO0fep309x/j0Cs5myh5dQAAAABJRU5ErkJggg=="
                  }
                />
              </div>
            </div>
          )}

          {showCalendario && (
            <CalendarioEventi
              {...this.props}
              events={events}
              toggleCalendario={this.toggleCalendario}
            />
          )}
          {!!this.state.showEvent && (
            <Evento
              {...this.props}
              margin={this.state.margin}
              goToEvent={this.goToEvent}
              events={events}
              event={events.find((event) => event.id === showEvent)}
            />
          )}

          <div
            onScroll={() => this.hideScrollHint()}
            className="horizontal-scroll-wrapper"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            {loading ? (
              <div
                style={{
                  width: "80vw",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100vh",
                }}
              >
                <Loader type="Rings" color="#91855D" height={100} width={100} />
              </div>
            ) : (
              <Fragment>
                <div id="container">
                  <div
                    id="container2"
                    style={{
                      width:
                        20 +
                        events.filter(
                          (event) => new Date(event.data).toISOString() > new Date().toISOString()
                        ).length *
                          27 +
                        "vw",
                    }}
                  >
                    {events
                      // .filter(event => new Date(event.data).toISOString() <= new Date().toISOString())
                      .map((event) => (
                        <div className="box" style={{ backgroundColor: "black" }}>
                          <Event
                            {...this.props}
                            goToEvent={this.goToEvent}
                            id={event.id}
                            name={event[`titolo${this.props.lan === 'en' ? '_en' : ''}`]}
                            date={new Date(event.data).toLocaleDateString()}
                            image={"https://backoffice.arboutiqueapartments.it" + event.sfondo.url}
                            eventName="evento2"
                            eventPage="Aperitivo"
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </Fragment>
      );
    }
  }
}

export default EventPicker;
