import React, { Fragment } from "react";
import "../style/style.css";
import { TransitionGroup, CSSTransition, Transition } from "react-transition-group";
import Logo from "./Logo";
import BackArrow from "./BackArrow";
import DownArrow from "./DownArrow";
import Header from "./Header";
import LanguageSelector from "./LanguageSelector";
import LanguageSelectorRelative from "./LanguageSelectorRelative";
import axios from "axios";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import Footer from "./Footer";
import { values } from "ramda";

class Evento extends React.Component {
  state = {
    inProp: false,
    loading: true,
    open: false,
    margin: this.props.margin,
    showGallery: false,
    photo: 0,
    startAnimating: false,
  };

  async componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
    this.setState({
      open: true,
    });
  }

  componentDidUpdate(oldProps) {
    if (oldProps.event.id !== this.props.event.id) {
      window.scrollTo(0, 0);
    }
  }

  goBack = async () => {
    this.setState({ open: false });
    setTimeout(() => {
      this.props.goToEvent();
    }, 1400);
    // await this.props.history.push('/evento/'+id)
  };

  openGallery = (index) => {
    setTimeout(() => {
      this.setState({ startAnimating: true });
    }, 100);
    this.setState({
      showGallery: true,
      photo: index,
    });
  };

  handleKeyDown = (key) => {
    switch (key.keyCode) {
      case 37: {
        this.handlePrevPhoto();
        break;
      }
      case 39: {
        this.handleNextPhoto();
        break;
      }
      case 27: {
        if (this.state.showGallery) {
          this.closeGallery();
        }
        break;
      }
    }
  };

  closeGallery = () => {
    setTimeout(
      () =>
        this.setState({
          showGallery: false,
        }),
      900
    );
    this.setState({
      startAnimating: false,
    });
  };

  handlePrevPhoto = (event) => {
    if (event) {
      event.stopPropagation();
    }
    if (this.state.photo === 0) {
      this.setState({
        photo: this.props.event.galleria.length - 1,
      });
    } else {
      this.setState({
        photo: this.state.photo - 1,
      });
    }
  };

  handleNextPhoto = (event) => {
    if (event) {
      event.stopPropagation();
    }
    if (this.state.photo === this.props.event.galleria.length - 1) {
      this.setState({
        photo: 0,
      });
    } else {
      this.setState({
        photo: this.state.photo + 1,
      });
    }
  };

  render() {
    const { event, events, id } = this.props;
    const indexEv = events.findIndex((evento) => evento.id === event.id);
    const prevEv = indexEv === 0 ? null : events[indexEv - 1];
    const nextEv = indexEv === events.length - 1 ? null : events[indexEv + 1];
    // console.log("event props", prevEv);
    // console.log("event props", nextEv);
    return (
      <Transition in={this.state.open} timeout={100}>
        {(state) => (
          <div
            style={{ marginLeft: this.state.margin + "vw" }}
            className={`evento-container-${state}`}
          >
            <CalendarioEventiContaier
              background={`url("https://backoffice.arboutiqueapartments.it${event.sfondo.url}")`}
            >
              <div style={{ flex: 1, display: "flex", alignItems: "center", paddingLeft: "2.5%" }}>
                <div
                  onClick={() => this.goBack()}
                  // onClick={() => this.setState({open: false})}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ color: "white", marginBottom: 4 }}>
                    {this.props.lan === "en" ? "Go Back" : "Indietro"}
                  </div>
                  <img
                    className="backArrow"
                    src={
                      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAS4AAAA8CAYAAADYKVdnAAAABHNCSVQICAgIfAhkiAAABOxJREFUeF7t3U/opVMcx/H340/EgiwYSUmaFCkLqSnZsLYgRUhNlJ2kTM1vRIxGkp2iKSFKWdhKymaQheykwV6xl9JHz687P/f3m3t/95zn361733d9zvfM8zqnT+c88zzPr8GfAgoo0EEgyVvAX03TnOnQvVeXpldvOyugwNYKzILrReBE0zRvTglhcE2p7VgKbJhAkveBZ6YOL4NrwxaSl6PA1AJJPgKenDK8DK6pZ9nxFNgwgSRtjnwJPDhVeBlcG7aIvBwF1iGQ5CrgO+CuKcLL4FrHLDumAhsokOQG4CfgyNjhZXBt4ALykhRYl0CS+4FvZuOP9r+NBte6ZthxFdhQgSSfAo+NGV4G14YuHi9LgXUJJLkO+A24dqzwMrjWNbuOq8AGCyR5Dnh37hJ3mqY5PdQlG1xDSVpHAQX2BJJcApwHbp1jGeyel8HlYlNAgVEEkjwOfHKg+CDhZXCNMmUWVUCBViDJz8DtQ4eXweX6UkCB0QSSPA18sGCAXjsvg2u0KbOwAgokuaL99A1w9ZDhZXC5thRQYFSBJGeB40sG6bTzMrhGnTKLK6BAkmPAuUMkTjVN83qNlMFVo2VbBRSoFph9PeJPoH0wddmvaudlcFVPgx0UUKBWIMnnwMMr+hWHl8FVOwO2V0CBaoEFT9L32nkZXNVTYAcFFKgVSHIU+KWw38p7XgZXoaTNFFCgn0CSv4H28YiS36HHRoOrhNA2CijQWyDJj8DdFYWWhpfBVaFoUwUU6C6QpH1vsX1/sea3MLwMrhpC2yqgQGeBJDvAax0KXHTPy+DqoGgXBRSoF0jyLPBefc/dHvt2Xk2SVzoWspsCCihQI3AH8EhNhwNt98KrDa70KGRXBRRQYEqB3fAyuKYkdywFFBhC4JTBNQSjNRRQYEqBEwbXlNyOpYACfQX2jorenO9LaX8FFCgRuLPgRevD6vx/c75kNNsooIACfQV6Pg6x71kun+PqOxv2V0CBIoEkZ4CXihrvb3TR0/MGVwdFuyigQL1Aki+Ahyp7+spPJZjNFVBgQIElf6qs6J7WwUbuuAacGEspoMBigdlftv4HuLTQyM/aFELZTAEFRhJIci/wfWH5lZ9wdsdVKGkzBRToLpDkVeDlggorQ6utYXAVSNpEAQX6CST5AbhnRZWi0DK4+s2FvRVQoEAgyfXAHyuarvzO/Hx/d1wF8DZRQIHuAkleAN4+pELxTutCDYOr+3zYUwEFCgSSnAduW9K0OrQ8Khag20QBBboLJDkGnBsytAyu7vNhTwUUKBBI8iHw1IKmO03TnC4osbCJR8WucvZTQIFDBZLcDPwOXHagYafj4XwNg8vFp4ACowgkOQscHzq0PCqOMl0WVUCBJbut3jutC7LuuFxjCigwuECSj4En5gqfbJrmjaEGMriGkrSOAgrsCiR5APhqjmOwnZY7LheZAgoMLpDkSuBX4KZZ8cFDy3tcg0+bBRXYboEk7wDPzxSqXuOpkfOoWKNlWwUUWCqQ5FHgszF3Wh4VXYAKKDCYQJL7gK+By4FRjofz/1h3XINNnYUU2E6BJEeB9rM11wCjHQ8Nru1cX161AoMLJLkF+Ba4cYqdlkfFwafQggpsl0CSI7OdVvtqz+jHQ3dc27W+vFoFRhGY+1zNJMdDg2uUabSoAtslkOQk8G/TNO0fep309x/j0Cs5myh5dQAAAABJRU5ErkJggg=="
                    }
                  />
                </div>
              </div>
              <div
                style={{ flex: 2, display: "flex", flexDirection: "column", alignItems: "center" }}
              >
                <div style={{ flex: 1 }} />
                <div
                  style={{
                    fontSize: "64px",
                    color: "white",
                    flex: 3,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    textAlign: "center",
                  }}
                >
                  <div>{event[`titolo${this.props.lan === 'en' ? '_en' : ''}`]}</div>
                  <div style={{ fontSize: "0.7em" }}>
                    {new Date(event.data).toLocaleDateString()}
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className={this.state.additionalClass}
                    style={{ color: "white", marginBottom: 50, transition: "300ms ease-in-out" }}
                  >
                    {this.props.lan === "en" ? "Scroll Down" : "Scorri in Basso"}
                  </div>
                  <img
                    onMouseEnter={() => this.setState({ additionalClass: "moveUp" })}
                    onMouseLeave={() => this.setState({ additionalClass: "" })}
                    className="downArrow"
                    src={
                      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAS4AAAA8CAYAAADYKVdnAAAABHNCSVQICAgIfAhkiAAABOxJREFUeF7t3U/opVMcx/H340/EgiwYSUmaFCkLqSnZsLYgRUhNlJ2kTM1vRIxGkp2iKSFKWdhKymaQheykwV6xl9JHz687P/f3m3t/95zn361733d9zvfM8zqnT+c88zzPr8GfAgoo0EEgyVvAX03TnOnQvVeXpldvOyugwNYKzILrReBE0zRvTglhcE2p7VgKbJhAkveBZ6YOL4NrwxaSl6PA1AJJPgKenDK8DK6pZ9nxFNgwgSRtjnwJPDhVeBlcG7aIvBwF1iGQ5CrgO+CuKcLL4FrHLDumAhsokOQG4CfgyNjhZXBt4ALykhRYl0CS+4FvZuOP9r+NBte6ZthxFdhQgSSfAo+NGV4G14YuHi9LgXUJJLkO+A24dqzwMrjWNbuOq8AGCyR5Dnh37hJ3mqY5PdQlG1xDSVpHAQX2BJJcApwHbp1jGeyel8HlYlNAgVEEkjwOfHKg+CDhZXCNMmUWVUCBViDJz8DtQ4eXweX6UkCB0QSSPA18sGCAXjsvg2u0KbOwAgokuaL99A1w9ZDhZXC5thRQYFSBJGeB40sG6bTzMrhGnTKLK6BAkmPAuUMkTjVN83qNlMFVo2VbBRSoFph9PeJPoH0wddmvaudlcFVPgx0UUKBWIMnnwMMr+hWHl8FVOwO2V0CBaoEFT9L32nkZXNVTYAcFFKgVSHIU+KWw38p7XgZXoaTNFFCgn0CSv4H28YiS36HHRoOrhNA2CijQWyDJj8DdFYWWhpfBVaFoUwUU6C6QpH1vsX1/sea3MLwMrhpC2yqgQGeBJDvAax0KXHTPy+DqoGgXBRSoF0jyLPBefc/dHvt2Xk2SVzoWspsCCihQI3AH8EhNhwNt98KrDa70KGRXBRRQYEqB3fAyuKYkdywFFBhC4JTBNQSjNRRQYEqBEwbXlNyOpYACfQX2jorenO9LaX8FFCgRuLPgRevD6vx/c75kNNsooIACfQV6Pg6x71kun+PqOxv2V0CBIoEkZ4CXihrvb3TR0/MGVwdFuyigQL1Aki+Ahyp7+spPJZjNFVBgQIElf6qs6J7WwUbuuAacGEspoMBigdlftv4HuLTQyM/aFELZTAEFRhJIci/wfWH5lZ9wdsdVKGkzBRToLpDkVeDlggorQ6utYXAVSNpEAQX6CST5AbhnRZWi0DK4+s2FvRVQoEAgyfXAHyuarvzO/Hx/d1wF8DZRQIHuAkleAN4+pELxTutCDYOr+3zYUwEFCgSSnAduW9K0OrQ8Khag20QBBboLJDkGnBsytAyu7vNhTwUUKBBI8iHw1IKmO03TnC4osbCJR8WucvZTQIFDBZLcDPwOXHagYafj4XwNg8vFp4ACowgkOQscHzq0PCqOMl0WVUCBJbut3jutC7LuuFxjCigwuECSj4En5gqfbJrmjaEGMriGkrSOAgrsCiR5APhqjmOwnZY7LheZAgoMLpDkSuBX4KZZ8cFDy3tcg0+bBRXYboEk7wDPzxSqXuOpkfOoWKNlWwUUWCqQ5FHgszF3Wh4VXYAKKDCYQJL7gK+By4FRjofz/1h3XINNnYUU2E6BJEeB9rM11wCjHQ8Nru1cX161AoMLJLkF+Ba4cYqdlkfFwafQggpsl0CSI7OdVvtqz+jHQ3dc27W+vFoFRhGY+1zNJMdDg2uUabSoAtslkOQk8G/TNO0fep309x/j0Cs5myh5dQAAAABJRU5ErkJggg=="
                    }
                  />
                </div>
              </div>
              <div style={{ flex: 1 }}></div>
            </CalendarioEventiContaier>

            {this.state.showGallery && (
              <Transition in={this.state.startAnimating} timeout={100}>
                {(state) => (
                  <div
                    className={`fade-gallery-${state}`}
                    onClick={() => this.closeGallery()}
                    style={{
                      width: "100vw",
                      height: "100vh",
                      position: "fixed",
                      top: 0,
                      zIndex: 2000,
                      background: "rgba(0,0,0,0.8)",
                    }}
                  >
                    <div
                      style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        onKeyDown={(key) => console.log("key", key)}
                        onClick={this.handlePrevPhoto}
                        style={{
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: 64,
                          color: "white",
                          transform: "scaleY(2)",
                          cursor: "pointer",
                          zIndex: 2100,
                        }}
                      >
                        {"<"}
                      </div>
                      <div
                        style={{
                          flex: 8,
                          height: "100vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={
                            "https://backoffice.arboutiqueapartments.it" +
                            event.galleria[this.state.photo].url
                          }
                          style={{ height: "90%", maxWidth: "100%" }}
                        />
                      </div>
                      <div
                        onClick={this.handleNextPhoto}
                        style={{
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: 64,
                          color: "white",
                          transform: "scaleY(2)",
                          cursor: "pointer",
                          zIndex: 2100,
                        }}
                      >
                        {">"}
                      </div>
                    </div>
                  </div>
                )}
              </Transition>
            )}

            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                padding: "24vh",
                paddingBottom: "8vh",
              }}
            >
              <div style={{ flex: 1, paddingRight: "3vh" }}>
                <div className="">
                  <div className="aperitivoFCTitle">{event[`sottotitolo${this.props.lan === 'en' ? '_en' : ''}`]}</div>
                  <div
                    className="aperitivoFCText"
                    dangerouslySetInnerHTML={{ __html: event[`descrizione${this.props.lan === 'en' ? '_en' : ''}`] }}
                  />
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "50%",
                  overflow: "hidden",
                  alignItems: "center",
                }}
              >
                {event.galleria && event.galleria.length > 0 ? (
                  <Fragment>
                    <div style={{ display: "flex", flex: 1 }}>
                      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                        <div style={{ flex: 1, margin: 12 }}>
                          {event.galleria[0] && (
                            <img
                              onClick={() => this.openGallery(0)}
                              src={
                                "https://backoffice.arboutiqueapartments.it" + event.galleria[0].url
                              }
                              style={{
                                width: 400,
                                height: 286,
                                objectFit: "cover",
                                maxHeight: 286,
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </div>
                        <div style={{ flex: 1, margin: 12 }}>
                          {event.galleria[1] && (
                            <img
                              onClick={() => this.openGallery(1)}
                              src={
                                "https://backoffice.arboutiqueapartments.it" + event.galleria[1].url
                              }
                              style={{
                                width: 400,
                                height: 286,
                                objectFit: "cover",
                                maxHeight: 286,
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div style={{ flex: 1, margin: 12 }}>
                        {event.galleria[2] && (
                          <img
                            onClick={() => this.openGallery(2)}
                            src={
                              "https://backoffice.arboutiqueapartments.it" + event.galleria[2].url
                            }
                            style={{
                              width: 400,
                              height: 600,
                              objectFit: "cover",
                              maxHeight: 600,
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                      <div style={{ flex: 1, margin: 12 }}>
                        {event.galleria[3] && (
                          <img
                            onClick={() => this.openGallery(3)}
                            src={
                              "https://backoffice.arboutiqueapartments.it" + event.galleria[3].url
                            }
                            style={{
                              width: 824,
                              height: 286,
                              objectFit: "cover",
                              maxHeight: 600,
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </div>
                      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: 1, margin: 12 }}>
                          {event.galleria[4] && (
                            <img
                              onClick={() => this.openGallery(4)}
                              src={
                                "https://backoffice.arboutiqueapartments.it" + event.galleria[4].url
                              }
                              style={{
                                width: 400,
                                height: 286,
                                objectFit: "cover",
                                maxHeight: 286,
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </div>
                        <div style={{ flex: 1, margin: 12 }}>
                          {event.galleria[5] && (
                            <img
                              onClick={() => this.openGallery(5)}
                              src={
                                "https://backoffice.arboutiqueapartments.it" + event.galleria[5].url
                              }
                              style={{
                                width: 400,
                                height: 286,
                                objectFit: "cover",
                                maxHeight: 286,
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", flex: 1 }}>
                      <div style={{ flex: 1, margin: 12 }}>
                        {event.galleria[6] && (
                          <img
                            onClick={() => this.openGallery(6)}
                            src={
                              "https://backoffice.arboutiqueapartments.it" + event.galleria[6].url
                            }
                            style={{
                              width: 400,
                              height: 600,
                              objectFit: "cover",
                              maxHeight: 600,
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </div>
                      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                        <div style={{ flex: 1, margin: 12 }}>
                          {event.galleria[7] && (
                            <img
                              onClick={() => this.openGallery(7)}
                              src={
                                "https://backoffice.arboutiqueapartments.it" + event.galleria[7].url
                              }
                              style={{
                                width: 400,
                                height: 286,
                                objectFit: "cover",
                                maxHeight: 286,
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </div>
                        <div style={{ flex: 1, margin: 12 }}>
                          {event.galleria[8] && (
                            <img
                              onClick={() => this.openGallery(8)}
                              src={
                                "https://backoffice.arboutiqueapartments.it" + event.galleria[8].url
                              }
                              style={{
                                width: 400,
                                height: 286,
                                objectFit: "cover",
                                maxHeight: 286,
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div style={{ display: "flex", flex: 1 }}>
                      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                        <div style={{ flex: 1, margin: 12 }}>
                          <img
                            src="/static/media/jessica-ruscello-AbuQX0g4cdU-unsplash.7b893a76.jpg"
                            style={{ width: 400, height: 286, objectFit: "cover", maxHeight: 286 }}
                          />
                        </div>
                        <div style={{ flex: 1, margin: 12 }}>
                          <img
                            src="/static/media/scott-warman-rrYF1RfotSM-unsplash.39fa8c8f.jpg"
                            style={{ width: 400, height: 286, objectFit: "cover", maxHeight: 286 }}
                          />
                        </div>
                      </div>
                      <div style={{ flex: 1, margin: 12 }}>
                        <img
                          src="/static/media/justin-aikin-x-6HB8_qE4A-unsplash.df021343.jpg"
                          style={{ width: 400, height: 600, objectFit: "cover", maxHeight: 600 }}
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                      <div style={{ flex: 1, margin: 12 }}>
                        <img
                          src="/static/media/elevate-Euyr_8yImIQ-unsplash.dfd8effb.jpg"
                          style={{ width: 824, height: 286, objectFit: "cover", maxHeight: 600 }}
                        />
                      </div>
                      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: 1, margin: 12 }}>
                          <img
                            src="/static/media/lana-abie-De3-PUfrO5Q-unsplash.84b2b7e9.jpg"
                            style={{ width: 400, height: 286, objectFit: "cover", maxHeight: 286 }}
                          />
                        </div>
                        <div style={{ flex: 1, margin: 12 }}>
                          <img
                            src="/static/media/louis-hansel-yAo8dahtHgg-unsplash.64d12a97.jpg"
                            style={{ width: 400, height: 286, objectFit: "cover", maxHeight: 286 }}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", flex: 1 }}>
                      <div style={{ flex: 1, margin: 12 }}>
                        <img
                          src="/static/media/elevate-oRl7BoX7QCE-unsplash.d0dfe8a4.jpg"
                          style={{ width: 400, height: 600, objectFit: "cover", maxHeight: 600 }}
                        />
                      </div>
                      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                        <div style={{ flex: 1, margin: 12 }}>
                          <img
                            src="/static/media/fineas-anton-kC1-9Snr9hI-unsplash.38fcc335.jpg"
                            style={{ width: 400, height: 286, objectFit: "cover", maxHeight: 286 }}
                          />
                        </div>
                        <div style={{ flex: 1, margin: 12 }}>
                          <img
                            src="/static/media/filipe-de-rodrigues-i7Vy81pnZCI-unsplash.4b4829d9.jpg"
                            style={{ width: 400, height: 286, objectFit: "cover", maxHeight: 286 }}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ alignSelf: "flex-start", fontSize: 12 }}>
                      {this.props.lan === "en"
                        ? "* photos for demonstration purposes only"
                        : "* immagini solo a scopo illustrativo"}
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
            <div
              style={{
                background: "white",
                display: "flex",
                width: "100vw",
                alignItems: "center",
                justifyContent: "center",
                padding: "4vw 0",
              }}
            >
              <div
                className=""
                style={{
                  flex: 1,
                  cursor: "pointer",
                }}
                onClick={() => this.props.goToEvent(prevEv.id)}
              >
                {prevEv && (
                  <div
                    style={{
                      padding: 24,
                      fontSize: 18,
                      color: "#91855d",
                      border: "1px solid #91855d",
                      textDecoration: "underline",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {prevEv[`titolo${this.props.lan === 'en' ? '_en' : ''}`]}
                  </div>
                )}
              </div>
              <div
                className=""
                style={{
                  flex: 1,
                  cursor: "pointer",
                }}
                onClick={() => this.props.goToEvent(nextEv.id)}
              >
                {nextEv && (
                  <div
                    style={{
                      padding: 24,
                      fontSize: 18,
                      color: "#91855d",
                      border: "1px solid #91855d",
                      textDecoration: "underline",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {nextEv[`titolo${this.props.lan === 'en' ? '_en' : ''}`]}
                  </div>
                )}
              </div>
            </div>
            <Footer {...this.props} />
          </div>
        )}
      </Transition>
    );
  }
}

export default Evento;

const CalendarioEventiContaier = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;

  ::after {
    content: "";
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    background: ${(props) =>
      props.background
        ? props.background
        : "url('/static/media/priscilla-du-preez-nF8xhLMmg0c-unsplash.95da3521.jpg')"};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.6;
    height: 100vh;
  }
`;
