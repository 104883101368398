import React from 'react';
import "../style/style.css"
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import Header from "./Header";
import Logo from "./Logo";
import BottomMenu from "./BottomMenu";
import Form from 'react-bootstrap/Form';
import PrettyCheckbox from 'pretty-checkbox';
import SlideTracker from "./SlideTracker";
import LanguageSelector from "./LanguageSelector";
import { indexOf } from 'ramda';



class ContactPage extends React.Component {
    state = {
        previousPage: this.props.previousPage,
        nextPage: this.props.nextPage,
    };

    nextIndex = () => {
        const { nextPage } = this.state;
        this.props.history.push("/"+nextPage)
    };

    prevIndex = () => {
        const { previousPage } = this.state;
        this.props.history.push("/"+previousPage)
    };

    render(){
        return (

            <div>

                <span className="logoUpLeftInvertedColor"/>
                <Header {...this.props} colorBurger ="#91855d"/>
                <LanguageSelector defaultColor="#91855d"/>
                <div className="contactMainTitle">Contattaci</div>
                <div className="contactSubTitle">Per informazioni riguardo la disponibilitò degli appartamenti, l'iscrizione alla nostra newsletter e aggiornamenti sui nostri eventi.</div>
                <div className="contactName">Nome</div>
                <div className="contactEmail">Email</div>
                <div className="contactPrivacy">Privacy</div>
                <div className="contactCheckBox">
                    <div className="pretty p-default p-squared p-fill">
                        <input type="checkbox"/>
                        <div className="state">
                            <label>Acconsento al trattamento dei miei dati personali</label>
                        </div>
                    </div>
                </div>
                <form>
                    <input
                        value='ciao'
                        placeholder='ciao'
                        onChange={() => console.log('changed')}
                        className="contactNameInput"
                        type="text"
                    />
                    <input className="contactEmailInput"
                        type="text"
                    />
                    <textarea className="contactTextArea" value={this.state.value} onChange={this.handleChange}/>
                </form>
                <div className="contactMessage">Messaggio</div>
                <button className="contactSendButton">Invio</button>
                <SlideTracker slideTracker={this.props.sectionName}/>
                <div className="fixedBottomMenu">
                    <BottomMenu {...this.props}/>
                </div>
                <ReactScrollWheelHandler
                    timeout={0}
                    upHandler={this.prevIndex}
                    downHandler={this.nextIndex}
                    customStyle={{
                        width: "100%",
                        height: "100vh",
                    }}
                />
            </div>
        );
    }
}

export default ContactPage;