import React, {Fragment} from 'react';
import "../style/style.css";
import { TransitionGroup, CSSTransition} from "react-transition-group";


class LanguageSelectorRelative extends React.Component{
    state = {
        languageSelected: "It",
    };
    setIt = () => {
        this.setState({languageSelected: "It"});
    };
    setEn = () => {
        this.setState({languageSelected: "En"});
    };
    renderSelectorUpdated = () => {
        if(this.state.languageSelected === "It") {
            return (
                <Fragment>
                    <span className="italianLanguageRelativeIt" onClick={this.setIt} >It</span>
                    <span className="englishLanguageRelativeIt" onClick={this.setEn} style={{color: this.props.defaultColor}}>En</span>
                    <hr className="languageVerticalLineRelative" styles={{color: this.props.defaultColor}}></hr>
                </Fragment>
            )

        }
        else if(this.state.languageSelected === "En"){
            return(
                <Fragment>
                    <span className="italianLanguageRelativeEn" onClick={this.setIt} style={{color: this.props.defaultColor}}>It</span>
                    <span className="englishLanguageRelativeEn" onClick={this.setEn} >En</span>
                    <hr className="languageVerticalLineRelative"></hr>
                </Fragment>
            )
        }


    }
    render(){

        return(
            <Fragment>
                <div>{this.renderSelectorUpdated()}</div>
            </Fragment>

        )
    }
}

export default LanguageSelectorRelative;