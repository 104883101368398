import React, { Fragment } from "react";
import Header from "./Header";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import "../style/style.css";
import RightArrow from "./RightArrow";
import {
  TransitionGroup,
  CSSTransition,
  Transition,
} from "react-transition-group";
import Logo from "./Logo";
import SlideTracker from "./SlideTracker";
import SectionName from "./SectionName";
import Test from "./Test";
import LanguageSelector from "./LanguageSelector";
import Home from "./Home";
import DownArrow from "./DownArrow";
import BottomArrows from "./BottomArrows";
import ContactPageImplementation from "./ContactPageImplementation";
import MainHeader from "./MainHeader";
import axios from "axios";
import { isMobile } from "react-device-detect";
import HomeBackground from "../img/riempitivi/WEB/texture2.0.png";
import LogoHome from "../img/Export_Asset/Logo_Home.png";
import ArrowDownHome from "../img/Export_Asset/Previous_B_1.png";
import ArrowDownMobile from "../img/arrow_down_mobile.png";
import ArrowRight from "../img/Export_Asset/Forward_B_1.png";
import ServiziBackground from "../img/riempitivi/WEB/thought-catalog-505eectW54k-unsplash.jpg";
import AppartamentiBackground from "../img/WhatsApp_Image_2020-03-12_at_13.49.45.jpeg";
import EventiBackground from "../img/riempitivi/WEB/priscilla-du-preez-nF8xhLMmg0c-unsplash.jpg";
import VicinoANoiBackground from "../img/riempitivi/WEB/Horizontal WEB/_MG_6774-1_01.jpg";
import {
  translate,
  translateSectionPresentationTitle,
} from "../utils/translations";

class SectionPresentation extends React.Component {
  setInitialAnimation = () => {
    this.props.home
      ? this.setState({ animation: "fadeInslideFromSx" })
      : this.setState({ animation: "sectionTitle" });
  };

  state = {
    previousPage: this.props.previousPage,
    nextPage: this.props.nextPage,
    inProp: true,
    rightArrow: this.props.rightArrow,
    animation: this.setInitialAnimation,
    animationDownArrow: "sectionTitleReverse",
    contactPageAnimation: "contactMainTitleReverse",
    backgroundAnimation: "fadeBackground",
    timeFade: 1400,
    logoHomeAnimation: "fadeLogo",
    colorHeader: "#ffffff",
    colorSectionName: "#ffffff",
    fadeOut: false,
    pagine: [],
    sections: [],
    fadeLogoHome: false,
    showLogoMobile: false,
    index: 0,
  };

  setColorHeader(color) {
    this.setState({ colorHeader: color });
  }

  nextIndex = () => {
    const { nextPage } = this.props;

    this.setState({ animation: "sectionTitle" });
    this.setState({ logoHomeAnimation: "sectionTitle" });
    this.setState({ contactPageAnimation: "contactMainTitleReverse" });
    this.setState({ backgroundAnimation: "fadeSection" });
    this.setState({ timeFade: 500 });
    setTimeout(() => {
      this.props.history.push("/" + nextPage);
    }, 50);
  };

  componentWillMount = async () => {
    this.setInitialAnimation();
    try {
      const result = await axios({
        url: "https://backoffice.arboutiqueapartments.it/graphql",
        method: "post",
        data: {
          query: `
            query {
                paginas {
                    id
                    identificativo
                    testo
                    Testo_en
                }
                sottopaginas {
                    id
                    identificativo
                    Titolo
                    descrizione
                    Titolo_en
                    descrizione_en
                }
            }
        `,
        },
      });
      // console.log('result', result)
      this.setState({
        loading: false,
        pagine: result.data.data.sottopaginas,
        sections: result.data.data.paginas,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  componentDidMount() {
    if (isMobile) {
      document.getElementById("main").addEventListener("scroll", this.onScroll);
      // console.log(this.props.history)
      const hash = this.props.history.location.hash.substring(1);
      if (!!hash) {
        const div = document.getElementById(hash);
        if (!!div) {
          div.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    }
    this.setState({
      fadeLogoHome: true,
      // height: window.innerHeight
    });
  }

  componentDidUpdate(oldProps) {
    if (oldProps.history.location.hash !== this.props.history.location.hash) {
      // console.log('changed')
      const hash = this.props.history.location.hash.substring(1);
      if (!!hash) {
        const div = document.getElementById(hash);
        if (!!div) {
          div.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    }
  }

  componentWillUnmount() {
    if (isMobile) {
      document
        .getElementById("main")
        .removeEventListener("scroll", this.onScroll);
    }
  }

  onScroll = (event) => {
    // console.log('scrolling', document.getElementById('main').scrollTop)
    // console.log('height', window.innerHeight)
    const position = document.getElementById("main").scrollTop;
    const height = window.innerHeight;
    this.setState({
      showLogoMobile:
        position >= (height * 50) / 100 && position <= height * 5.5,
    });
  };

  setInitialAnimation = () => {
    this.props.home
      ? this.setState({ animation: "fadeLogo" })
      : this.setState({ animation: "fade" });

    this.props.home
      ? this.setState({ backgroundAnimation: "fadeBackground" })
      : this.setState({ backgroundAnimation: "fadeSection" });

    this.props.home
      ? this.setState({ timeFade: 1400 })
      : this.setState({ timeFade: 500 });

    this.props.home
      ? this.setState({ animationDownArrow: "fadeArrow" })
      : this.setState({ timeFade: 500 });
  };

  prevIndex = () => {
    const { previousPage } = this.props;
    this.setState({ animation: "sectionTitleReverse" });
    this.setState({ logoHomeAnimation: "sectionTitleReverse" });
    this.setState({ contactPageAnimation: "contactMainTitleReverse" });
    this.setState({ backgroundAnimation: "fadeSection" });
    this.setState({ timeFade: 500 });
    this.props.home
      ? this.setState({ animationDownArrow: "fadeArrow" })
      : this.setState({ animationDownArrow: "sectionTitle" });
    setTimeout(() => {
      this.props.history.push("/" + previousPage);
    }, 50);
  };

  handleFadeout = () => {
    this.setState({
      fadeOut: !this.state.fadeOut,
    });
  };

  setContactLogoAnimation = () => {
    this.setState({ contactPageAnimation: "fade" });
  };

  goDown = () => {
    this.setState({
      animation: "sectionTitle",
      logoHomeAnimation: "sectionTitle",
    });
  };

  goHome = () => {
    this.setState({ backgroundAnimation: "fadeBackground" });
    this.setState({ animation: "fadeLogo" });
    this.setState({ animationDownArrow: "fadeArrow" });
    this.props.history.push("/");
  };

  setAnimationHome = () => {
    this.setState({ backgroundAnimation: "fadeBackground" });
    this.setState({ animation: "fadeLogo" });
    this.setState({ animationDownArrow: "fadeArrow" });
  };

  setAnimationNotHome = () => {
    this.setState({ animation: "sectionTitle" });
  };

  handleSendEmail = async () => {
    // console.log('clicked')
    if (this.state.email) {
      // console.log('email', this.state.email)
      axios({
        method: "POST",
        url: "https://backoffice.arboutiqueapartments.it/email",
        data: {
          to: "info@arboutiqueapartments.it",
          from: "info@arboutiqueapartments.it",
          replyTo: this.state.email,
          subject: this.state.nome,
          text: `test`,
          html: `
            ${this.state.email}<br>
            ${this.state.nome}<br>
            ${this.state.testo}<br><br>
            ${
              this.state.checkbox
                ? "Acconsento al trattamento dei dati personali"
                : "NON acconsento al trattamento dei dati personali"
            }
          `,
        },
      })
        .then((res) => {
          // console.log('email sent correctly')
          // console.log('email: ', res)
          this.setState({
            nome: "",
            email: "",
            testo: "",
            checkbox: false,
          });
        })
        .catch((err) => {
          // console.log('error sending mail: ', err)
        });
    }
  };

  render() {
    // console.log('state', this.state)
    // console.log('lan', this.props.lan)
    if (isMobile) {
      // console.log('state', this.state)
      const data = [
        {
          home: true,
          nextPage: "Concept",
          sectionName: "Home",
          backgroundImage: HomeBackground,
          title: <img src={LogoHome} style={{ width: "70%" }} />,
          subTitle: (
            <div
              style={{
                fontSize: 24,
                color: "#91855D",
                marginTop: 32,
              }}
            >
              Affittiamo l'esclusività
            </div>
          ),
          footer: (
            <img
              // className="bounceOnHover"
              src={ArrowDownHome}
              style={{ marginBottom: 100 }}
            />
          ),
        },
        {
          home: false,
          nextPage: "Servizi",
          sectionName: "Concept",
          backgroundImage:
            "/static/media/kirill-9uH-hM0VwPg-unsplash.0df7defd.jpg",
          title: (
            <div style={{ color: "white", fontSize: "13vw" }}>
              {translate(
                this.state.sections,
                "concept",
                this.props.lan === "en" ? "Testo_en" : "testo"
              )}
            </div>
          ),
          subTitle: (
            <a
              href="/Concept1"
              style={{
                marginTop: 24,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={ArrowRight} style={{ width: "45%" }} />
            </a>
          ),
          footer: <img src={ArrowDownMobile} style={{ marginBottom: 60 }} />,
        },
        {
          home: false,
          nextPage: "Appartamenti",
          sectionName: "Servizi",
          backgroundImage: ServiziBackground,
          title: (
            <div style={{ color: "white", fontSize: "13vw" }}>
              {translate(
                this.state.sections,
                "servizi",
                this.props.lan === "en" ? "Testo_en" : "testo"
              )}
            </div>
          ),
          subTitle: (
            <a
              href="/Servizi1"
              style={{
                marginTop: 24,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={ArrowRight} style={{ width: "45%" }} />
            </a>
          ),
          footer: <img src={ArrowDownMobile} style={{ marginBottom: 60 }} />,
        },
        {
          home: false,
          nextPage: "Eventi",
          sectionName: "Appartamenti",
          backgroundImage: AppartamentiBackground,
          title: (
            <div style={{ color: "white", fontSize: "11vw" }}>
              {translate(
                this.state.sections,
                "appartamenti",
                this.props.lan === "en" ? "Testo_en" : "testo"
              )}
            </div>
          ),
          subTitle: (
            <a
              href="/Appartamenti1"
              style={{
                marginTop: 24,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={ArrowRight} style={{ width: "45%" }} />
            </a>
          ),
          footer: <img src={ArrowDownMobile} style={{ marginBottom: 60 }} />,
        },
        {
          home: false,
          nextPage: "Vicino a noi",
          sectionName: "Eventi",
          backgroundImage: EventiBackground,
          title: (
            <div style={{ color: "white", fontSize: "13vw" }}>
              {translate(
                this.state.sections,
                "eventi",
                this.props.lan === "en" ? "Testo_en" : "testo"
              )}
            </div>
          ),
          subTitle: (
            <a
              href="/Eventi1"
              style={{
                marginTop: 24,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={ArrowRight} style={{ width: "45%" }} />
            </a>
          ),
          footer: <img src={ArrowDownMobile} style={{ marginBottom: 60 }} />,
        },
        {
          home: false,
          nextPage: "Contatti",
          sectionName: "Vicino a noi",
          backgroundImage: VicinoANoiBackground,
          title: (
            <div style={{ color: "white", fontSize: "13vw" }}>
              {translate(
                this.state.sections,
                "vicino",
                this.props.lan === "en" ? "Testo_en" : "testo"
              )}
            </div>
          ),
          subTitle: (
            <a
              href="/VicinoANoi1"
              style={{
                marginTop: 24,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={ArrowRight} style={{ width: "45%" }} />
            </a>
          ),
          footer: <img src={ArrowDownMobile} style={{ marginBottom: 60 }} />,
        },
      ];
      const contactData = this.state.pagine.find(
        (page) => page.identificativo === "Contattaci1"
      );
      const height = window.innerHeight;
      return (
        <div
          id="main"
          style={{
            height: "100vh",
            overflow: "scroll",
            scrollSnapType: "y mandatory",
          }}
        >
          <MainHeader
            {...this.props}
            animated
            show={this.state.showLogoMobile}
            position="fixed"
            setAnimationHome={this.setAnimationHome}
            inverted={false}
            background="trasparent"
          />
          {data.map((page) => {
            if (!!page.home) {
              return (
                <>
                  <section
                    id={page.sectionName}
                    style={{
                      width: "100vw",
                      height,
                      display: "flex",
                      flexDirection: "column",
                      scrollSnapAlign: "start",
                    }}
                  >
                    <Transition in={this.state.fadeLogoHome} timeout={500}>
                      {(state) => (
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `
                                <video
                                  playsinline
                                  autoPlay
                                  muted
                                  loop
                                  poster="${HomeBackground}"
                                  id="bgvid"
                                  style="height: ${height}px; position: fixed; width: 110vw; right: -3.5vw; left: unset; z-index: -1">
                                  <source src="${require("../img/sfondo_video_low.mp4")}" type="video/mp4" />
                                </video>
                              `,
                            }}
                          />
                          <div
                            style={{
                              height,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div style={{ flex: 1 }} />
                            <div
                              style={{
                                flex: 2,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                className={`fadeLogo-${state}`}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {page.title}
                                {page.subTitle}
                              </div>
                            </div>
                            <div
                              className={`fadeLogo-${state}`}
                              style={{
                                flex: 1,
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "center",
                              }}
                            >
                              <a
                                href={`#${page.nextPage}`}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {page.footer}
                              </a>
                            </div>
                          </div>
                        </>
                      )}
                    </Transition>
                  </section>
                </>
              );
            }
            return (
              <>
                <section
                  id={page.sectionName}
                  style={{
                    background: `${
                      !page.home
                        ? "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),"
                        : ""
                    } url(${page.backgroundImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100vw",
                    // height: '90vh',
                    height,
                    display: "flex",
                    // opacity: page.home ? 1 : 0.5,
                    flexDirection: "column",
                    scrollSnapAlign: "start",
                  }}
                >
                  {/* <div style={{flex: 1}} />
                                    <div style={{flex: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}> */}
                  {page.home ? (
                    <Transition in={this.state.fadeLogoHome} timeout={500}>
                      {(state) => (
                        <>
                          <div style={{ flex: 1 }} />
                          <div
                            style={{
                              flex: 2,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              className={`fadeLogo-${state}`}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {page.title}
                              {page.subTitle}
                            </div>
                          </div>
                          <div
                            className={`fadeLogo-${state}`}
                            style={{
                              flex: 1,
                              display: "flex",
                              alignItems: "flex-end",
                              justifyContent: "center",
                            }}
                          >
                            <a
                              href={`#${page.nextPage}`}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {page.footer}
                            </a>
                          </div>
                        </>
                      )}
                    </Transition>
                  ) : (
                    <>
                      <div style={{ flex: 1, position: "relative" }} />
                      <div
                        style={{
                          flex: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        {page.title}
                        {page.subTitle}
                      </div>

                      {!!isMobile && (
                        <a
                          href="https://www.arconceptlife.com/ar-boutique-apartments#widget-section"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="prenotaBtn"
                          style={{
                            position: "absolute",
                            left: 16,
                            bottom: 16,
                            zIndex: 100,
                            borderRadius: 20,
                            padding: 12,
                            fontSize: "0.9rem",
                          }}
                        >
                          {this.props.lan === "ita"
                            ? "Prenota qui"
                            : "Book here"}
                        </a>
                      )}
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href={`#${page.nextPage}`}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {page.footer}
                        </a>
                      </div>
                    </>
                  )}
                </section>
              </>
            );
          })}
          <section
            id="Contatti"
            style={{
              // minHeight: '100vh',
              height: "160vh",
              // overflow: "scroll",
              width: "100vw",
              backgroundColor: "white",
              scrollSnapAlign: "start",
              // scrollSnapStop: "always",
            }}
          >
            <div style={{ flex: 1, position: "relative" }}>
              <MainHeader
                {...this.props}
                // animated
                // show={this.state.showLogoMobile}
                position="absolute"
                setAnimationHome={this.setAnimationHome}
                inverted
                background="trasparent"
              />
            </div>
            <div style={{ padding: 48, paddingTop: 160 }}>
              <div
                style={{
                  fontSize: 32,
                  color: "#91855D",
                  marginBottom: 24,
                }}
              >
                {!!contactData &&
                  contactData[`Titolo${this.props.lan === "en" ? "_en" : ""}`]}
              </div>
              {/* <div
                style={{
                  marginBottom: 48,
                  lineHeight: "28px",
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    !!contactData &&
                    contactData[`descrizione${this.props.lan === "en" ? "_en" : ""}`],
                }}
              /> */}
              <div>
                <div className="contactInputLabel">
                  {this.props.lan === "en" ? "Name" : "Nome"}
                </div>
                <input
                  onChange={(ev) => this.setState({ nome: ev.target.value })}
                  className="contactInput"
                />
              </div>
              <div>
                <div className="contactInputLabel">Email</div>
                <input
                  onChange={(ev) => this.setState({ email: ev.target.value })}
                  className="contactInput"
                />
              </div>
              <div>
                <div className="contactInputLabel">Privacy</div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 8,
                    marginBottom: 16,
                  }}
                >
                  <input
                    style={{ width: 32, height: 32 }}
                    onChange={() =>
                      this.setState({ checkbox: !this.state.checkbox })
                    }
                    type="checkbox"
                  />
                  <div
                    style={{
                      marginLeft: 16,
                    }}
                  >
                    {this.props.lan === "en"
                      ? "Acconsento al trattamento dei miei dati personali"
                      : "Acconsento al trattamento dei miei dati personali"}
                  </div>
                </div>
              </div>
              <div>
                <div className="contactInputLabel">
                  {this.props.lan === "en" ? "Message" : "Messaggio"}
                </div>
                <input
                  style={{ marginTop: 16 }}
                  onChange={(ev) => this.setState({ testo: ev.target.value })}
                  className="contactInputArea"
                />
              </div>
              <div style={{ marginTop: 24 }}>
                <div
                  onClick={this.handleSendEmail}
                  style={{
                    border: "1px solid #91855D",
                    padding: "8px 24px",
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "10%",
                    minWidth: 60,
                  }}
                >
                  {this.props.lan === "en" ? "Send" : "Invia"}
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    }
    return (
      <ReactScrollWheelHandler
        timeout={1000}
        upHandler={this.prevIndex}
        downHandler={this.nextIndex}
        customStyle={{
          width: "100%",
          height: "100vh",
          zIndex: !this.props.contact ? 800 : 1000,
          position: "fixed",
          border: 0,
          backgroundColor: "transparent",
        }}
      >
        <Transition in={this.state.fadeOut} timeout={500}>
          {(state) => (
            <div className={`fade fade-${state}`}>
              <TransitionGroup>
                <CSSTransition
                  classNames={this.state.backgroundAnimation}
                  key={this.props.sectionName}
                  timeout={{ enter: this.state.timeFade, exit: 500 }}
                  in={this.state.inProp}
                  unmountOnExit={true}
                  appear
                >
                  {this.props.home & !this.props.contact ? (
                    // ? <div className="home" />
                    <video
                      playsinline
                      autoPlay
                      muted
                      loop
                      poster={HomeBackground}
                      id="bgvid"
                    >
                      <source src={require("../img/sfondo_video_low.mp4")} type="video/mp4" />
                    </video>
                  ) : (
                    <Fragment>
                      <div
                        className={this.props.sectionName.replace(/\s+/g, "")}
                      />
                    </Fragment>
                  )}
                </CSSTransition>
              </TransitionGroup>

              {!this.props.home && (
                <Fragment>
                  <MainHeader
                    {...this.props}
                    position="relative"
                    top={0}
                    setAnimationHome={this.setAnimationHome}
                    inverted={this.props.contact}
                    background="trasparent"
                  />
                  {/* {!this.props.contact && <SlideTracker {...this.props} slideTracker={this.props.sectionName} position={this.props.position} />} */}
                  <SlideTracker
                    {...this.props}
                    slideTracker={this.props.sectionName}
                    position={this.props.position}
                    home={this.props.home}
                  />
                </Fragment>
              )}

              <SectionName
                {...this.props}
                sections={this.state.sections}
                home={!!this.props.home}
                section={!!this.props.section}
                contact={!!this.props.contact}
                sectionId={this.props.sectionId}
                sectionName={this.props.sectionName}
                animationSection={this.state.animation}
                logoHomeAnimation={this.state.logoHomeAnimation}
                goHome={this.goHome}
                setAnimationHome={this.setAnimationHome}
                setAnimationNotHome={this.setAnimationNotHome}
                handleFadeout={this.handleFadeout}
                fadeTime={this.state.timeFade}
              />
              <BottomArrows
                handleFadeout={this.handleFadeout}
                {...this.props}
                home={!!this.props.home}
                section={!!this.props.section}
                goDown={this.goDown}
                sectionName={this.props.sectionName}
                classType="menuDownButtonCentered"
                downArrow={this.props.nextPage}
                animationSection={this.state.animation}
                animationDownArrow={this.state.animationDownArrow}
                fadeTime={this.state.timeFade}
              />

              {/* <div className="mobileFlex">
                <div className="mobile">
									<div className="logoMobile" />
									// Visita il sito da desktop
                </div>
            	</div> */}

              <ContactPageImplementation
                {...this.props}
                sections={this.state.sections}
                contact={!!this.props.contact}
                texts={this.state.pagine.find(
                  (sp) => sp.identificativo === "Contattaci1"
                )}
                {...this.props}
                setContactLogoAnimation={this.setContactLogoAnimation}
                sectionName={this.props.sectionName}
                animation={this.state.animation}
                contactPageAnimation={this.state.contactPageAnimation}
                setColorHeader={() => this.setColorHeader()}
                goHome={this.goHome}
              />

              {!!this.props.home && (
                <div
                  style={{
                    position: "absolute",
                    top: "6vh",
                    right: "6vh",
                    zIndex: 1000,
                    fontSize: 16,
                    color: "#91855d",
                    fontWeight: "bold",
                  }}
                >
                  <a
                    href="https://www.arconceptlife.com/ar-boutique-apartments#widget-section"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="prenotaBtn"
                  >
                    {this.props.lan === "ita" ? "Prenota Ora" : "Book Now"}
                  </a>
                  +39 035 5785067
                </div>
              )}

              {!this.props.contact && (
                <div onClick={this.nextIndex} className="downArrowMobile">
                  <img
                    alt="down-arrow-icon"
                    className="downArrow"
                    src={
                      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAS4AAAA8CAYAAADYKVdnAAAABHNCSVQICAgIfAhkiAAABOxJREFUeF7t3U/opVMcx/H340/EgiwYSUmaFCkLqSnZsLYgRUhNlJ2kTM1vRIxGkp2iKSFKWdhKymaQheykwV6xl9JHz687P/f3m3t/95zn361733d9zvfM8zqnT+c88zzPr8GfAgoo0EEgyVvAX03TnOnQvVeXpldvOyugwNYKzILrReBE0zRvTglhcE2p7VgKbJhAkveBZ6YOL4NrwxaSl6PA1AJJPgKenDK8DK6pZ9nxFNgwgSRtjnwJPDhVeBlcG7aIvBwF1iGQ5CrgO+CuKcLL4FrHLDumAhsokOQG4CfgyNjhZXBt4ALykhRYl0CS+4FvZuOP9r+NBte6ZthxFdhQgSSfAo+NGV4G14YuHi9LgXUJJLkO+A24dqzwMrjWNbuOq8AGCyR5Dnh37hJ3mqY5PdQlG1xDSVpHAQX2BJJcApwHbp1jGeyel8HlYlNAgVEEkjwOfHKg+CDhZXCNMmUWVUCBViDJz8DtQ4eXweX6UkCB0QSSPA18sGCAXjsvg2u0KbOwAgokuaL99A1w9ZDhZXC5thRQYFSBJGeB40sG6bTzMrhGnTKLK6BAkmPAuUMkTjVN83qNlMFVo2VbBRSoFph9PeJPoH0wddmvaudlcFVPgx0UUKBWIMnnwMMr+hWHl8FVOwO2V0CBaoEFT9L32nkZXNVTYAcFFKgVSHIU+KWw38p7XgZXoaTNFFCgn0CSv4H28YiS36HHRoOrhNA2CijQWyDJj8DdFYWWhpfBVaFoUwUU6C6QpH1vsX1/sea3MLwMrhpC2yqgQGeBJDvAax0KXHTPy+DqoGgXBRSoF0jyLPBefc/dHvt2Xk2SVzoWspsCCihQI3AH8EhNhwNt98KrDa70KGRXBRRQYEqB3fAyuKYkdywFFBhC4JTBNQSjNRRQYEqBEwbXlNyOpYACfQX2jorenO9LaX8FFCgRuLPgRevD6vx/c75kNNsooIACfQV6Pg6x71kun+PqOxv2V0CBIoEkZ4CXihrvb3TR0/MGVwdFuyigQL1Aki+Ahyp7+spPJZjNFVBgQIElf6qs6J7WwUbuuAacGEspoMBigdlftv4HuLTQyM/aFELZTAEFRhJIci/wfWH5lZ9wdsdVKGkzBRToLpDkVeDlggorQ6utYXAVSNpEAQX6CST5AbhnRZWi0DK4+s2FvRVQoEAgyfXAHyuarvzO/Hx/d1wF8DZRQIHuAkleAN4+pELxTutCDYOr+3zYUwEFCgSSnAduW9K0OrQ8Khag20QBBboLJDkGnBsytAyu7vNhTwUUKBBI8iHw1IKmO03TnC4osbCJR8WucvZTQIFDBZLcDPwOXHagYafj4XwNg8vFp4ACowgkOQscHzq0PCqOMl0WVUCBJbut3jutC7LuuFxjCigwuECSj4En5gqfbJrmjaEGMriGkrSOAgrsCiR5APhqjmOwnZY7LheZAgoMLpDkSuBX4KZZ8cFDy3tcg0+bBRXYboEk7wDPzxSqXuOpkfOoWKNlWwUUWCqQ5FHgszF3Wh4VXYAKKDCYQJL7gK+By4FRjofz/1h3XINNnYUU2E6BJEeB9rM11wCjHQ8Nru1cX161AoMLJLkF+Ba4cYqdlkfFwafQggpsl0CSI7OdVvtqz+jHQ3dc27W+vFoFRhGY+1zNJMdDg2uUabSoAtslkOQk8G/TNO0fep309x/j0Cs5myh5dQAAAABJRU5ErkJggg=="
                    }
                  />
                </div>
              )}
            </div>
          )}
        </Transition>
      </ReactScrollWheelHandler>
    );
  }
}

export default SectionPresentation;
