import React from 'react';
import "../style/style.css"


class MapMenu extends React.Component{
    render(){
        const { selected } = this.props
        return(
            <div className="mapMenu">
                <span
                    style={{color: selected === 'places' ? '#e6ce3c' : ''}}
                    onClick={() => this.props.changeLocationType('places')}
                    className="mapMenuItem">Tutti</span>
                <span
                    style={{color: selected === 'banche' ? '#e6ce3c' : ''}}
                    onClick={() => this.props.changeLocationType('banche')}
                    className="mapMenuItem">Banche</span>
                <span
                    style={{color: selected === 'supermarkets' ? '#e6ce3c' : ''}}
                    onClick={() => this.props.changeLocationType('supermarkets')}
                    className="mapMenuItem">Supermarket</span>
                <span
                    style={{color: selected === 'farmacie' ? '#e6ce3c' : ''}}
                    onClick={() => this.props.changeLocationType('farmacie')}
                    className="mapMenuItem">Farmacie</span>
                <span
                    style={{color: selected === 'ospedali' ? '#e6ce3c' : ''}}
                    onClick={() => this.props.changeLocationType('ospedali')}
                    className="mapMenuItem">Ospedali</span>
                <span
                    style={{color: selected === 'cinemas' ? '#e6ce3c' : ''}}
                    onClick={() => this.props.changeLocationType('cinemas')}
                    className="mapMenuItem">Cinema</span>
            </div>
        )
    }
}

export default MapMenu;