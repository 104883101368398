import React from 'react';
import "../style/style.css";
import { TransitionGroup, CSSTransition} from "react-transition-group";

class RightArrow extends React.Component{
    state = {
        rightArrow: this.props.rightArrow,
    };

    goRight = (event) => {
        event.preventDefault();
        const rightArrow = this.state.rightArrow;
        this.props.history.push("/"+this.props.rightArrow);
    };

    render(){
        return(
            <TransitionGroup>
                <CSSTransition classNames={this.props.animationSection}
                               key={this.props.sectionName}
                               timeout={{ enter: 500, exit: 500 }}
                               in = {this.state.inProp}
                               unmountOnExit={true}
                >
                    <div className="menuRightButton" onClick={this.goRight}></div>
                </CSSTransition>
            </TransitionGroup>

        )
    }
}

export default RightArrow;