import React, {Fragment} from 'react';
import "../style/style.css"
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import Logo from "./Logo";
import BottomMenu from "./BottomMenu";
import Form from 'react-bootstrap/Form';
import PrettyCheckbox from 'pretty-checkbox';
import SlideTracker from "./SlideTracker";
import LanguageSelector from "./LanguageSelector";
import { TransitionGroup, CSSTransition, Transition} from "react-transition-group";
import axios from 'axios'
import Header from './MainHeader'
import Footer from './Footer'


class PrivacyPolicy extends React.Component {

  render(){
    return (
      <Fragment>
        <Header {...this.props} inverted background='transparent' />
        
        <div
          style={{padding: '16vh 20vw'}}
          dangerouslySetInnerHTML={{
            __html: `Gentile Utente/Interessato,<br>
            la presente Informativa è resa ai sensi dell’art. 13 del D. Lgs. 30 giungo 2003 n. 196 e successive modifiche (cd. Codice Privacy), nonché ai sensi dell’art. 14 del Regolamento (UE) 2016/679 del Parlamento Europeo e del Consiglio del 27 aprile 2016.
            <br><br>
            Ti informiamo che i dati personali da Te forniti nell’ambito della consultazione del sito www.arboutiqueapartments.it saranno trattati da AR Boutique Apartments in qualità di Titolare del trattamento (di seguito anche Titolare) nel rispetto dei principi di protezione stabiliti dal Codice in materia di dati personali e successive modifiche, nonché di tutti gli interventi legislativi europei e nazionali e / o provvedimenti delle Autorità di controllo.
            <br><br>
            L’informativa che segue è resa per il solo sito di AR Boutique Apartments e non anche per altri siti web eventualmente consultati dall’Utente tramite link.
            <br><br>
            Ti informiamo che l’invio facoltativo, esplicito e volontario di messaggi di posta elettronica agli indirizzi indicati su questo sito, nonché la compilazione di form di contatto comporta l’acquisizione dell’indirizzo del mittente, necessario per rispondere alle richieste, nonché degli eventuali altri dati personali inseriti nel messaggio.
            <br><br>
            Ti informiamo infine che AR Boutique Apartments potrà proporti, via posta elettronica – qualora tu ci abbia fornito il tuo indirizzo e abbia prestato il consenso – l’acquisto di prodotti o servizi analoghi a quelli che ci hai già richiesto. In tal caso, sarà nostra cura ricordarti sempre la possibilità di manifestarci la volontà di non ricevere ulteriori analoghe comunicazioni e che nel corso della tua navigazione sulle pagine del sito di AR Boutique Apartments potrà installare sul suo browser cookie tecnici allo scopo di migliorare la tua esperienza d’uso.
            <br><br>
            Troverai maggiori dettagli a proposito di tali cookie e dei trattamenti ad essi connessi nel paragrafo “COOKIE”.
            <br><br>
            A. FINALITA’ DEL TRATTAMENTO<br>
            Il trattamento dei dati spontaneamente forniti dall’Utente nel corso della navigazione per via telematica tramite la compilazione dei form richiesta informazioni è effettuato da AR Boutique Apartments per le seguenti finalità:
            <br><br>
            a. consentirci di dare seguito alle richieste da te pervenute. A tal fine sono richiesti i seguenti dati: nome, cognome, azienda, e-mail e numero di telefono (quest’ultimo facoltativo);
            <br><br>
            b. previo consenso, per conseguire l’efficace instaurazione e gestione dei rapporti commerciali, con particolare riferimento a finalità promozionali, pubblicitarie, di marketing, relativi a prodotti e servizi forniti da AR Boutique Apartments
            <br><br>
            c. pervio consenso, per analizzare le tue abitudini e scelte al fine dell’invio di materiale commerciale più appropriato alle caratteristiche di questi.
            <br><br>
            B. TIPOLOGIA DI DATI RACCOLTI E TRATTATI<br>
            Ferma restando l’autonomia personale dell’Interessato e fermo restando il conferimento di dati di navigazione, il conferimento dei dati di cui al paragrafo A lettera a), è obbligatorio ed il mancato conferimento, anche parziale, dei dati espressamente indicati come necessari determinerà l’impossibilità per AR Boutique Apartments di procedere all’evasione della richiesta pervenuta. I dati obbligatori sono contrassegnati dal simbolo asterisco. Il conferimento dei dati di cui al paragrafo A lettera b) e c) è facoltativo ed il mancato conferimento per tali finalità comporterà l’impossibilità di aggiornare gli Utenti sulle iniziative promozionali di vendita.
            <br><br>
            C. TITOLARE, RESPONSABILI E INCARICATI<br>
            Il titolare del trattamento è AR Boutique Apartments ,in persona del suo legale rappresentante pro-tempore, Arizzi Roberto codice fiscale RZZRRT66C15A794T - PARTITA IVA 03626880169 con sede in via Quarenghi 7, Bergamo (BG), indirizzo pec: roberto.arizzi@pec.it e-mail: info@arboutiqueapartments.it.<br>
            <br>
            Ti informiamo che puoi venire in contatto con il responsabile del trattamento ai recapiti della Società sopra indicati.<br>
            Si informa che i Dati forniti verranno trattati da: Arizzi Roberto in qualità di incaricati al trattamento.<br>
            <br>
            D. MODALITÀ DI TRATTAMENTO<br>
            I Dati personali forniti verranno trattati presso la sede di AR Boutique Apartments anche mediante l’utilizzo di procedure automatizzate nei modi e nei limiti necessari per perseguire le predette finalità. Si informa altresì che i Dati personali forniti verranno trattati con l’utilizzo di procedure informatizzate nei modi e nei limiti necessari per perseguire le predette finalità.<br>
            Il titolare si avvale dei servizi resi dalla società Locatelli Alessandro, C.F: LCTLSN95T24I628V, P.IVA: 04285560167, con sede in Via Milano, 8, 24011 Almè (BG) responsabile esterno per la fornitura del servizio Housing, con sede in Via Daste e Spalenga, 11, 24125 Bergamo BG.<br>
            <br>
            <br>
            E. PERIODO DI CONSERVAZIONE<br>
            Si informa che i Dati forniti verranno trattati e conservati dal Titolare per le finalità strettamente collegate alle finalità di cui al punto A e conservati presso il Titolare per il periodo strettamente necessario all’evasione delle richieste formulate dall’Utente. Al termine del periodo di conservazione i dati verranno cancellati / distrutti.<br>
            F. DIRITTI DELL’INTERESSATO<br>
            In qualità di interessato puoi in ogni momento esercitare i tuoi diritti nei confronti del Titolare del trattamento ai sensi del D. Lgs. 193/2006 e del Regolamento (UE) 2016/679 che qui si riportano<br>
            <br>
            G. DIRITTO DI ACCESSO ALL’INTERESSATO – Art. 15 Reg. (UE) 2016/679<br>
            1. L’interessato ha il diritto di ottenere dal titolare del trattamento la conferma che sia o meno in corso un trattamento di dati personali che lo riguardano e in tal caso, di ottenere l’accesso ai dati personali e alle seguenti informazioni:<br>
            <br>
            a) le finalità del trattamento;<br>
            b) le categorie di dati personali in questione;<br>
            c) i destinatari o le categorie di destinatari a cui i dati personali sono stati o saranno comunicati, in particolare se destinatari di paesi terzi o organizzazioni internazionali;<br>
            d) quando possibile, il periodo di conservazione dei dati personali previsto oppure, se non è possibile, i criteri utilizzati per determinare tale periodo;<br>
            e) l’esistenza del diritto dell’interessato di chiedere al titolare del trattamento la rettifica o la cancellazione di dati personali o la limitazione del trattamento dei dati personali che lo riguardano o di opporsi al loro trattamento;<br>
            f) il diritto di proporre reclamo a un’autorità di controllo;<br>
            g) qualora i dati non siano raccolti presso l’interessato, tutte le informazioni disponibili sulla loro origine;<br>
            h) l’esistenza di un processo decisionale automatizzato, compresa la profilazione di cui all’art. 22 paragrafi 1 e 4 e, almeno in tali casi, informazioni significative sulla logica utilizzata, nonché l’importanza e le conseguenze previste di tale trattamento per l’interessato.<br>
            <br>
            2. Qualora i dati personali siano trasferiti a un paese terzo o a un’organizzazione internazionale, l’interessato ha il diritto di essere informato dell’esistenza di garanzie adeguate ai sensi dell’art. 46 relative al trasferimento.<br>
            3. Il titolare del trattamento fornisce una copia dei dati personali oggetto di trattamento. In caso di ulteriori copie richieste dall’interessato, il titolare del trattamento può addebitare un contributo spese ragionevole basato sui costi amministrativi. Se l’interessato presenta la richiesta mediante mezzi elettronici, e salvo indicazione diversa dall’interessato, le informazioni sono fornite in un formato elettronico di uso comune.<br>
            4. Il diritto di ottenere una copia di cui al paragrafo 3 non deve ledere i diritti e le libertà altrui.<br>
            <br>
            II. DIRITTO DI RETTIFICA – Art. 15 Reg. (UE) 2016/679<br>
            <br>
            L’interessato ha il diritto di ottenere dal titolare del trattamento la rettifica dei dati personali inesatti che lo riguardano senza giustificato ritardo. Tenuto conto delle finalità del trattamento, l’interessato ha il diritto di ottenere l’integrazione dei dati personali incompleti, anche fornendo una dichiarazione integrativa.<br>
            <br>
            III. DIRITTO ALLA CANCELLAZIONE (“DIRITTO ALL’OBLIO”) – Art. 17 Reg. (UE) 2016/679<br>
            <br>
            1. L’interessato ha il diritto di ottenere dal titolare del trattamento la cancellazione dei dati personali che lo riguardano senza ingiustificato ritardo e il titolare del trattamento ha l’obbligo di cancellare senza ingiustificato ritardo i dati personali, se sussiste uno dei motivi seguenti:<br>
            <br>
            a) i dati personali non sono più necessari rispetto alle finalità per le quali sono stati raccolti o altrimenti trattati;<br>
            b) l’interessato revoca il consenso su cui si basa il trattamento conformemente all’articolo 6, paragrafo 1, lettera a), o all’articolo 9, paragrafo 2, lettera a), e se non sussiste altro fondamento giuridico per il trattamento;<br>
            c) l’interessato si oppone al trattamento ai sensi dell’art. 21, paragrafo 1, e non sussiste alcun motivo legittimo prevalente per procedere al trattamento, oppure si oppone al trattamento ai sensi dell’articolo 21, paragrafo 2;<br>
            d) i dati personali sono trattati illecitamente;<br>
            e) i dati personali devono essere cancellati per adempiere un obbligo legale previsto dal diritto dell’Unione o dallo Stato membro cui è soggetto il titolare del trattamento;<br>
            f) i dati personali sono stati raccolti relativamente all’offerta di servizi della società dell’informazione di cui all’articolo 8, paragrafo 1.<br>
            <br>
            2. Il titolare del trattamento, se ha reso pubblici dati personali ed è obbligato, ai sensi del paragrafo 1, a cancellarli, tenendo conto della tecnologia disponibile e dei costi di attuazione, adotta le misure ragionevoli, anche tecniche, per informare i titolari del trattamento che stanno trattando i dati personali della richiesta dell’interessato di cancellare qualsiasi link, copia o riproduzione dei sui dati personali.<br>
            3. I paragrafi 1 e 2 non si applicano nella misura in cui il trattamento sia necessario:<br>
            a) per l’esercizio del diritto alla libertà di espressione e di informazione;<br>
            b) per l’adempimento di un obbligo legale che richieda il trattamento previsto dal diritto dell’Unione o dello Stato membro cui è soggetto il titolare del trattamento o per l’esecuzione di un compito svolto nel pubblico interesse oppure nell’esercizio di pubblici poteri di cui è investito il titolare del trattamento;<br>
            c) per motivi di interesse pubblico nel settore della sanità pubblica in conformità dell’articolo 9, paragrafo 2, lettere h) e i), e dell’articolo 9, paragrafo 3;<br>
            d) ai fini di archiviazione nel pubblico interesse, di ricerca scientifica o storica o a fini statistici conformemente all’articolo 89, paragrafo 1, nella misura in cui il diritto di cui al paragrafo 1 rischi di rendere impossibile o di pregiudicare gravemente il conseguimento degli obiettivi di tale trattamento; o<br>
            e) per l’accertamento, l’esercizio o la difesa di un diritto in sede giudiziaria.<br>
            <br>
            IV. DIRITTO ALLA LIMITAZIONE DI TRATTAMENTO – Art. 18 Reg. (UE) 2016/679<br>
            1. L’interessato ha il diritto di ottenere dal titolare del trattamento la limitazione del trattamento quando ricorre una delle seguenti ipotesi:<br>
            a) l’interessato contesta l’esattezza dei dati personali, per il periodo necessario al titolare del trattamento per verificare l’esattezza di tali dati personali;<br>
            b) il trattamento è illecito e l’interessato si oppone alla cancellazione dei dati personali e chiede invece che ne sia limitato l’utilizzo;<br>
            c) benché il titolare del trattamento non ne abbia più bisogno ai fini del trattamento, i dati personali sono necessari all’interessato per l’accertamento, l’esercizio o la difesa di un diritto ius sede giudiziaria;<br>
            d) l’interessato si è opposto al trattamento ai sensi dell’art. 21, paragrafo 1, in attesa della verifica in merito all’eventuale prevalenza di motivi legittimi del titolare al trattamento rispetto a quelli dell’interessato.<br>
            2. Se il trattamento è limitato a norma del paragrafo 1, tali dati personali sono trattati, salvo che per la conservazione, soltanto con il consenso dell’interessato o per l’accertamento, l’esercizio o la difesa di un diritto in sede giudiziaria oppure per tutelare i diritti di un’altra persona fisica o giuridica o per i motivi di interesse pubblico rilevante dell’Unione o di uno Stato membro.<br>
            3. L’interessato che ha ottenuto la limitazione del trattamento a norma del paragrafo 1 è informato dal titolare del trattamento prima che detta limitazione sia revocata.<br>
            <br>
            V. DIRITTO ALLA PORTABILITA’ DEI DATI – Art. 20 Reg. (UE) 2016/679<br>
            1. L’interessato ha il diritto di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati personali che lo riguardano forniti a un titolare del trattamento e ha il diritto di trasmetter tali dati a un altro titolare del trattamento senza impedimenti da parte del titolare del trattamento cui li ha forniti qualora:<br>
            a) il trattamento si basi sul consenso ai sensi dell’articolo 6, paragrafo 1, lettera a), o dall’articolo 9, paragrafo 2, lettera a), o su un contratto ai sensi dell’articolo 6, paragrafo 1, lettera b); e<br>
            b) il trattamento sia effettuato con mezzi automatizzati.<br>
            <br>
            2. Nell’esercitare i propri diritti relativamente alla portabilità dei dati a norma del paragrafo 1, l’interessato ha il diritto di ottenere la trasmissione diretta dei dati personali da un titolare del trattamento all’altro, se tecnicamente fattibile.<br>
            3. L’esercizio del diritto di cui al paragrafo 1 del presente articolo lascia impregiudicato l’articolo 17. Tale diritto non si applica al trattamento necessario per l’esecuzione di un compito di interesse pubblico o connesso all’esercizio di pubblici poteri di cui è investito il titolare del trattamento.<br>
            4. Il diritto di cui al paragrafo 1 non deve ledere i diritti e le libertà altrui.<br>
            <br>
            VI. DIRITTO DI OPPOSIZONE – Art. 21Reg. (UE) 2016/679<br>
            1. L’interessato ha il diritto di opporsi in qualsiasi momento, per i motivi connessi alla sua situazione particolare, al trattamento dei dati personali che lo riguardano ai sensi dell’articolo 6, paragrafo 1, lettere e) o f), compresa la profilazione sulla base di tali disposizioni. Il titolare del trattamento si astiene dal trattare ulteriormente i dati personali salvo che egli dimostri l’esistenza di motivi legittimi cogenti per procedere al trattamento che prevalgono sugli interessi, sui diritti e sulle libertà dell’interessato oppure per l’accertamento, l’esercizio o la difesa di un diritto in sede giudiziaria.<br>
            2. Qualora i dati personali siano trattati per finalità di marketing diretto, l’interessato ha il diritto di opporsi in qualsiasi momento al trattamento dei dati personali che lo riguardano effettuato per tali finalità, compresa la profilazione nella misura in cui sia connessa a tale marketing diretto.<br>
            3. Qualora l’interessato si opponga al trattamento per finalità di marketing diretto, i dati personali non sono più oggetto di trattamento per tali finalità.<br>
            4. Il diritto di cui ai paragrafi 1 e 2 è esplicitamente portato all’attenzione dell’interessato ed è presentato chiaramente e separatamente da qualsiasi altra informazione al più tardi al momento della prima comunicazione con l’interessato.<br>
            5. Nel contesto dell’utilizzo di servizi delle società dell’informazione e fatta salva la direttiva 2002/58/CE, l’interessato può esercitare il proprio diritto di opposizione con mezzi automatizzati che utilizzano specifiche tecniche.<br>
            6. Qualora i dati personali siano trattati a fini di ricerca scientifica o storica o a fini statistici a norma dell’articolo 89, paragrafo 1, l’interessato, per motivi connessi alla sua situazione particolare, ha il diritto di opporsi al trattamento di dati personali che lo riguarda, salvo che il trattamento è necessario per l’esecuzione di un compito di interesse pubblico.<br>
            <br>
            REGOLE GENERALI DI ESERCIZIO DEI DIRITTI<br>
            Ti informiamo che i diritti di cui ai paragrafi che precedono potranno essere esercitati in qualunque momento inviando una mail al seguente indirizzo: info@arboutiqueapartments.it<br>
            unitamente ad una copia digitale del tuo documento di identità in corso di validità. Ti ricordiamo che nel caso di richiesta di interruzione di tutti i trattamenti dei tuoi dati personali, non saremo in grado di continuare a fornirti i servizi che richiesti. In ogni caso, la nostra società potrà conservare taluni tuoi dati personali qualora potessero risultare necessari per difendere o far valere un proprio diritto.<br>
            COOKIE POLICY<br>
            AR Boutique Apartments utilizza i cookie per migliorare il proprio Sito Internet e per fornire servizi e funzionalità agli utenti. È possibile limitare o disattivare l’utilizzo dei cookie tramite il proprio browser Web. Tuttavia, in questo caso alcune funzionalità del sito potrebbero diventare inaccessibili.<br>
            <br>
            Dati di navigazione<br>
            I sistemi informatici e le procedure software preposte al funzionamento del sito web www.arboutiqueapartments.it acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita all’uso dei protocolli di comunicazione di Internet. Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti. In questa categoria di dati rientrano:<br>
            – indirizzi IP o i nomi a dominio dei computer utilizzati dagli Utenti connessi al sito;<br>
            – gli indirizzi di notazione URI (Uniform Resource Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server; la dimensione del file ottenuto in risposta; il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc..) ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’Utente.<br>
            <br>
            Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del sito e per controllarne il corretto funzionamento e vengono cancellati immediatamente dopo l’elaborazione.`
          }}
        >
        </div>
        {/* <div className="fixedBottomMenu"> */}
        <Footer {...this.props} />
        {/* </div> */}
      </Fragment>
    );
  }
}

export default PrivacyPolicy;