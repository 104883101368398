import React, { Fragment } from "react";
import "../style/style.css";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import Header from "./Header";
import Logo from "./Logo";
import Form from "react-bootstrap/Form";
import PrettyCheckbox from "pretty-checkbox";
import SlideTracker from "./SlideTracker";
import LanguageSelector from "./LanguageSelector";
import { TransitionGroup, CSSTransition, Transition } from "react-transition-group";
import axios from "axios";
import Footer from "./Footer";
import { isMobile } from "react-device-detect";

class ContactPageImplementation extends React.Component {
  state = {
    previousPage: this.props.previousPage,
    nextPage: this.props.nextPage,
    inProp: true,
    color: "#91855d",
    email: "",
    nome: "",
    testo: "",
    checkbox: 0,
  };

  goHome = (event) => {
    event.preventDefault();
    this.props.setContactLogoAnimation();
    this.props.history.push("/");
  };

  handleSendEmail = async () => {
    if (this.state.email) {
      axios({
        method: "POST",
        url: "https://backoffice.arboutiqueapartments.it/email",
        data: {
          to: "info@arboutiqueapartments.it",
          from: "info@arboutiqueapartments.it",
          replyTo: this.state.email,
          subject: this.state.nome,
          text: `test`,
          html: `
						${this.state.email}<br>
						${this.state.nome}<br>
						${this.state.testo}<br><br>
						${this.state.checkbox
              ? this.props.lan === "en"
                ? "I consent to the processing of personal data"
                : "Acconsento al trattamento dei dati personali"
              : this.props.lan === "en"
                ? "I do not consent to the processing of personal data"
                : "NON acconsento al trattamento dei dati personali"
            }
				`,
        },
      })
        .then((res) => {
          this.setState({
            nome: "",
            email: "",
            testo: "",
            checkbox: false,
          });
          alert(
            this.props.lan === "en" ? "Email sent successfully!" : "Email inviata correttamente!"
          );
        })
        .catch((err) => {
          console.log("error sending mail: ", err);
          alert(
            this.props.lan === "en"
              ? "An error occured sending the email!"
              : "Errore durante l'invio della email!"
          );
        });
    } else {
      alert(
        this.props.lan === "en"
          ? "Please insert a valid email!"
          : "Inserisci un indirizzo email valido per lasciarci ricontattarti!"
      );
    }
  };

  render() {
    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition
            classNames={this.props.contactPageAnimation}
            key={this.props.sectionName}
            timeout={{ enter: 100000000000, exit: 500 }}
            in={this.state.inProp}
            unmountOnExit={true}
          >
            {this.props.contact ? (
              <Fragment>
                <div className="contactMainTitle">
                  {this.props.texts
                    ? this.props.texts["Titolo".concat(this.props.lan === "en" ? "_en" : "")]
                    : "Contattaci"}
                </div>
              </Fragment>
            ) : (
              <div className={"hiddenElement"}></div>
            )}
          </CSSTransition>
        </TransitionGroup>
        <TransitionGroup>
          <CSSTransition
            classNames="fadeDelay"
            key={this.props.sectionName}
            timeout={{ enter: 1500, exit: 1000 }}
            in={this.state.inProp}
            unmountOnExit={true}
          >
            {this.props.contact ? (
              <div>
                {/* <span className="logoUpLeftInvertedColor" onClick={this.goHome}/> */}
                <div
                  className="contactSubTitle"
                  dangerouslySetInnerHTML={{
                    __html: this.props.texts
                      ? this.props.texts["descrizione".concat(this.props.lan === "en" ? "_en" : "")]
                      : "Per informazioni riguardo la disponibilitò degli appartamenti, l'iscrizione alla nostra newsletter e aggiornamenti sui nostri eventi.",
                  }}
                />
                {/* {this.props.texts
									? this.props.texts.descrizione
									: "Per informazioni riguardo la disponibilitò degli appartamenti, l'iscrizione alla nostra newsletter e aggiornamenti sui nostri eventi."}
								</div> */}
                {!isMobile ? (
                  <>
                    <div className="contactName">{this.props.lan === "en" ? "Name" : "Nome"}</div>
                    <div className="contactEmail">Email</div>
                    <div className="contactPrivacy">Privacy</div>
                    <div className="contactCheckBox">
                      <div className="pretty p-default p-squared p-fill">
                        <input
                          // onChange={(event) => this.setState({checkbox: event.target.value})}
                          checked={this.state.checkbox}
                          onChange={() => this.setState({ checkbox: !this.state.checkbox })}
                          type="checkbox"
                        />
                        <div className="state">
                          <label style={{ color: "#3f3f3f" }}>
                            {this.props.lan === "en"
                              ? "I consent to the processing of personal data"
                              : "Acconsento al trattamento dei miei dati personali"}
                          </label>
                        </div>
                      </div>
                    </div>
                    <input
                      value={this.state.nome}
                      onChange={(event) => this.setState({ nome: event.target.value })}
                      className="contactNameInput"
                      type="text"
                    />
                    <input
                      value={this.state.email}
                      onChange={(event) => this.setState({ email: event.target.value })}
                      className="contactEmailInput"
                      type="text"
                    />
                    <textarea
                      className="contactTextArea"
                      value={this.state.testo}
                      onChange={(event) => this.setState({ testo: event.target.value })}
                    />
                    <div className="contactMessage">
                      {this.props.lan === "en" ? "Message" : "Messaggio"}
                    </div>
                    <button onClick={this.handleSendEmail} className="contactSendButton">
                      {this.props.lan === "en" ? "Send" : "Invia"}
                    </button>
                  </>
                ) : (
                  <div style={{ position: "fixed", top: "55%", paddingLeft: "10.5%" }}>
                    <div className="contactInputLabel">
                      {this.props.lan === "en" ? "Name" : "Nome"}
                    </div>
                    <input
                      value={this.state.nome}
                      onChange={(event) => this.setState({ nome: event.target.value })}
                      className="contactInput"
                      type="text"
                    />
                    <div className="contactInputLabel">Email</div>
                    <input
                      value={this.state.email}
                      onChange={(event) => this.setState({ email: event.target.value })}
                      className="contactInput"
                      type="text"
                    />
                    <div className="contactInputLabel">Privacy</div>
                    <div
                      // onClick={() => this.setState({checkbox: !this.state.checkbox})}
                      style={{
                        marginTop: 16,
                        marginBottom: 16,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <input
                        // style={{zIndex: 1000, borderColor: '#91855d'}}
                        style={{ zIndex: 1000, borderColor: "red" }}
                        checked={this.state.checkbox}
                        // onChange={() => this.setState({checkbox: !this.state.checkbox})}
                        type="checkbox"
                      />
                      <div style={{ marginLeft: 8 }}>
                        Acconsento al trattamento dei miei dati personali
                      </div>
                    </div>
                    <div className="contactInputLabel">
                      {this.props.lan === "en" ? "Message" : "Messaggio"}
                    </div>
                    <textarea
                      style={{ marginTop: 16, marginBottom: 16 }}
                      className="contactInputArea"
                      value={this.state.testo}
                      onChange={(event) => this.setState({ testo: event.target.value })}
                    />
                    <div style={{ marginBottom: 80 }}>
                      <button onClick={this.handleSendEmail} className="contactSendButtonMobile">
                        {this.props.lan === "en" ? "Send" : "Invia"}
                      </button>
                    </div>
                  </div>
                )}
                {/* <SlideTracker slideTracker={this.props.sectionName}/> */}
                {!isMobile && (
                  <div className="fixedBottomMenu">
                    <Footer {...this.props} />
                  </div>
                )}
              </div>
            ) : (
              <div className={"hiddenElement"}></div>
            )}
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}

export default ContactPageImplementation;
