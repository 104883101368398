import React, {Fragment} from 'react';
import Header from "./Header";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import "../style/style.css"
import RightArrow from "./RightArrow";
import { TransitionGroup, CSSTransition} from "react-transition-group";
import Logo from "./Logo";
import DownArrow from "./DownArrow";
import BackArrow from "./BackArrow";

import Map from './Map';
import LanguageSelector from "./LanguageSelector";
import axios from 'axios';
import Loader from 'react-loader-spinner';
import MainHeader from './MainHeader'
import { isMobile } from 'react-device-detect';


class DoveSiamo extends React.Component {
    static defaultProps = {
        center: {
            lat: 45.690910,
            lng: 9.672650
        },
        zoom: 11
    };
    state = {
        previousPage: this.props.previousPage,
        nextPage: this.props.nextPage,
        mounted: false,
        backArrow :this.props.BackArrow,
        downArrow: this.props.downArrow,
        loading: true
    };

    nextIndex = () => {
        const { nextPage } = this.props;
        this.props.history.push("/"+nextPage)
    };

    prevIndex = () => {
        const { previousPage } = this.props;
        this.props.history.push("/"+previousPage)
    };

    goDown = () => {

        this.props.history.push("/Appartamenti1");
    };

    async componentDidMount() {
        this.setState({mounted: true});
        try {
            const result = await axios({
                url: 'https://backoffice.arboutiqueapartments.it/graphql',
                method: 'post',
                data: {
                    query: `
                        query {
                            sottopaginas {
                                id
                                Titolo
                                descrizione
                                identificativo
                                Titolo_en
                                descrizione_en
                            }
                        }
                    `
                }
            })
            this.setState({
                loading: false,
                sottopagina: result.data.data.sottopaginas.find(sottopagina => sottopagina.identificativo === "doveSiamo")
            })
        } catch (err) {
            console.log('error: ', err)
        }
    }

    render(){
        return (

            <Fragment>
                <TransitionGroup>
                    <CSSTransition
                        classNames="fade"
                        key={this.props.location.key}
                        timeout={{ enter: 1000, exit: 1000 }}
                        in = {this.state.inProp}
                        unmountOnExit={true}
                        appear
                    >
                        <div>
                            <MainHeader
                                {...this.props}
                                withBack
                                backTarget={this.props.backArrow}
                                inverted={isMobile}
                                textColor='#91855d'
                                menuColor='#91855d' />
                            <DownArrow
                                {...this.props}
                                downArrow={this.props.downArrow}
                                downArrowWrite={this.props.downArrowWrite}
                                classType="menuDownButtonDecentered"
                                goDown={this.goDown} />
                            {!isMobile && <BackArrow {...this.props} backArrow={this.props.backArrow} classButtonType="menuBackButton" classWriteType="menuBackButtonWrite"/>}
                            {!isMobile && <div className={this.props.sectionName.replace(/\s+/g, '')}></div>}

                            {this.state.loading
                                ? <div className='loader'>
                                    <Loader
                                        type="Rings"
                                        color="#91855D"
                                        height={100}
                                        width={100}
                                    />
                                </div>
                                : isMobile
                                    ?   <div style={{position: 'fixed', top: '18%', maxWidth: '80%'}}>
                                            <div
                                                // className="particularSectionPresentationRightText"
                                                style={{color: '#91855D', fontSize: 32, marginLeft: '10%'}}
                                            >
                                                {this.state.sottopagina["Titolo".concat(this.props.lan === 'en' ? "_en" : "")]}
                                            </div>
                                            <div
                                                // className="particularSectionPresentationRightText1"
                                                style={{color: '#91855D', fontSize: 14, lineHeight: '24px', marginTop: 16, marginLeft: '10%'}}
                                                dangerouslySetInnerHTML={{ __html: this.state.sottopagina["descrizione".concat(this.props.lan === 'en' ? "_en" : "")] }}
                                            />
                                            <div
                                                // className="map"
                                                style={{marginTop: 28}}
                                            >
                                                <Map
                                                    places={[]}
                                                    defaultCenter={this.props.center}
                                                    height={"25vh"}
                                                    width={"100vw"}
                                                />
                                            </div>
                                        </div>
                                    :   <Fragment>
                                            <div className="particularSectionPresentationRightText" style={{top: '10%'}}>
                                                {this.state.sottopagina["Titolo".concat(this.props.lan === 'en' ? "_en" : "")]}
                                            </div>
                                            <div
                                                className="particularSectionPresentationRightText1"
                                                style={{top: '18%', width: 900, textAlign: 'justify', color: '#3f3f3f'}}
                                                dangerouslySetInnerHTML={{ __html: this.state.sottopagina["descrizione".concat(this.props.lan === 'en' ? "_en" : "")] }}
                                            />
                                            <div className="map" style={{left: '66%', marginLeft: '-24.5%'}}>
                                                <Map
                                                    places={[]}
                                                    defaultCenter={this.props.center}
                                                    height={"39vh"}
                                                    width={"50vw"}
                                                />
                                            </div>
                                        </Fragment>}
                        </div>
                    </CSSTransition>
                </TransitionGroup>
                <ReactScrollWheelHandler
                    timeout={0}
                    upHandler={this.prevIndex}
                    downHandler={this.nextIndex}
                    customStyle={{
                        width: "100%",
                        height: "100vh",
                    }}
                />
            </Fragment>
        );
    }
}

export default DoveSiamo;