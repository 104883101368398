import React from 'react';
import { hideAll } from 'react-reveal/globals';
import "../style/style.css";

class Expandable extends React.Component{
  componentDidUpdate (oldProps) {
    if (oldProps.in !== this.props.in) {
      this._growDiv()
    }
  }  
  
  _growDiv = () => {
		var growDiv = document.getElementById(`grow_${this.props.id}`);
		if (growDiv.clientHeight) {
			growDiv.style.height = 0;
		} else {
			var wrapper = document.querySelector(`.measuringWrapper_${this.props.id}`);
			growDiv.style.height = wrapper.clientHeight + "px";
		}
	}

  render () {
    return (
      <div
        key={`expandable_grow_${this.props.id}`}
        id={`grow_${this.props.id}`}
        style={{width: '100%',
          marginTop: this.props.marginTop || 0,
          marginBottom: this.props.marginBottom || 0,
          transition: 'height .5s',
          height: 0,
          overflow: "hidden"}}
      >
        <div className={`measuringWrapper_${this.props.id}`}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default Expandable;