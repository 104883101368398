import React from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'

import '../style/test.css'

class Test extends React.Component {
    state = {
        inProp: true,
    }

    render() {
        const { inProp } = this.state
        return (
            <div className="App">
                <button
                    type="button"
                    onClick={() => {
                        this.setState(state => ({
                            inProp: !state.inProp,
                        }))
                    }}
                >
                    Toggle in prop
                </button>
                <CSSTransition
                    in={inProp}
                    timeout={3000000}
                    classNames="test"
                    unmountOnExit={true}
                >

                            <div className="enable-location__footer">
                                <h2>React Transition Group</h2>
                                State:
                            </div>

                </CSSTransition>
            </div>
        )
    }
}

export default Test;/*
<a id="home" className="menu-item" onClick={() => this.goToPage("")}>Home</a>
<a id="about" className="menu-item" onClick={() => this.goToPage("Concept")}>Concept</a>
<a id="contact" className="menu-item" onClick={() => this.goToPage("Servizi")}>Servizi</a>
<a id="contact" className="menu-item" onClick={() => this.goToPage("Appartamenti")}>Appartamenti</a>
<a id="contact" className="menu-item" onClick={() => this.goToPage("VicinoANoi")}>Vicino a Noi</a>
<a id="contact" className="menu-item" onClick={() => this.goToPage("Eventi")}>Eventi</a>
<a id="contact" className="menu-item" onClick={() => this.goToPage("Contact")}>Contatti</a>*/