import React, {Fragment} from 'react';
import "../style/style.css"
import { isMobile } from 'react-device-detect';

class DownArrow extends React.Component{
    state = {
        downArrow: this.props.downArrow,
        classType: this.props.classType
    };

    goDown = (event) => {
        this.props.goDown(this.props.downArrow);
    };
    render(){
        return(
            <Fragment>
                <div
                    onMouseEnter={() => this.setState({additionalClass: 'moveUp'})}
                    onMouseLeave={() => this.setState({additionalClass: ''})}
                    className={this.props.classType}
                    onClick={() => this.goDown()}></div>
                {!isMobile && <div
                    className={"menuDownButtonWrite" + ' ' + this.state.additionalClass}
                    onClick={() => this.goDown()}>
                        {this.props.lan === "ita"
                            ? this.props.downArrowWrite
                            : this.props.downArrowWrite === "Servizi"
                                ? "Services"
                                : this.props.downArrowWrite === "Dove Siamo"
                                    ? "Where we are"
                                    : this.props.downArrowWrite === "Appartamenti"
                                        ? "Apartments"
                                        : this.props.downArrowWrite}
                    </div>}
            </Fragment>
        )
    }
}

export default DownArrow;