import React, {Fragment} from 'react';
import "../style/style.css";
import { TransitionGroup, CSSTransition} from "react-transition-group";
import Logo from './Logo'
import RightArrow from "./RightArrow";


class BottomArrows extends React.Component{
    state = {
        inProp: true,
        animationSection:this.props.animationSection,
        bouncing: ''
    };
    goHome = (event) => {
        event.preventDefault();
        this.props.history.push("/");
    };
    goRight = (event) => {
        event.preventDefault();
        this.props.handleFadeout();
        setTimeout(() => {
            this.props.history.push("/"+this.props.rightArrow);
        }, 1600)
    };
    goDown = () => {
        this.props.goDown()
        setTimeout(() => {
            this.props.history.push("/"+this.props.downArrow);
        }, 50);
    };
    handleBouncing = (bouncing) => {
        this.setState({
            bouncing
        })
    }
    render(){
        return(
            <Fragment>
                {this.props.home && (
                    <TransitionGroup>
                        <CSSTransition
                            classNames={this.props.animationDownArrow}
                            key={this.props.sectionName}
                            timeout={{ enter: this.props.fadeTime, exit: 500 }}
                            in = {this.state.inProp}
                            unmountOnExit={true}
                            appear
                        >
                            <Fragment>
                                <div
                                    onClick={() => this.goDown()}
                                    onMouseEnter={() => this.handleBouncing('box bounce')}
                                    class={`menuDownButtonCentered ${this.state.bouncing}`} />
                            </Fragment>
                        </CSSTransition>
                    </TransitionGroup>
                )}
            </Fragment>
        )
    }
}

export default BottomArrows;