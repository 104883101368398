import React, { Fragment } from 'react';
import "../style/style.css"
import Logo from "./Logo";
import { TransitionGroup, CSSTransition, Transition } from "react-transition-group";
import menuLateraleBianco from '../img/menu_laterale_bianco_small.png';
import menuLateraleGiallo from '../img/menu_laterale_giallo_small.png';
import styled from 'styled-components';

const slideTracker = [
    {
        name: 'Concept',
        target: '/Concept'
    },
    {
        name: 'Servizi',
        target: '/Servizi'
    },
    {
        name: 'Appartamenti',
        target: '/Appartamenti'
    },
    {
        name: 'Eventi',
        target: '/Eventi'
    },
    {
        name: 'Vicino a Noi',
        target: '/VicinoANoi'
    },
    {
        name: 'Contattaci',
        target: '/Contact'
    }]

class SlideTracker extends React.Component{
    state = {
        showSlide: false,
        hover: false
    };

    componentDidMount () {
        if (this.props.contact) {
            this.setState({showSlide: true})
        } else {
            this.setState({showSlide: false})
        }
    }
    componentDidUpdate (oldProps) {
        if (oldProps.contact !== this.props.contact) {
            if (this.props.contact) {
                this.setState({showSlide: true})
            } else {
                this.setState({showSlide: false})
            }
        }
    }

    goTo = (target) => {
        this.props.history.push(target)
    }

    toggleHover = (hover) => {
        this.setState({hover})
    }

    render(){
        return(
            <Transition in={this.state.showSlide} timeout={100}>
                {state => <div className={`slideTrackerConcept fade-${state}`}>
                        {slideTracker.map((section, index) => <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <div style={{flex: 10}}>
                                <Label
                                    opacity={this.state.hover === section.name ? 1 : 0}>
                                    {section.name}
                                </Label>
                            </div>
                            <div style={{flex: 1, paddingLeft: 16}}>
                                <Triangolo
                                    key={section.name + index}
                                    onMouseEnter={() => this.toggleHover(section.name)}
                                    onMouseLeave={() => this.toggleHover(false)}
                                    onClick={() => this.goTo(section.target)}
                                    src={index <= this.props.position ? menuLateraleGiallo : menuLateraleBianco} />
                            </div>
                        </div>)}
                    </div>}
            </Transition>
            // <TransitionGroup>
            //     <CSSTransition
            //         classNames="fade"
            //         key={this.props.slideTracker}
            //         timeout={{ enter: 500, exit: 500 }}
            //         in = {this.state.inProp}
            //         unmountOnExit={true}
            //     >
                    
            //     </CSSTransition>
            // </TransitionGroup>

        )
    }
}

export default SlideTracker;

const Triangolo = styled.img`
    width: 15px;
    height: 30px;
    margin-top: -5px;
    cursor: pointer;
`

const Label = styled.div`
    color: white;
    font-size: 16px;
    text-align: right;
    margin-top: -5px;
    opacity: ${props => props.opacity};
`
