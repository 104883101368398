import React, { Fragment } from "react";
import "../style/style.css";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { translate } from "../utils/translations";

class SectionName extends React.Component {
  state = {
    inProp: true,
    animationSection: this.props.logoHomeAnimation,
  };
  goHome = (event) => {
    this.props.goHome();
  };

  setAnimationHome = () => {
    this.props.setAnimationHome();
  };
  setAnimationNotHome = () => {
    this.props.setAnimationNotHome();
  };
  goRight = (event) => {
    event.preventDefault();
    this.props.handleFadeout();
    setTimeout(() => {
      this.props.history.push("/" + this.props.rightArrow);
    }, 1600);
  };

  render() {
    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition
            classNames={this.props.animationSection}
            key={this.props.sectionName}
            timeout={{ enter: 1400, exit: 800 }}
            in={this.state.inProp}
            unmountOnExit={true}
            appear
          >
            {this.props.home ? (
              <Fragment>
                {() => this.setAnimationHome()}
                <div
                  className={`logoCentered${this.props.lan === "en" ? "_en" : ""}`}
                  onClick={() => this.goHome()}
                />
                {/* <div
									style={{
                    position: "absolute",
                    top: "6vh",
                    right: "6vh",
                    zIndex: 1000,
                    fontSize: 16,
                    color: "#91855d",
                    fontWeight: "bold",
                  }}
                >
                  +39 035 5785067
                </div> */}
                {/* <div style={{fontSize: 48, lineHeight: '86px', position: 'absolute', left: '50%', marginLeft: '-500px', width: 1000, top: '60%', textAlign: 'center'}}>Affittiamo l'esclusività</div> */}
              </Fragment>
            ) : (
              <Fragment>
                {!this.props.contact && (
                  <Fragment>
                    {() => this.setAnimationNotHome()}
                    <div className="sectionTitleBox">
                      <span
                        className={`sectionTitle ${
                          this.props.sectionName === "Appartamenti" && "apartments"
                        }`}
                      >
                        {/* {this.props.sectionName} */}
                        {translate(
                          this.props.sections,
                          this.props.sectionId,
                          this.props.lan === "en" ? "Testo_en" : "testo"
                        )}
                      </span>
                      <div className="menuRightButton" onClick={this.goRight}></div>
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}

export default SectionName;
