import React, { Fragment, useEffect, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import "react-tiger-transition/styles/main.min.css";
import Link from "react-router-dom/Link";
import { BrowserRouter as Router } from "react-router-dom";
import { AnimatedRoute } from "react-router-transition";
import Home from "./Home";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { translate } from "../utils/translations";
import axios from "axios";

const menuItems = [
  {
    name: "Home",
    target: "",
    targetMobile: "Home",
    id: "Home",
  },
  {
    name: "Concept",
    target: "Concept1",
    targetMobile: "Concept",
    id: "concept",
  },
  {
    name: "Servizi",
    target: "Servizi1",
    targetMobile: "Servizi",
    id: "servizi",
  },
  {
    name: "Appartamenti",
    target: "Appartamenti1",
    targetMobile: "Appartamenti",
    id: "appartamenti",
  },
  {
    name: "Eventi",
    target: "Eventi1",
    targetMobile: "Eventi",
    id: "eventi",
  },
  {
    name: "Vicino a Noi",
    target: "VicinoANoi1",
    targetMobile: "Vicino a noi",
    id: "vicino",
  },
  {
    name: "Contatti",
    target: "Contact",
    targetMobile: "Contatti",
    id: "contattaci",
  },
];

const Header = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [pagine, setPagine] = useState([]);
  const height = window.innerHeight;
  const isSmallerMobile = height < 720;

  var styles = {
    bmBurgerButton: {
      position: "relative", //or fixed
      width: isMobile ? "52px" : "35px",
      height: isMobile ? "42px" : "35px",
      left: "96.1%",
      top: "6.4%",
      color: "#ffffff",
      zoom: 0.55,
      zIndex: 12050,
    },
    bmBurgerBars: {
      background: props.colorBurger,
      color: "#ffffff",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "30px",
      width: "30px",
      left: "86.5%",
      top: "5%",
    },
    bmCross: {
      // background: '#e6ce3c',
      background: "#91855d",
      zoom: 1.2,
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100vh",
      top: 0,
      transition: isMobile ? "600ms ease-in-out" : "800ms ease-in-out",
      webkitBoxShadow: menuOpen ? "-14px 0px 35px -8px rgba(0,0,0,0.4)" : "",
      moxBoxShadow: menuOpen ? "-14px 0px 35px -8px rgba(0,0,0,0.4)" : "",
      boxShadow: menuOpen ? "-14px 0px 35px -8px rgba(0,0,0,0.4)" : "",
      // overflow: isMobile ? 'scroll' : 'hidden',
      overflow: "hidden",
      zIndex: 12051,
      touchAction: "none",
    },
    bmMenu: {
      background: "white",
      // background: '#91855d',
      padding: "1.5em 0.55em 0",
      fontSize: "32px",
      font: "Montserrat",
      // overflow: isMobile ? "scroll" : 'hidden',
      overflow: "hidden",
      lineHeight: "43px",
      height: "100vh",
      touchAction: "none",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      height: isMobile ? "unset" : "100%",
      color: "#91855d",
      padding: "0.8em",
      marginTop: isSmallerMobile ? "24px" : "36px",
    },
    bmItem: {
      display: "block",
      margin: isMobile
        ? isSmallerMobile
          ? "0% 0 12px 0"
          : "0% 0 16px 0"
        : "0% 0 22px 0", //distanza item del menu
      textdecoration: "none",
      cursor: "pointer",
      transition: "opacity 400ms ease-in-out",
      webkitTransition: "opacity 400ms ease-in-out",
      mozTransition: "opacity 400ms ease-in-out",
      color: "#91855d",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.0)",
    },
  };

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const goToPage = (page) => {
    closeMenu();
    // const current = this.props.history.location;
    props.history.push("/" + page);
  };

  const goToPageMobile = (page) => {
    // this.props.history.push(this.props.history.location.pathname + "#" + page.targetMobile);
    props.history.push("/" + page);
  };

  const handleHover = (target) => {
    setHover(target);
  };

  useEffect(() => {
    (async () => {
      try {
        const result = await axios({
          url: "https://backoffice.arboutiqueapartments.it/graphql",
          method: "POST",
          data: {
            query: `
                        query {
                            paginas {
                                id
                                identificativo
                                testo
                                Testo_en
                            }
                        }
                    `,
          },
        });
        setPagine(result.data.data.paginas);
      } catch (err) {
        console.log("err", err);
      }
    })();
  }, []);

  return (
    <>
      <Menu
        colorBurger={props.colorBurger}
        styles={styles}
        onStateChange={(state) => handleStateChange(state)}
        right
        disableAutoFocus
        width={isMobile ? "100%" : "33.4%"}
        isOpen={menuOpen}
      >
        <div
          onClick={() => props.changeLanguage("ita")}
          style={{
            position: "absolute",
            top: 36,
            left: "2.35em",
            display: "flex",
            flexDirection: "row",
            fontSize: 18,
            textDecoration: "none",
          }}
        >
          <div
            style={{
              color: props.lan === "ita" ? "#e6ce3c" : "#91855d",
              textDecoration: props.lan === "ita" ? "underline" : "none",
            }}
          >
            It
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: 36,
            left: "3.70em",
            display: "flex",
            flexDirection: "row",
            fontSize: 18,
            textDecoration: "none",
          }}
        >
          <div>|</div>
        </div>
        <div
          onClick={() => props.changeLanguage("en")}
          style={{
            position: "absolute",
            top: 36,
            left: "4.35em",
            display: "flex",
            flexDirection: "row",
            fontSize: 18,
            textDecoration: "none",
          }}
        >
          <div
            style={{
              color: props.lan === "en" ? "#e6ce3c" : "#91855d",
              textDecoration: props.lan === "en" ? "underline" : "none",
            }}
          >
            En
          </div>
          {isMobile && (
            <div
              style={{
                marginLeft: 24,
              }}
            >
              <a
                href="callto:+393880744549"
                style={{ color: "#91855d", textDecoration: "none" }}
              >
                +39 035 5785067
              </a>
            </div>
          )}
        </div>
        {menuItems.map((item, index) => (
          <a
            onMouseEnter={() => handleHover(item.name)}
            onMouseLeave={() => handleHover(false)}
            id={item.name + index}
            className="menu-item"
            style={{
              opacity: hover && hover !== item.name ? 0.6 : 1,
              textDecoration: isMobile ? "none" : "underline",
            }}
            href={
              // isMobile
              //   ? `${
              //       !!menuItems.find(
              //         (el) => el.target === this.props.history.location.pathname.substring(1)
              //       )
              //         ? this.props.history.location.pathname
              //         : "/" + item.target
              //     }#${item.targetMobile}`
              //   : ""
              "/" + item.target + "#" + item.targetMobile
            }
            // onClick={() => !isMobile ? this.goToPage(item.target) : this.goToPageMobile(item)}
            onClick={() => !isMobile && goToPage(item.target)}
          >
            {/* {item.name} */}
            {translate(
              pagine,
              item.id,
              props.lan === "en" ? "Testo_en" : "testo"
            )}
          </a>
        ))}
        <a
          href="https://www.arconceptlife.com/ar-boutique-apartments#widget-section"
          target="_blank"
          className="prenotaBtn"
          style={{
            color: "#fff",
            textDecoration: "none",
            textAlign: "center",
            alignSelf: "center",
            fontSize: 18,
            width: 220,
            borderRadius: 40,
            margin: "32px auto 0",
          }}
        >
          {props.lan === "ita" ? "Prenota Ora" : "Book Now"}
        </a>
        <a
          href="/privacy-policy"
          style={{
            position: "absolute",
            top: window.innerHeight - 60,
            left: "2.35em",
            textDecoration: "underline",
            fontSize: 18,
          }}
        >
          Privacy policy
        </a>
        {!!pagine.find((pagina) => pagina.identificativo === "instagram") &&
          !!pagine.find((pagina) => pagina.identificativo === "instagram")[
            "testo"
          ] && (
            <a
              href={
                pagine.find((pagina) => pagina.identificativo === "instagram")[
                  "testo"
                ]
              }
              target="_blank"
              style={{
                position: "absolute",
                top: window.innerHeight - 56,
                right: "36%",
                fontSize: 18,
              }}
            >
              <img
                src={require("../img/instagram.png")}
                style={{ width: 32 }}
              />
            </a>
          )}
        {!!pagine.find((pagina) => pagina.identificativo === "facebook") &&
          !!pagine.find((pagina) => pagina.identificativo === "facebook")[
            "testo"
          ] && (
            <a
              href={
                pagine.find((pagina) => pagina.identificativo === "facebook")[
                  "testo"
                ]
              }
              target="_blank"
              style={{
                position: "absolute",
                top: window.innerHeight - 56,
                right: "16%",
                fontSize: 18,
              }}
            >
              <img
                src={require("../img/facebook-3-xl.png")}
                style={{ width: 32 }}
              />
            </a>
          )}
      </Menu>
    </>
  );
};

export default Header;
