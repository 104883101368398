import React, {Fragment} from 'react';
import "../style/style.css";
import { TransitionGroup, CSSTransition, Transition } from "react-transition-group";
import { is } from 'ramda';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import Loader from 'react-loader-spinner'
import MainHeader from './MainHeader'
import Expandable from '../shared/Expandable'
import * as _ from 'underscore'
import ArrowDownMobile from '../img/arrow_down_mobile.png'

class Event extends React.Component{
	constructor (props) {
		super(props)
		this.state = {
			loading: true,
			startAnimating: false,
			showHeader: true,
			focusImage: 0
		};
		this.handleGalleryScrollDebounced = _.debounce(this.handleGalleryScroll, 50)
		this.handleZoomGalleryScrollDebounced = _.debounce(this.handleZoomGalleryScroll, 50)
		this.onScrollDebounced = _.debounce(this.onScroll, 10)
	}
	
	async componentDidMount() {
		window.addEventListener('scroll', this.onScrollDebounced)
        try {
            const result = await axios({
                url: 'https://backoffice.arboutiqueapartments.it/graphql',
                method: 'post',
                data: {
                    query: `
                        query {
                            eventos (
                                sort: "data:asc"
                            ) {
                                id
                                data
                                titolo
                                sottotitolo
                                descrizione
                                galleria {
                                    id
                                    url
                                }
                                sfondo {
                                    id
                                    url
                                }
                            }
                        }
                    `
                }
            })
            const events = result.data.data.eventos;
            this.setState({
                loading: false,
                events
            })
        } catch (err) {
            console.log('err', err)
        }
	}
	
	componentDidUpdate (oldProps, oldState) {
		if (oldState.position < this.state.position && (this.state.position > 10)) {
				this.setState({showHeader: false})
		}
		if (oldState.position > this.state.position) {
				const triggerColor = window.innerHeight * 80 / 100
				if (this.state.position >= triggerColor) {
						this.setState({showHeader: true, headerInverted: true})
				} else {
						this.setState({showHeader: true, headerInverted: false})
				}
		}
		if (oldProps.match.params.id !== this.props.match.params.id) {
			window.scrollTo(0, 0)
		}
	}
    
	goToEvent = (id, margin) => {
		// console.log('clicked 2', id, margin)
		if (id === "xxx") {
				this.props.goToCalendario()
		} else {
				this.props.goToEvent(id, margin)
		}
	};

	onMouseDown = id => event => {
			// console.log('clicked 1')
			const mouseX = event.clientX
			const screenX = window.innerWidth
			this.goToEvent(id, mouseX / screenX * 100)
	}
	
	toggleSubTitle = () => {
		this.setState({
			showSubTitle: !this.state.showSubTitle
		})
		this.growDiv()
	}

	growDiv = () => {
		var growDiv = document.getElementById('grow');
		if (growDiv.clientHeight) {
			growDiv.style.height = 0;
		} else {
			var wrapper = document.querySelector('.measuringWrapper');
			growDiv.style.height = wrapper.clientHeight + "px";
		}
	}

	onScroll = () => {
        this.setState({
            position: window.pageYOffset
        })
	}
	
	openGallery = (index) => {
        setTimeout(() => {
            this.setState({startAnimating: true})
        }, 100)
        this.setState({
            showGallery: true,
            photo: index
        })
	}
	
	handleKeyDown = (key) => {
        switch (key.keyCode) {
            case 37: {
                this.handlePrevPhoto()
                break;
            }
            case 39: {
                this.handleNextPhoto()
                break;
            }
            case 27: {
                if (this.state.showGallery) {
                    this.closeGallery()          
                }
                break;
            }
        }
    }

    closeGallery = () => {
        setTimeout(() => this.setState({
            showGallery: false,
        }), 900)
        this.setState({
            startAnimating: false
        })
    }

    handlePrevPhoto = event => {
        if (event) {
            event.stopPropagation()
        }
        if (this.state.photo === 0) {
            this.setState({
                photo: this.state.events.find(ev => ev.id == this.props.match.params.id).galleria.length - 1
            })
        } else {
            this.setState({
                photo: this.state.photo - 1
            })
        }
    }

    handleNextPhoto = event => {
        if (event) {
            event.stopPropagation()
        }
        if (this.state.photo === (this.state.events.find(ev => ev.id == this.props.match.params.id).galleria.length - 1)) {
            this.setState({
                photo: 0
            })
        } else {
            this.setState({
                photo: this.state.photo + 1
            })
        }
		}
		
		handleGalleryScroll = () => {
			const div = document.getElementById("gallery")
			// console.log(div.scrollTop)
			// console.log(div.scrollLeft)
			const width = window.innerWidth * 80 / 100
			// console.log('focus:', Math.round(div.scrollLeft / (width)))
			this.setState({
					focusImage: Math.round(div.scrollLeft / (width))
			})
		}
		
		handleZoomGalleryScroll = () => {
				const div = document.getElementById("zoom_gallery")
				// console.log(div.scrollTop)
				// console.log(div.scrollLeft)
				const width = window.innerWidth * 90 / 100
				// console.log('focus:', Math.round(div.scrollLeft / (width)))
				this.setState({
						focusImage: Math.round(div.scrollLeft / (width))
				})
		}

		goToEvt = (id, direction) => {
			if (direction === 'prev') {
					this.setState({goingPrevApt: true})
			} else {
					this.setState({goingNextApt: true})
			}
			this.setState({animationDownText: "fadeInslideFromSxApartmentSingle"})
			this.setState({animationDownArrow: "fadeInslideFromSxApartmentDownArrow"})
			this.setState({animationBackArrow: "fadeInslideFromSxApartment"})
			this.setState({animationBackArrowWrite: "fadeInslideFromSxApartmentSingle"})
			this.setState({
				focusImage: 0,
			})
			// this.props.history.push('/apartment/'+id)
			setTimeout(
					() => {
							this.props.history.push('/evento/'+id)
							if (direction === 'prev') {
									this.setState({goingPrevApt: false})
							} else {
									this.setState({goingNextApt: false})
							}
					}, 750)
			// window.location.reload()
		}

    render(){
		const { loading } = this.state
		if (loading) {
			return (
				<>
					<MainHeader
						{...this.props}
						inverted
						withBack
						backTarget="Eventi1"
						background={isMobile && this.state.headerInverted ? 'white' : 'transparent'} />
					<div
						style={{
							width: '100vw',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '100vh',
						}}
					>
						<Loader
							type="Rings"
							color="#91855D"
							height={100}
							width={100}
						/>
					</div>
				</>
			)
		} else {
			// console.log('single event props', this.props)
			// console.log('single event props', this.state)
			let event
			let index
			if (this.props.match.params.id === 'xxx') {
				event = {
					id: 'xxx',
					titolo: "Calendario Eventi"
				}
			} else {
				event = this.state.events.find(ev => ev.id == this.props.match.params.id)
				index = this.state.events.findIndex(ev => ev.id == this.props.match.params.id)
			}
			const prevEvt = index > 0 && this.state.events[index - 1]
			const nextEvt = index < this.state.events.length && this.state.events[index + 1]
			// console.log('event', event)
			return (
				<>
					<MainHeader
						{...this.props}
						animated
						show={this.state.showHeader}
						withBack
						backTarget="Eventi1"
						inverted={this.state.headerInverted}
						background={isMobile && this.state.headerInverted ? 'white' : 'transparent'} />
					<div
						className="fadeSection"
						style={event.id !== 'xxx' ? {
							backgroundImage: `url(https://backoffice.arboutiqueapartments.it${event.sfondo.url})`,
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat',
							opacity: 1,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: 'column',
							backgroundPosition: 'unset',
							width: '100%',
							height: "92vh"
						} : {}}
						className={event.id === 'xxx' ? "evento1" : "eventoMobile"}
					>
						<TransitionGroup
							style={{width: '100%'}}
						>
							<CSSTransition
								classNames='sectionTitle'
								key={event.id}
								timeout={{ enter: 1400, exit: 0 }}
								in
								unmountOnExit
								appear
							>
								<div style={{
									flex: 1,
									display: 'flex',
									height: '92vh',
									width: '100%',
									alignItems: 'center',
									justifyContent: 'center',
									flexDirection: 'column'
								}}>
									<div style={{flex: 3}}></div>
									<div style={{
										flex: 4,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-evenly',
										flexDirection: 'column',
										width: '100%'
									}}>
										<div
											style={{
												textAlign: 'center',
												fontSize: 46,
												lineHeight: '56px',
												color: 'white',
												padding: '0 24px',
												height: !this.state.expandText ? 'auto' : 0,
												overflow: 'hidden',
												// opacity: !this.state.expandText ? 1 : 0,
												transition: 'all 800ms ease'
											}}
										>
											{event.titolo}
										</div>
										{!this.state.showSubTitle && !!event.data && <div
											style={{
												textAlign: 'center',
												fontSize: 30,
												lineHeight: '32px',
												color: 'white',
												marginTop: 24
											}}
										>
											{new Date(event.data).toLocaleDateString()}
										</div>}
										{/* {!this.state.showSubTitle && event.sottotitolo && <div onClick={this.toggleSubTitle} style={{color: 'white', fontSize: 56, lineHeight: '32px'}}>
											+
										</div>} */}
										{/* {!!this.state.showSubTitle && event.sottotitolo && ( */}
											{/* <div id="grow" style={{backgroundColor: 'white', color: '#333', fontSize: 18, width: '100%', marginTop: 24}}>
												<div className="measuringWrapper" style={{padding: 56}}>
													{event.sottotitolo}
												</div>
											</div> */}
											<Expandable id={`event_text_${event.id}`} in={this.state.expandText} marginTop={24}>
												<div style={{backgroundColor: 'white', color: '#333', fontSize: 18, width: '100%'}}>
													<div style={{padding: 56}}>
														{event.sottotitolo}
													</div>
												</div>
											</Expandable>
										{/* )} */}
										{!!event.sottotitolo && <div
											key={`expand_button_${event.id}`}
											onClick={() => this.setState({expandText: !this.state.expandText})}
											style={{
													color: 'white',
													height: 40,
													width: 40,
													padding: 8,
													transform: this.state.expandText ? 'rotate(45deg)' : 'rotate(90deg)',
													transition: 'transform 500ms ease-in-out',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center'
											}}>
												<img src={require("../img/plus.png")} style={{width: 40}} />
										</div>}
									</div>
									<div style={{flex: 3, display: 'flex', alignItems: 'flex-end'}}>
										{this.state.showSubTitle
											?	<div onClick={this.toggleSubTitle} style={{color: 'white', fontSize: 56, lineHeight: '32px', marginBottom: 100}}>
													<img src={require('../img/crossIcon.png')} />
												</div>
											// :	<a href="#event-body">
											:	<img
														// onClick={this.props.goToNextEvent}
													src={ArrowDownMobile}
													style={{marginBottom: 60, zIndex: 1000}} />
												// </a>
											}
									</div>
								</div>
							</CSSTransition>
						</TransitionGroup>
					</div>
					{this.state.showGallery && (
                        <Transition in={this.state.startAnimating} timeout={100}>
                            {state => <div
                                className={`fade-gallery-${state}`}
                                onClick={() => this.closeGallery()}
                                style={{width: '100vw', height: '100vh', position: 'fixed', top: 0, zIndex: 2000, background: 'rgba(0,0,0,0.8)'}}>
                                <div style={{width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <div
                                        onKeyDown={(key) => console.log('key', key)}
                                        onClick={this.handlePrevPhoto}
                                        style={{
											flex: 1,
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											fontSize: 28,
											color: 'white',
											transform: 'scaleY(2)',
											cursor: 'pointer',
											zIndex: 2100
									}}>{'<'}</div>
                                    <div style={{flex: 8, height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <img
											src={'https://backoffice.arboutiqueapartments.it'+event.galleria[this.state.photo].url}
											style={{
												// height: '90%',
												maxWidth: '100%'
											}}
										/>
                                    </div>
                                    <div
                                        onClick={this.handleNextPhoto}
                                        style={{
											flex: 1,
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											fontSize: 28,
											color: 'white',
											transform: 'scaleY(2)',
											cursor: 'pointer',
											zIndex: 2100
										}}>{'>'}</div>
                                </div>
                            </div>
                            }
                        </Transition>
                    )}
					<div id="event-body">
						{event.id === 'xxx'
							?	<div style={{padding: 36, display: 'flex', flexDirection: 'column'}}>
									{this.state.events.map(ev => {
										return (
											<div style={{marginTop: 40}}>
												<div style={{display: 'flex'}}>
													<div style={{width: '10%', height: 200, backgroundColor: 'rgb(255, 229, 51)'}} />
													<img src={`https://backoffice.arboutiqueapartments.it${ev.sfondo.url}`} style={{width: '90%', height: 200, objectFit: 'cover'}} />
												</div>
												<div style={{marginTop: 16, color: '#91855d', fontSize: 18, lineHeight: '24px'}}>
													{new Date(ev.data).toLocaleDateString() + ' - ' + ev.titolo}
												</div>
												<div
													style={{marginTop: 12, color: '#333', fontSize: 16, lineHeight: '24px'}}
													dangerouslySetInnerHTML={{ __html: ev.descrizione }} />
											</div>
										)
									})}
								</div>
							: 	<Fragment>
								<div
									style={{padding: 56, lineHeight: '28px', fontSize: 18, color: '#333'}}
									dangerouslySetInnerHTML={{ __html: event.descrizione}} />
							</Fragment>}
					</div>
									{event.id !== 'xxx' && <div
										id="gallery"
										onScroll={this.handleGalleryScrollDebounced}
										style={{display: 'flex', flexDirection: 'row', overflowX: 'scroll', marginBottom: 56, scrollSnapType: 'x mandatory'}}>
										{!!event.galleria && event.galleria.length > 0
											?	event.galleria.map((image, i) => <img
													onClick={() => this.setState({zoomImage: i+1, focusImage: 0})}
													style={{
														width: '80%',
														height: this.state.focusImage === i ? 300 : 260,
														marginTop: this.state.focusImage === i ? 0 : 20,
														marginBottom: this.state.focusImage === i ? 0 : 20,
														objectFit: 'cover',
														transition: 'height 500ms ease, margin 500ms ease',
														scrollSnapAlign: 'center'
													}}
													src={'https://backoffice.arboutiqueapartments.it' + image.url} />)
											: gallery.map((image, i) => <img
													onClick={() => this.setState({zoomImage: i+1, focusImage: 0})}
													style={{
														width: '80%',
														height: this.state.focusImage === i ? 300 : 260,
														marginTop: this.state.focusImage === i ? 0 : 20,
														marginBottom: this.state.focusImage === i ? 0 : 20,
														objectFit: 'cover',
														transition: 'height 200ms ease, margin 200ms ease',
														scrollSnapAlign: 'center'
													}}
													src={image} />)}
									</div>}

									{!!this.state.zoomImage && (
                    <TransitionGroup>
											<CSSTransition
													classNames="fadeShort"
													// key={this.props.location.key}
													timeout={{ enter: 200, exit: 1000 }}
													in={this.state.inProp}
													unmountOnExit={true}
													appear
											>
													<div
															// onClick={() => this.handleZoom()}
															style={{ position: 'fixed', top: 0, touchAction: 'none', zIndex: 2000, height: isMobile ? '100vh' : '100vh', width: '100vw', backgroundColor: 'rgba(0,0,0,0.9)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
															<div
																	id="zoom_gallery"
																	onScroll={this.handleZoomGalleryScrollDebounced}
																	style={{display: 'flex', flexDirection: 'row', overflowX: 'scroll', scrollSnapType: 'x mandatory'}}>
																	{!!event.galleria && event.galleria.length > 0
																		?	event.galleria.map((image, i) => <img
																				style={{
																					width: '90%',
																					marginRight: '2%',
																					height: this.state.focusImage === i ? 300 : 260,
																					marginTop: this.state.focusImage === i ? 0 : 20,
																					marginBottom: this.state.focusImage === i ? 0 : 20,
																					objectFit: 'cover',
																					transition: 'height 500ms ease, margin 500ms ease',
																					scrollSnapAlign: 'center'
																				}}
																				src={'https://backoffice.arboutiqueapartments.it' + image.url} />)
																		: gallery.map((image, i) => <img
																				style={{
																					width: '90%',
																					marginRight: '2%',
																					height: this.state.focusImage === i ? 300 : 260,
																					marginTop: this.state.focusImage === i ? 0 : 20,
																					marginBottom: this.state.focusImage === i ? 0 : 20,
																					objectFit: 'cover',
																					transition: 'height 500ms ease, margin 500ms ease',
																					scrollSnapAlign: 'center'
																				}}
																				src={image} />)}
															</div>
															<div className="crossOverlay" onClick={() => this.setState({zoomImage: false})} style={{right: isMobile ? '10vw' : '3vw'}} />
													</div>
									 		</CSSTransition>
									 </TransitionGroup>
                )}
								<div style={{margin: '80px 0', display: 'flex'}}>
									{prevEvt
											? <Transition in={this.state.goingPrevApt} timeout={50}>
															{state => <div
																	className={`previousRoomButtonMobile-${state}`}
																	onClick={() => this.goToEvt(prevEvt.id, 'prev')}>
																			{prevEvt.titolo.substring(0, 8) + '...'}
															</div>}
													</Transition>
											: <div style={{width: '50vw'}} />}
								{nextEvt && <Transition in={this.state.goingNextApt} timeout={50}>
										{state => <div
												className={`nextRoomButtonMobile-${state}`}
												onClick={() => this.goToEvt(nextEvt.id, 'next')}>
														{nextEvt.titolo.substring(0, 8) + '...'}
										</div>}
								</Transition>}
							</div>
				</>
			)
		}
	}
}

const gallery = [
	'/static/media/jessica-ruscello-AbuQX0g4cdU-unsplash.7b893a76.jpg',
	'/static/media/scott-warman-rrYF1RfotSM-unsplash.39fa8c8f.jpg',
	'/static/media/justin-aikin-x-6HB8_qE4A-unsplash.df021343.jpg',
	'/static/media/elevate-Euyr_8yImIQ-unsplash.dfd8effb.jpg',
	'/static/media/lana-abie-De3-PUfrO5Q-unsplash.84b2b7e9.jpg',
	'/static/media/louis-hansel-yAo8dahtHgg-unsplash.64d12a97.jpg',
	'/static/media/elevate-oRl7BoX7QCE-unsplash.d0dfe8a4.jpg',
	'/static/media/fineas-anton-kC1-9Snr9hI-unsplash.38fcc335.jpg',
	'/static/media/filipe-de-rodrigues-i7Vy81pnZCI-unsplash.4b4829d9.jpg'
]

export default Event;