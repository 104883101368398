import React, {Fragment} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import SectionPresentation from "./SectionPresentation";
import Home from "./Home"
import ContactPage from "./ContactPage";
import ParticularSectionPresentation from "./ParticularSectionPresentation";
import DoveSiamo from "./DoveSiamo";
import CioCheServe from "./CioCheServe";
import SectionName from "./SectionName";
import Test from "./Test";
import "../style/style.css"

import { TransitionGroup, CSSTransition} from "react-transition-group";
import EventPicker from "./EventPicker";
import ParticularSectionPresentationTwoText from "./ParticularSectionPresentationTwoText";
import Apartments from "./Apartments";
import SingleApartment from "./SingleApartment";
import CalendarioEventi from "./CalendarioEventi";
import Evento from "./Evento";
import SingleEventMobile from "./SingleEventMobile";
import PrivacyPolicy from './PrivacyPolicy';
import LanguageSelector from "./LanguageSelector";
import "react-tiger-transition/styles/main.min.css";


class Router extends React.Component {
    state = {
        inProp: false,
        locationSwitch:false,
        lan: 'ita'
    };

    // com
    changeLanguage = (lan) => {
        this.setState({
            lan
        })
    }

    render(){
        return(
            <BrowserRouter>
                {()=>this.setState({locationSwitch: window.location})}
                <Switch>
                    {/* <Route exact path="/" render={(props) => <Home {...props} nextPage = "Concept" previousPage = "" sectionName = "Home"/>} /> */}
                    <Route exact path="/" render={(props) => <SectionPresentation home {...props} nextPage = "Concept" previousPage = "" sectionName = "Home" lan={this.state.lan} changeLanguage={this.changeLanguage} />} />

                    <Route exact path="/Concept"  render={(props) => <SectionPresentation position={0} section {...props} nextPage = "Servizi" previousPage = "" sectionId="concept" sectionName="Concept" rightArrow = "Concept1" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />
                    <Route exact path="/Servizi" render={(props) => <SectionPresentation position={1} section {...props} previousPage = "Concept" nextPage = "Appartamenti" sectionId="servizi" sectionName="Servizi" rightArrow = "Servizi1" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />
                    <Route exact path="/Appartamenti" render={(props) => <SectionPresentation position={2} section {...props} previousPage = "Servizi" nextPage = "Eventi" sectionId="appartamenti" sectionName="Appartamenti" rightArrow = "Appartamenti1" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />
                    <Route exact path="/Eventi" render={(props) => <SectionPresentation position={3} section {...props} previousPage = "Appartamenti" nextPage = "VicinoANoi" sectionId="eventi" sectionName="Eventi" rightArrow = "Eventi1" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />
                    <Route exact path="/VicinoANoi" render={(props) => <SectionPresentation position={4} section {...props} previousPage = "Eventi" nextPage = "Contact" sectionId="vicino" sectionName="Vicino A Noi" rightArrow = "VicinoANoi1" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />
                    <Route exact path="/Contact" render={(props) => <SectionPresentation position={5} contact {...props} previousPage = "VicinoANoi" nextPage = "Contact" sectionId="contattaci" sectionName="Contact" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />

                    <Route exact path="/privacy-policy" render={(props) => <PrivacyPolicy {...props} />} />
                    
                </Switch>
                <TransitionGroup>
                    <CSSTransition
                        classnames={"fade"}
                        key={window.location.key}
                        timeout={{ enter: 300, exit: 300 }}
                    >
                    <Switch location={window.location}>
                        <Route exact path="/Concept1" render={(props) => <ParticularSectionPresentation {...props} previousPage = "Concept#Concept" nextPage = "Concept2" sectionName = "Concept1" backArrow ="Concept" downArrow ="Concept2" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />
                        <Route exact path="/Concept2" render={(props) => <ParticularSectionPresentation {...props} previousPage = "Concept1" nextPage = "Servizi1" sectionName = "Concept2" downArrow ="Servizi1" downArrowWrite="Servizi" backArrow ="Concept" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />
                        <Route exact path="/Servizi1" render={(props) => <ParticularSectionPresentation {...props} previousPage = "Concept2" nextPage = "Servizi2" sectionName = "Servizi1" backArrow ="Servizi" downArrow ="Servizi2" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />
                        <Route exact path="/Servizi2" render={(props) => <ParticularSectionPresentation {...props} previousPage = "Servizi1" nextPage = "Servizi3" sectionName = "Servizi2" backArrow ="Servizi" downArrow ="Servizi3" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />
                        <Route exact path="/Servizi3" render={(props) => <ParticularSectionPresentation {...props} previousPage = "Servizi2" nextPage = "Servizi4" sectionName = "Servizi3" backArrow ="Servizi" downArrow ="Servizi4" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />
                        <Route exact path="/Servizi4" render={(props) => <ParticularSectionPresentation {...props} previousPage = "Servizi3" nextPage = "Servizi5" sectionName = "Servizi4" backArrow ="Servizi" downArrow ="Servizi5" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />
                        <Route exact path="/Servizi5" render={(props) => <ParticularSectionPresentation {...props} previousPage = "Servizi4" nextPage = "Servizi6" sectionName = "Servizi5" backArrow ="Servizi" downArrow ="Servizi6" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />
                        <Route exact path="/Servizi6" render={(props) => <ParticularSectionPresentation {...props} previousPage = "Servizi5" nextPage = "DoveSiamo" sectionName = "Servizi6" backArrow ="Servizi" downArrow ="DoveSiamo" downArrowWrite="Dove Siamo" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />
                        <Route exact path="/DoveSiamo" render={(props) => <DoveSiamo {...props} previousPage = "Servizi6" nextPage = "Appartamenti1" sectionName = "DoveSiamo" downArrow ="Appartamenti1" downArrowWrite="Appartamenti" backArrow ="Servizi" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />
                    </Switch>
                    </CSSTransition>
                </TransitionGroup>
                <Switch>

                    <Route exact path="/Appartamenti1" render={(props) => <Apartments {...props} previousPage = "Servizi" nextPage = "Eventi" sectionName = "Appartamenti" rightArrow = "Appartamenti1" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />
                    <Route exact path="/apartment/:id" render={(props) => <SingleApartment {...props} previousPage = "Appartamenti1" nextPage = "Apt" sectionName = "Apt" backArrow ="Appartamenti1" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />
                    {/* <Route exact path="/Apt" render={(props) => <SingleApartment {...props} previousPage = "Appartamenti1" nextPage = "Apt" sectionName = "Apt" backArrow ="Appartamenti1"/>} /> */}


                    <Route exact path="/Eventi1" render={(props) => <EventPicker {...props} previousPage = "Servizi" nextPage = "Eventi" sectionName = "Eventi1" rightArrow = "Appartamenti1" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />
                    <Route exact path="/evento/:id" render={(props) => <SingleEventMobile {...props} lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />
                    <Route exact path="/CalendarioEventi" render={(props) => <CalendarioEventi {...props} previousPage = "Servizi" nextPage = "Eventi" sectionName = "Eventi1"  backArrow ="Eventi1" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />
                        {/* <Route exact path="/Aperitivo" render={(props) => <AperitivoInCasa {...props} previousPage = "Servizi" nextPage = "Eventi" sectionName = "AperitivoInCasa"  backArrow ="Eventi1"/>} /> */}


                    <Route exact path="/VicinoANoi1" render={(props) => <CioCheServe {...props} previousPage = "Servizi" nextPage = "Eventi" sectionName = "VicinoANoi1" rightArrow = "Appartamenti1" lan={this.state.lan} changeLanguage={this.changeLanguage}/>} />


                </Switch>
            </BrowserRouter>
        )
    }
}

export default Router;