import React, {Fragment} from 'react';
import "../style/style.css";
import { TransitionGroup, CSSTransition, Transition} from "react-transition-group";
import Logo from "./Logo"
import BackArrow from "./BackArrow";
import DownArrow from "./DownArrow";
import Header from "./Header";
import LanguageSelector from "./LanguageSelector";
import LanguageSelectorRelative from "./LanguageSelectorRelative";
import Footer from './Footer'


class CalendarioEventi extends React.Component{
    state = {
        inProp: false,
        margin: this.props.margin,
        open: false
    };

    async componentDidMount() {
        this.setState({open: true})
    }

    goBack = async () => {
        this.setState({open: false})
        setTimeout(() => {
            this.props.toggleCalendario()
        }, 800)
        // await this.props.history.push('/evento/'+id)
    }

    render(){
        const { events } = this.props
        return(
            <Transition in={this.state.open} timeout={100}>
                {state => <div
                    className={`evento-container-${state}`}
                >
                    <div className="calendario-eventi">
                        <div style={{flex: 1, display: 'flex', alignItems: 'center', paddingLeft: '2.5%'}}>
                            <div
                                // onClick={() => this.props.toggleCalendario()}
                                onClick={() => this.goBack()}
                                style={{display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer'}}>
                                <div style={{color: 'white', marginBottom: 4}}>
                                    {this.props.lan === "en" ? "Go Back" : "Indietro"}
                                </div>
                                <img
                                    className="backArrow"
                                    src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAS4AAAA8CAYAAADYKVdnAAAABHNCSVQICAgIfAhkiAAABOxJREFUeF7t3U/opVMcx/H340/EgiwYSUmaFCkLqSnZsLYgRUhNlJ2kTM1vRIxGkp2iKSFKWdhKymaQheykwV6xl9JHz687P/f3m3t/95zn361733d9zvfM8zqnT+c88zzPr8GfAgoo0EEgyVvAX03TnOnQvVeXpldvOyugwNYKzILrReBE0zRvTglhcE2p7VgKbJhAkveBZ6YOL4NrwxaSl6PA1AJJPgKenDK8DK6pZ9nxFNgwgSRtjnwJPDhVeBlcG7aIvBwF1iGQ5CrgO+CuKcLL4FrHLDumAhsokOQG4CfgyNjhZXBt4ALykhRYl0CS+4FvZuOP9r+NBte6ZthxFdhQgSSfAo+NGV4G14YuHi9LgXUJJLkO+A24dqzwMrjWNbuOq8AGCyR5Dnh37hJ3mqY5PdQlG1xDSVpHAQX2BJJcApwHbp1jGeyel8HlYlNAgVEEkjwOfHKg+CDhZXCNMmUWVUCBViDJz8DtQ4eXweX6UkCB0QSSPA18sGCAXjsvg2u0KbOwAgokuaL99A1w9ZDhZXC5thRQYFSBJGeB40sG6bTzMrhGnTKLK6BAkmPAuUMkTjVN83qNlMFVo2VbBRSoFph9PeJPoH0wddmvaudlcFVPgx0UUKBWIMnnwMMr+hWHl8FVOwO2V0CBaoEFT9L32nkZXNVTYAcFFKgVSHIU+KWw38p7XgZXoaTNFFCgn0CSv4H28YiS36HHRoOrhNA2CijQWyDJj8DdFYWWhpfBVaFoUwUU6C6QpH1vsX1/sea3MLwMrhpC2yqgQGeBJDvAax0KXHTPy+DqoGgXBRSoF0jyLPBefc/dHvt2Xk2SVzoWspsCCihQI3AH8EhNhwNt98KrDa70KGRXBRRQYEqB3fAyuKYkdywFFBhC4JTBNQSjNRRQYEqBEwbXlNyOpYACfQX2jorenO9LaX8FFCgRuLPgRevD6vx/c75kNNsooIACfQV6Pg6x71kun+PqOxv2V0CBIoEkZ4CXihrvb3TR0/MGVwdFuyigQL1Aki+Ahyp7+spPJZjNFVBgQIElf6qs6J7WwUbuuAacGEspoMBigdlftv4HuLTQyM/aFELZTAEFRhJIci/wfWH5lZ9wdsdVKGkzBRToLpDkVeDlggorQ6utYXAVSNpEAQX6CST5AbhnRZWi0DK4+s2FvRVQoEAgyfXAHyuarvzO/Hx/d1wF8DZRQIHuAkleAN4+pELxTutCDYOr+3zYUwEFCgSSnAduW9K0OrQ8Khag20QBBboLJDkGnBsytAyu7vNhTwUUKBBI8iHw1IKmO03TnC4osbCJR8WucvZTQIFDBZLcDPwOXHagYafj4XwNg8vFp4ACowgkOQscHzq0PCqOMl0WVUCBJbut3jutC7LuuFxjCigwuECSj4En5gqfbJrmjaEGMriGkrSOAgrsCiR5APhqjmOwnZY7LheZAgoMLpDkSuBX4KZZ8cFDy3tcg0+bBRXYboEk7wDPzxSqXuOpkfOoWKNlWwUUWCqQ5FHgszF3Wh4VXYAKKDCYQJL7gK+By4FRjofz/1h3XINNnYUU2E6BJEeB9rM11wCjHQ8Nru1cX161AoMLJLkF+Ba4cYqdlkfFwafQggpsl0CSI7OdVvtqz+jHQ3dc27W+vFoFRhGY+1zNJMdDg2uUabSoAtslkOQk8G/TNO0fep309x/j0Cs5myh5dQAAAABJRU5ErkJggg=="} />
                            </div>
                        </div>
                        <div style={{flex: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{flex: 1}} />
                            <div style={{
                                fontSize: '3.5em',
                                color: 'white',
                                flex: 3,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                {this.props.lan === "en" ? "Calendar" : "Calendario Eventi"}
                            </div>
                            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                <div className={this.state.additionalClass} style={{color: 'white', marginBottom: 55, transition: '300ms ease-in-out'}}>
                                    {this.props.lan === "en" ? "Scroll" : "Scorri"}
                                </div>
                                <img
                                    onMouseEnter={() => this.setState({additionalClass: 'moveUp'})}
                                    onMouseLeave={() => this.setState({additionalClass: ''})}
                                    className="downArrow"
                                    src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAS4AAAA8CAYAAADYKVdnAAAABHNCSVQICAgIfAhkiAAABOxJREFUeF7t3U/opVMcx/H340/EgiwYSUmaFCkLqSnZsLYgRUhNlJ2kTM1vRIxGkp2iKSFKWdhKymaQheykwV6xl9JHz687P/f3m3t/95zn361733d9zvfM8zqnT+c88zzPr8GfAgoo0EEgyVvAX03TnOnQvVeXpldvOyugwNYKzILrReBE0zRvTglhcE2p7VgKbJhAkveBZ6YOL4NrwxaSl6PA1AJJPgKenDK8DK6pZ9nxFNgwgSRtjnwJPDhVeBlcG7aIvBwF1iGQ5CrgO+CuKcLL4FrHLDumAhsokOQG4CfgyNjhZXBt4ALykhRYl0CS+4FvZuOP9r+NBte6ZthxFdhQgSSfAo+NGV4G14YuHi9LgXUJJLkO+A24dqzwMrjWNbuOq8AGCyR5Dnh37hJ3mqY5PdQlG1xDSVpHAQX2BJJcApwHbp1jGeyel8HlYlNAgVEEkjwOfHKg+CDhZXCNMmUWVUCBViDJz8DtQ4eXweX6UkCB0QSSPA18sGCAXjsvg2u0KbOwAgokuaL99A1w9ZDhZXC5thRQYFSBJGeB40sG6bTzMrhGnTKLK6BAkmPAuUMkTjVN83qNlMFVo2VbBRSoFph9PeJPoH0wddmvaudlcFVPgx0UUKBWIMnnwMMr+hWHl8FVOwO2V0CBaoEFT9L32nkZXNVTYAcFFKgVSHIU+KWw38p7XgZXoaTNFFCgn0CSv4H28YiS36HHRoOrhNA2CijQWyDJj8DdFYWWhpfBVaFoUwUU6C6QpH1vsX1/sea3MLwMrhpC2yqgQGeBJDvAax0KXHTPy+DqoGgXBRSoF0jyLPBefc/dHvt2Xk2SVzoWspsCCihQI3AH8EhNhwNt98KrDa70KGRXBRRQYEqB3fAyuKYkdywFFBhC4JTBNQSjNRRQYEqBEwbXlNyOpYACfQX2jorenO9LaX8FFCgRuLPgRevD6vx/c75kNNsooIACfQV6Pg6x71kun+PqOxv2V0CBIoEkZ4CXihrvb3TR0/MGVwdFuyigQL1Aki+Ahyp7+spPJZjNFVBgQIElf6qs6J7WwUbuuAacGEspoMBigdlftv4HuLTQyM/aFELZTAEFRhJIci/wfWH5lZ9wdsdVKGkzBRToLpDkVeDlggorQ6utYXAVSNpEAQX6CST5AbhnRZWi0DK4+s2FvRVQoEAgyfXAHyuarvzO/Hx/d1wF8DZRQIHuAkleAN4+pELxTutCDYOr+3zYUwEFCgSSnAduW9K0OrQ8Khag20QBBboLJDkGnBsytAyu7vNhTwUUKBBI8iHw1IKmO03TnC4osbCJR8WucvZTQIFDBZLcDPwOXHagYafj4XwNg8vFp4ACowgkOQscHzq0PCqOMl0WVUCBJbut3jutC7LuuFxjCigwuECSj4En5gqfbJrmjaEGMriGkrSOAgrsCiR5APhqjmOwnZY7LheZAgoMLpDkSuBX4KZZ8cFDy3tcg0+bBRXYboEk7wDPzxSqXuOpkfOoWKNlWwUUWCqQ5FHgszF3Wh4VXYAKKDCYQJL7gK+By4FRjofz/1h3XINNnYUU2E6BJEeB9rM11wCjHQ8Nru1cX161AoMLJLkF+Ba4cYqdlkfFwafQggpsl0CSI7OdVvtqz+jHQ3dc27W+vFoFRhGY+1zNJMdDg2uUabSoAtslkOQk8G/TNO0fep309x/j0Cs5myh5dQAAAABJRU5ErkJggg=="} />
                            </div>
                        </div>
                        <div style={{flex: 1}}></div>
                    </div>

                    <div style={{backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10vh 0', flexDirection: 'column'}}>
                        {events
                            .filter(event => new Date(event.data).toISOString() > new Date().toISOString())
                            .map(event => <div className="" style={{margin: '5vh 0', maxWidth: '100vh'}}>
                                <div className="" style={{overflow: 'hidden', backgroundColor: 'white', height: '50vh', display: 'flex', flexDirection: 'row'}}>
                                    <div style={{backgroundColor: '#ffe533', flex: 1}} />
                                    <div style={{
                                        height: '50vh',
                                        backgroundImage: `url("https://backoffice.arboutiqueapartments.it${event.sfondo.url}")`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        opacity: 0.8,
                                        zIndex: 0,
                                        flex: 10,
                                        width: '100vw'
                                    }} />
                                </div>
                                <div className="calendarioEventiTitle">
                                    {new Date(event.data).toLocaleDateString() + ' - ' + event.titolo}
                                </div>
                                <div
                                    className="calendarioEventiDescription"
                                    dangerouslySetInnerHTML={{ __html: event.descrizione}} />
                                    {/* {event.descrizione}
                                </div> */}
                            </div>)}
                    </div>
                    <Footer {...this.props} />
                </div>}
            </Transition>
        )
    }
}

export default CalendarioEventi;