import React, { Fragment } from "react";
import "../style/style.css";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { is } from "ramda";
import { isMobile } from "react-device-detect";
import ArrowDownMobile from "../img/arrow_down_mobile.png";

class Event extends React.Component {
  state = {
    inProp: false,
  };

  goToEvent = (id, margin) => {
    // console.log("clicked 2", id, margin);
    if (id === "xxx") {
      this.props.goToCalendario();
    } else {
      this.props.goToEvent(id, margin);
    }
  };

  onMouseDown = (id) => (event) => {
    // console.log("clicked 1");
    const mouseX = event.clientX;
    const screenX = window.innerWidth;
    this.goToEvent(id, (mouseX / screenX) * 100);
  };

  goToMobileEvent = (id) => {
    this.props.history.push("/evento/" + id);
  };

  render() {
    if (!!isMobile) {
      return (
        <div
          key={this.props.id}
          id={this.props.id}
          className="fadeSection"
          style={
            this.props.eventName !== "evento1"
              ? {
                  backgroundImage: `url("${this.props.image}")`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  opacity: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  backgroundPosition: "unset",
                  height: window.innerHeight,
                  scrollSnapAlign: "start",
                }
              : {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: window.innerHeight,
                  scrollSnapAlign: "start",
                }
          }
          className="evento1"
        >
          <TransitionGroup>
            <CSSTransition
              classNames={this.props.animation || ''}
              key={this.props.id}
              timeout={{ enter: 1400, exit: 0 }}
              in
              unmountOnExit
              appear
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  height: window.innerHeight,
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div style={{ flex: 3 }}></div>
                <div
                  style={{
                    flex: 4,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    flexDirection: "column",
                    padding: "0 24px",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: 46,
                      lineHeight: "56px",
                      color: "white",
                    }}
                  >
                    {this.props.name}
                  </div>
                  {this.props.date && (
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: 30,
                        lineHeight: "32px",
                        color: "white",
                      }}
                    >
                      {this.props.date}
                    </div>
                  )}
                  <img
                    onClick={() => this.goToMobileEvent(this.props.id)}
                    className="forwardArrow"
                    src={require("../img/Export_Asset/Forward_B_1.png")}
                    style={{ width: 136, zIndex: 1000 }}
                  />
                </div>
                <a
                  href={`#${this.props.nextEvent}`}
                  style={{
                    flex: 3,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!this.props.last && (
                    <img
                      // onClick={this.props.goToNextEvent}
                      // className="downArrow"
                      src={ArrowDownMobile}
                      style={{ marginBottom: 0, zIndex: 1000 }}
                    />
                  )}
                </a>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
        //     </CSSTransition>
        // </TransitionGroup>
      );
    } else {
      return (
        <Fragment>
          <div
            style={
              this.props.eventName !== "evento1"
                ? {
                    backgroundImage: `url("${this.props.image}")`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    opacity: 1,
                  }
                : null
            }
            // className="transition-item"
            className={this.props.eventName === "evento1" ? this.props.eventName : ''}
            onMouseDown={this.onMouseDown(this.props.id)}
            // onClick={() => this.goToEvent(this.props.id)}
          ></div>
          <div
            className="eventoText"
            style={
              this.props.name !== "Calendario Eventi"
                ? {
                    padding: "0 10%",
                    width: "80%",
                  }
                : {}
            }
            onMouseDown={this.onMouseDown(this.props.id)}
            // onClick={() => this.goToEvent(this.props.id)}
          >
            {this.props.name}
          </div>
          <div
            className="eventoDate"
            onMouseDown={this.onMouseDown(this.props.id)}
            // onClick={() => this.goToEvent(this.props.id)}
          >
            {this.props.date}
          </div>
        </Fragment>
      );
    }
  }
}

export default Event;
