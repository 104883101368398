export const translate = (data, id, target) => {
  const current = data.find(obj => obj.identificativo === id)
  if (!!current) {
    return current[`${target}`]
  } else {
    return id
  }
}

export const translateFloorName = (floorName, lan) => {
  switch (floorName) {
    case "Piano Terra":
      return lan === "en" ? "Ground Floor" : floorName
    case "Piano Primo":
      return lan === "en" ? "First Floor" : floorName
    case "Piano Secondo":
      return lan === "en" ? "Second Floor" : floorName
    case "Piano Terzo":
      return lan === "en" ? "Third Floor" : floorName
    case "Piano Quarto":
      return lan === "en" ? "Fourth Floor" : floorName
  }
}

export const translateAptType = (type, lan) => {
  switch (type) {
    case "Monolocale":
      return lan === "en" ? "One-room apartment" : type
    case "Bilocale":
      return lan === "en" ? "Two-room apartment" : type
    case "Trilocale":
      return lan === "en" ? "Three-room apartment" : type
    case "Quadrilocale":
      return lan === "en" ? "Four-room apartment" : type
  }
}