import React, { Component } from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import BankMarker from '../img/MapMarker/Bank_Map.svg'
import CinemaMarker from '../img/MapMarker/Cinema_Map.svg'
import HospitalMarker from '../img/MapMarker/Hospital_Map.svg'
import PharmacyMarker from '../img/MapMarker/Pharmacy_Map.svg'
import SupermarketMarker from '../img/MapMarker/Supermarket_Map.svg'
import ArLogoMarker from '../img/MapMarker/AR_logo_Map.svg'
 
export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  };

  onMarkerClick = place => (props, marker, e) =>
    this.setState({
      selectedPlace: place,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };
  renderMarker = (place) => {
    switch (place.type) {
      case '_banca': 
        return BankMarker
      case '_supermarket':
        return SupermarketMarker
      case '_farmacia':
        return PharmacyMarker
      case '_ospedale':
        return HospitalMarker
      case '_cinema':
        return CinemaMarker
      default:
        return ''
    }
  }

  render() {
    const { defaultCenter, places, height, width } = this.props;

    return (
      <Map
        onClick={this.onMapClicked}
        initialCenter={defaultCenter}
        google={this.props.google}
        zoom={14}
        disableDefaultUI={true}
        style={{
          width: width,
          height: height
        }}
      >
        <Marker
          icon={{
            url: ArLogoMarker,
            scaledSize: new this.props.google.maps.Size(60,60)
          }}
        />
        {this.props.places.map((place, index) => {
          return <Marker
            key={index}
            id={index}
            position={{
              lat: place.lat,
              lng: place.lon
            }}
            icon={{
              url: this.renderMarker(place),
              scaledSize: new this.props.google.maps.Size(40,40)
            }}
            onClick={this.onMarkerClick(place)} />
        })}
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}>
            <div>
              <div style={{fontSize: 18, fontWeight: 'bold', marginBottom: 8}}>{this.state.selectedPlace.name}</div>
              <div style={{fontSize: 16, marginBottom: 4}}>{'Indirizzo: ' + this.state.selectedPlace.indirizzo}</div>
              <div style={{fontSize: 16}}>{'Telefono: ' + this.state.selectedPlace.telefono}</div>
            </div>
        </InfoWindow>
      </Map>
    );
  }
}
 
export default GoogleApiWrapper({
  apiKey: "AIzaSyBQQANGO2pLqNazBVzV-KTwTXZ-omEe8wA"
})(MapContainer)