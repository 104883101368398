import React, {Fragment} from 'react';
import "../style/style.css";
import Logo from "./Logo";

import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import { TransitionGroup, CSSTransition} from "react-transition-group";
import DownArrow from "./DownArrow";


class Home extends React.Component {
    state = {
        previousPage: this.props.previousPage,
        nextPage: this.props.nextPage,
        inProp: true,
    };

    nextIndex = () => {
        const { nextPage } = this.state;
        this.state.inProp = false;
        this.props.history.push("/"+nextPage);

    };

    prevIndex = () => {
        const { previousPage } = this.state;
        this.props.history.push("/"+previousPage);

    };


    render(){
        return (
            <Fragment>
                <div className="home"></div>

                <Logo {...this.props} logoPosition = "logoCentered"/>
                <DownArrow {...this.props}  downArrow ={this.props.nextPage} classType ="menuDownButtonCentered"/>
                <ReactScrollWheelHandler
                    timeout={1000}
                    upHandler={this.prevIndex}
                    downHandler={this.nextIndex}
                    customStyle={{
                        width: "100%",
                        height: "100vh",
                    }}
                />
            </Fragment>

        );
    }
}

export default Home;