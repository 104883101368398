import React, {Fragment} from 'react';
import "../style/style.css";
import { TransitionGroup, CSSTransition} from "react-transition-group";
import Logo from "./Logo";
import Header from "./Header";
import Apartment from "./Apartment";
import LanguageSelector from "./LanguageSelector";
import axios from 'axios';
import { groupBy } from 'ramda';
import Loader from 'react-loader-spinner';
import Fade from 'react-reveal/Fade';
import logo from '../img/Export_Asset/Layer 2.png'
import MainHeader from './MainHeader'
import Footer from './Footer'
import { isMobile } from 'react-device-detect';
import { translateFloorName } from '../utils/translations';



class Apartments extends React.Component{
    state = {
        loading: true,
        apartments: [],
        show: false
    }
    
    async componentDidMount() {
        try {
            const result = await axios({
                url: 'https://backoffice.arboutiqueapartments.it/graphql',
                method: 'post',
                data: {
                    query: `
                        query {
                            appartamentos {
                                id
                                piano
                                identificativo
                                tipo
                                metratura
                                terrazza
                                disponibilita
                                disponibile
                                descrizione
                                piantina {
                                    id
                                    url
                                }
                                galleria {
                                    id
                                    url
                                }
                            }
                        }
                    `
                }
            })
            const byFloor = groupBy(function(apartment) {
                return apartment.piano
            })
            const apartments = result.data.data.appartamentos;

            const apartmentsByFloor = byFloor(apartments)
            this.setState({
                loading: false,
                apartments: Object.entries(apartmentsByFloor)
            })
        } catch (err) {
            console.log('err', err)
        }
    }

    triggerShow = (floor) => {
        if (this.state.show === floor) {
            this.setState({
                show: false
            })
        } else {
            this.setState({
                show: floor
            })
        }
    }

    render(){

        const { loading, apartments } = this.state
        return(
            <Fragment>
                <MainHeader
                    {...this.props}
                    inverted
                    withBack
                    backTarget={'Appartamenti#Appartamenti'}
                    background='white' />
                
                <div className="pianoZero"></div>
                {loading
                    ? <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '60vh',
                        }}
                    >
                        <Loader
                            type="Rings"
                            color="#91855D"
                            height={100}
                            width={100}
                        />
                    </div>
                    :   <div style={{marginTop: isMobile ? '16vh' : 0}}>
                            {apartments.map((floor,index) => {
                                return <Apartment
                                    key={`apartment_${index}`}
                                    id={`apartment_${index}`}
                                    show={this.state.show}
                                    triggerShow={this.triggerShow}
                                    floorName={translateFloorName("Piano " + floor[0], this.props.lan)}
                                    apartments={floor[1]}
                                    {...this.props}
                                    index ={index}
                                />
                            })}
                        </div>}
                {!isMobile && <div style={{marginTop: '4vh'}}>
                    <Footer {...this.props} />
                </div>}

            </Fragment>

        )
    }
}

export default Apartments;