import React, { Fragment } from "react";
import "../style/style.css";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Logo from "./Logo";
import Header from "./Header";
import Apartment from "./Apartment";
import LanguageSelector from "./LanguageSelector";
import axios from "axios";
import { groupBy } from "ramda";
import Loader from "react-loader-spinner";
import Fade from "react-reveal/Fade";
import logoInverted from "../img/Export_Asset/Layer 2.png";
import logo from "../img/Export_Asset/Layer -9.png";
import logoMobile from "../img/logo_mobile.png";
import { ReactComponent as CalendarIcon } from "../img/calendar_icon.svg";
import { isMobile } from "react-device-detect";

const routes = [
  {
    name: "Home",
    target: "/",
    targetMobile: "Home",
  },
  {
    name: "Concept",
    target: "Concept",
    targetMobile: "Concept",
  },
  {
    name: "Servizi",
    target: "Servizi",
    targetMobile: "Servizi",
  },
  {
    name: "Appartamenti",
    target: "Appartamenti",
    targetMobile: "Appartamenti",
  },
  {
    name: "Eventi",
    target: "Eventi",
    targetMobile: "Eventi",
  },
  {
    name: "Vicino a Noi",
    target: "VicinoANoi",
    targetMobile: "Vicino a noi",
  },
  {
    name: "Contatti",
    target: "Contact",
    targetMobile: "Contatti",
  },
];

class MainHeader extends React.Component {
  state = {
    loading: true,
    apartments: [],
    show: false,
  };

  goHome = () => {
    if (this.props.setAnimationHome) {
      this.props.setAnimationHome();
    }
    if (isMobile) {
      // console.log("isMobile");
      if (
        !!routes.find(
          (route) => route.target === this.props.history.location.pathname
        )
      ) {
        // console.log("scrolling");
        document.getElementById("main").scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } else {
        this.props.history.push("/");
      }
    } else {
      this.props.history.push("/");
    }
  };

  handleGoBack = () => {
    // console.log("clicked", this.props.backTarget);
    this.props.history.push("/" + this.props.backTarget);
  };

  render() {
    const {
      inverted,
      background,
      textColor,
      menuColor,
      withBack,
      backTarget,
      show,
      animated,
    } = this.props;
    // console.log("header lan", this.props.lan);
    return (
      <div
        style={{
          zIndex: 1201,
          position: this.props.position || "fixed",
          backgroundColor: background,
          height: isMobile ? "20vh" : "15vh",
          minHeight: 120,
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          top: this.props.top
            ? this.props.top
            : animated
            ? show
              ? 0
              : "-20vh"
            : "unset",
          transition: "top 600ms ease-in-out",
        }}
      >
        {!!isMobile && (
          <>
            <div style={{ flex: 1 }}>
              {!!withBack ? (
                <div
                  onClick={this.handleGoBack}
                  className={
                    inverted
                      ? "headerBackButtonMobile"
                      : "headerBackButtonMobileInverted"
                  }
                />
              ) : // ?   <img src={{uri: "../img/Export_Asset/Previous_B_1.svg"}} style={{height: 40, width: 40}} />
              null}
            </div>
          </>
        )}
        <div
          style={{
            padding: 36,
            cursor: "pointer",
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: isMobile ? "center" : "flex-start",
          }}
          // onClick={this.goHome}
        >
          <img
            alt="ar-logo"
            onClick={this.goHome}
            src={isMobile ? logoMobile : inverted ? logoInverted : logo}
            style={{ height: isMobile ? 72 : 50, marginTop: isMobile ? 0 : 0 }}
          />
        </div>
        <div
          style={{
            paddiing: 16,
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {!isMobile && (
            <div
              style={{
                alignItems: "center",
                color: textColor ? textColor : inverted ? "#91855d" : "white",
                display: "flex",
                flexDirection: "row",
                marginRight: 100,
                fontSize: 14,
              }}
            >
              <a
                href="https://www.arconceptlife.com/ar-boutique-apartments#widget-section"
                target="_blank"
                rel="noopener noreferrer"
                className="prenotaBtn"
              >
                {this.props.lan === "ita" ? "Prenota Ora" : "Book Now"}
              </a>
              <div style={{ padding: 4, marginRight: 24, marginTop: 1 }}>
                +39 035 5785067
              </div>
              <div
                onClick={() => this.props.changeLanguage("ita")}
                className="language-selection"
                style={{
                  fontWeight: this.props.lan === "ita" ? "bold" : "normal",
                }}
              >
                It
              </div>
              <div className="language-selection">|</div>
              <div
                onClick={() => this.props.changeLanguage("en")}
                className="language-selection"
                style={{
                  fontWeight: this.props.lan === "en" ? "bold" : "normal",
                }}
              >
                En
              </div>
            </div>
          )}
          <div
            style={
              !isMobile
                ? { position: "absolute", top: "45%", right: "4%" }
                : { position: "absolute", top: "40%", right: "16%" }
            }
          >
            <Header
              {...this.props}
              colorBurger={
                menuColor ? menuColor : inverted ? "#91855d" : "white"
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MainHeader;
