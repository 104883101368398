import React, {Fragment} from 'react';
import "../style/style.css";
import ig from '../img/instagram.png';
import fb from '../img/facebook-3-xl.png';
import styled from 'styled-components';
import { translate } from '../utils/translations';
import axios from 'axios'

const menuItems = [
  {
      name: 'Home',
      target: '/',
      identificativo: 'Home'
  },
  {
      name: 'Concept',
      target: '/Concept',
      identificativo: 'concept'
  },
  {
      name: 'Servizi',
      target: '/Servizi',
      identificativo: 'servizi'
  },
  {
      name: 'Appartamenti',
      target: '/Appartamenti',
      identificativo: 'appartamenti'
  },
  {
      name: 'Eventi',
      target: '/Eventi',
      identificativo: 'eventi'
  },
  {
      name: 'Vicino a Noi',
      target: '/VicinoANoi',
      identificativo: 'vicino'
  },
  {
      name: 'Contattaci',
      target: '/Contact',
      identificativo: 'contattaci'
  }]

class Footer extends React.Component{
    state = {
      sections: []
    }  

    async componentDidMount () {
      try {
        const result = await axios({
            url: 'https://backoffice.arboutiqueapartments.it/graphql',
            method: 'post',
            data: {
                query: `
                    query {
                        paginas {
                            id
                            identificativo
                            testo
                            Testo_en
                        }
                    }
                `
            }
        })
        this.setState({
            loading: false,
            sections: result.data.data.paginas
        })
      } catch (err) {
          console.log('err', err)
      }
    }

    goTo = (target) => {
      this.props.history.push(target)
    }
  
    render(){
      const { sections } = this.state
      return(
        <div style={{display: 'flex', alignItems: 'center', padding: '2vh 36px 6vh', background: 'white'}}>
            <div style={{flex: 3, display: 'flex', justifyContent: 'space-between'}}>
                <Link>|</Link>
                {menuItems.map(menu => 
                  <Link onClick={() => this.goTo(menu.target)}>
                    {/* {menu.name} */}
                    {translate(this.state.sections, menu.identificativo, this.props.lan === "en" ? "Testo_en" : "testo")}
                  </Link>)}
            </div>
            <div style={{flex: 1}} />
            <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
                <Link onClick={() => this.goTo('/privacy-policy')}>Privacy policy</Link>
                {!!sections.find(pagina => pagina.identificativo === 'instagram') &&
                  !!sections.find(pagina => pagina.identificativo === 'instagram')["testo"] && (
                  <a
                    href={sections.find(pagina => pagina.identificativo === 'instagram')["testo"]}
                    target="_blank"
                    style={{cursor: 'pointer'}}>
                    <img src={ig} style={{width: 24}} />
                  </a>
                )}
                {!!sections.find(pagina => pagina.identificativo === 'facebook') &&
                  !!sections.find(pagina => pagina.identificativo === 'facebook')["testo"] && (
                    <a
                      href={sections.find(pagina => pagina.identificativo === 'facebook')["testo"]}
                      target="_blank"
                      style={{cursor: 'pointer'}}>
                      <img src={fb} style={{width: 24}} />
                    </a>
                  )}
            </div>
        </div>
      )
    }
}

export default Footer;

const Link = styled.div`
  cursor: pointer;
  color: rgb(145, 133, 93);
  text-decoration: underline;
  :hover {
    color: #e6ce3c;
  }
`