import React, { Fragment } from "react";
import "../style/style.css";
import {
  TransitionGroup,
  CSSTransition,
  Transition,
} from "react-transition-group";
import Logo from "./Logo";
import BackArrow from "./BackArrow";
import DownArrow from "./DownArrow";
import Header from "./Header";
import LanguageSelector from "./LanguageSelector";
import LanguageSelectorRelative from "./LanguageSelectorRelative";
import axios from "axios";
import Loader from "react-loader-spinner";
import ApartmentMainImage from "./ApartmentMainImage";
import MainHeader from "./MainHeader";
import Lightbox from "react-image-lightbox";
import ReactModal from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Footer from "./Footer";
import { isMobile } from "react-device-detect";
import * as _ from "underscore";
import ArrowDownMobile from "../img/arrow_down_mobile.png";
import { translateAptType, translateFloorName } from "../utils/translations";
import ArrowBack from "../img/arrow_back_header.png";

class SingleApartment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inProp: true,
      loading: true,
      zoom: false,
      position: 0,
      showHeader: true,
      animationDownText: "sectionTitle4Apartment",
      animationDownArrow: "sectionTitleSingleApartment",
      animationBackArrow: "fadeInslideFromSxApartment",
      animationBackArrowWrite: "fadeInslideFromSx",
      goingNextApt: false,
      goingPrevApt: false,
      imagePopup: "",
      isOpen: false,
      focusImage: 0,
      galleryPosition: "145vh",
    };
    this.handleGalleryScrollDebounced = _.debounce(
      this.handleGalleryScroll,
      50
    );
    this.handleZoomGalleryScrollDebounced = _.debounce(
      this.handleZoomGalleryScroll,
      50
    );
    this._mobileGalley = null;
  }

  async componentDidMount() {
    window.addEventListener("scroll", this.onScroll);

    try {
      const result = await axios({
        url: "https://backoffice.arboutiqueapartments.it/graphql",
        method: "post",
        data: {
          query: `
            query {
                appartamentos (
                    sort: "identificativo:asc"
                ) {
                    id
                    piano
                    identificativo
                    tipo
                    metratura
                    terrazza
                    disponibile
                    disponibilita
                    virtual_toor
                    descrizione
                    Descrizione_en
                    piantina {
                    id
                    url
                    }
                    galleria {
                    id
                    url
                    }
                }
            }
        `,
        },
      });
      const apartments = result.data.data.appartamentos;
      const apartment = apartments.find(
        (apartment) => apartment.identificativo === this.props.match.params.id
      );
      const indexApt = apartments.findIndex(
        (apartment) => apartment.identificativo === this.props.match.params.id
      );
      const prevApt = indexApt === 0 ? null : apartments[indexApt - 1];
      const nextApt =
        indexApt === apartments.length - 1 ? null : apartments[indexApt + 1];
      this.setState({
        loading: false,
        apartments,
        apartment,
        prevApt,
        nextApt,
      });
    } catch (err) {
      console.log("err", err);
    }
    this.setGalleryPosition();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  handleKeyPress = (e) => {
    if (typeof this.state.zoomImage !== "undefined") {
      e.preventDefault();
      const { keyCode } = e;
      const galleria =
        (this.state.apartment &&
          this.state.apartment.galleria.map(
            (image) => `https://backoffice.arboutiqueapartments.it${image.url}`
          )) ||
        gallery;
      let nextIndex = undefined;

      // next
      if (keyCode === 39 || keyCode === 68) {
        if (this.state.zoomImage === galleria.length - 1) {
          nextIndex = 0;
        } else {
          nextIndex = this.state.zoomImage + 1;
        }
      }
      if (keyCode === 37 || keyCode === 65) {
        // back
        if (this.state.zoomImage === 0) {
          nextIndex = galleria.length - 1;
        } else {
          nextIndex = this.state.zoomImage - 1;
        }
      }

      if (typeof nextIndex !== "undefined") {
        this.setState({
          zoomImage: nextIndex,
        });
      }
    }
  };

  componentDidUpdate(oldProps, oldState) {
    if (oldState.position < this.state.position && this.state.position > 10) {
      this.setState({ showHeader: false });
    }
    if (oldState.position > this.state.position) {
      const triggerColor = (window.innerHeight * 85) / 100;
      if (this.state.position >= triggerColor) {
        this.setState({ showHeader: true, headerInverted: true });
      } else {
        this.setState({ showHeader: true, headerInverted: false });
      }
    }
    if (oldProps.match.params.id !== this.props.match.params.id) {
      const apartments = this.state.apartments;
      const apartment = apartments.find(
        (apartment) => apartment.identificativo === this.props.match.params.id
      );
      const indexApt = apartments.findIndex(
        (apartment) => apartment.identificativo === this.props.match.params.id
      );
      const prevApt = indexApt === 0 ? null : apartments[indexApt - 1];
      const nextApt =
        indexApt === apartments.length - 1 ? null : apartments[indexApt + 1];
      this.setState({
        loading: false,
        apartments,
        apartment,
        prevApt,
        nextApt,
      });
      window.scrollTo(0, 0);
      setTimeout(() => this.setGalleryPosition(), 500);
    }
    if (this.state.zoomImage !== oldState.zoomImage) {
      if (this._mobileGalley) {
        setTimeout(() => {
          this._mobileGalley.scrollLeft = 0;
        }, 200);
      }
    }
  }

  setGalleryPosition = () => {
    const div =
      document.getElementsByClassName("leftPart4") &&
      document.getElementsByClassName("leftPart4")[0];
    const offsets = div ? div.getBoundingClientRect() : 0;
    const pageHeight = window.innerHeight;
    this.setState({
      galleryPosition:
        (div ? div.clientHeight : 0) +
        offsets.top +
        window.pageYOffset -
        (pageHeight * 50) / 100,
    });
  };

  goToApt = (id, direction) => {
    if (direction === "prev") {
      this.setState({ goingPrevApt: true });
    } else {
      this.setState({ goingNextApt: true });
    }
    this.setState({ animationDownText: "fadeInslideFromSxApartmentSingle" });
    this.setState({
      animationDownArrow: "fadeInslideFromSxApartmentDownArrow",
    });
    this.setState({ animationBackArrow: "fadeInslideFromSxApartment" });
    this.setState({
      animationBackArrowWrite: "fadeInslideFromSxApartmentSingle",
    });
    this.setState({
      zoomImage: undefined,
      focusImage: undefined,
    });
    // this.props.history.push('/apartment/'+id)
    setTimeout(() => {
      this.props.history.push("/apartment/" + id);
      if (direction === "prev") {
        this.setState({ goingPrevApt: false });
      } else {
        this.setState({ goingNextApt: false });
      }
    }, 750);
    // window.location.reload()
  };

  goBack = (event) => {
    event.preventDefault();
    const backArrow = this.state.backArrow;
    this.props.history.push("/" + this.props.backArrow);
  };

  handleZoom = () => {
    this.setState({
      zoom: !this.state.zoom,
      showHeader: false,
    });
  };

  goContact = (event) => {
    event.preventDefault();
    this.props.history.push("/Contact");
  };

  onScroll = () => {
    this.setState({
      position: window.pageYOffset,
    });
  };

  handleGalleryScroll = () => {
    const div = document.getElementById("gallery");
    // console.log(div.scrollTop)
    // console.log(div.scrollLeft);
    const width = (window.innerWidth * 80) / 100;
    // console.log("focus:", Math.round(div.scrollLeft / width));
    this.setState({
      focusImage: Math.round(div.scrollLeft / width),
    });
  };

  handleZoomGalleryScroll = () => {
    const div = document.getElementById("zoom_gallery");
    // console.log(div.scrollTop)
    // console.log(div.scrollLeft);
    const width = (window.innerWidth * 90) / 100;
    // console.log("focus:", Math.round(div.scrollLeft / width));
    this.setState({
      focusImage: Math.round(div.scrollLeft / (width - 40)),
    });
  };

  handleZoomImage = (zoomImage, focusImage) => {
    this.setState({ zoomImage, focusImage });
  };

  resetZoomImage = () => {
    this.setState({ zoomImage: undefined });
  };

  render() {
    const { imagePopup, isOpen } = this.state;
    const { loading, apartment, prevApt, nextApt } = this.state;
    // console.log('zoom', this.state.zoom)
    const galleria =
      (this.state.apartment &&
        this.state.apartment.galleria.length > 0 &&
        this.state.apartment.galleria.map(
          (image) => `https://backoffice.arboutiqueapartments.it${image.url}`
        )) ||
      gallery;
    // console.log(galleria);
    // console.log("state", this.state);
    return (
      <div onKeyDown={this.handleKeyPress} tabIndex="0">
        {this.state.showHeader && (
          <MainHeader
            {...this.props}
            inverted={this.state.headerInverted}
            withBack
            backTarget={"Appartamenti1"}
            background={
              isMobile && this.state.headerInverted ? "white" : "transparent"
            }
          />
        )}
        <TransitionGroup>
          <CSSTransition
            classNames={this.state.animationDownText}
            key={this.props.location.key}
            timeout={{ enter: 1000, exit: 1000000000000 }}
            in={this.state.inProp}
            appear
            unmountOnExit={true}
          >
            <Fragment>
              <div
                className="apartmentHeroImage"
                style={{
                  backgroundImage: `url(${require(`../img/hero/apt_${this.props.match.params.id}_hero.webp`)})`,
                }}
              >
                <div className="backgroundBlackMainImage" />
              </div>
            </Fragment>
          </CSSTransition>
        </TransitionGroup>
        {!isMobile && (
          <TransitionGroup>
            <CSSTransition
              classNames={this.state.animationDownText}
              key={this.props.location.key}
              timeout={{ enter: 1000, exit: 1000 }}
              in={this.state.inProp}
              appear
              unmountOnExit={true}
            >
              <Fragment>
                <div
                  className={
                    "simpleDownArrowWrite" + " " + this.state.additionalClass
                  }
                >
                  {this.props.lan === "en" ? "Scroll Down" : "Scorri in Basso"}{" "}
                  {this.props.index}
                </div>
              </Fragment>
            </CSSTransition>
          </TransitionGroup>
        )}
        <TransitionGroup>
          <CSSTransition
            // classNames={`${this.state.animationDownArrow}${isMobile ? 'Mobile' : ''}`}
            classNames={`${this.state.animationDownArrow}Mobile`}
            key={this.props.location.key}
            timeout={{ enter: 1000, exit: 1000 }}
            in={this.state.inProp}
            appear
            unmountOnExit={true}
          >
            <div
              onMouseEnter={() => this.setState({ additionalClass: "moveUp" })}
              onMouseLeave={() => this.setState({ additionalClass: "" })}
              className="simpleDownArrowMobile bounceOnHover"
            ></div>
          </CSSTransition>
        </TransitionGroup>

        {!isMobile && (
          <TransitionGroup>
            <CSSTransition
              classNames={this.state.animationBackArrowWrite}
              key={this.props.location.key}
              timeout={{ enter: 1000, exit: 1000 }}
              in={this.state.inProp}
              appear
              unmountOnExit={true}
            >
              <div className="apartmentBackButtonWrite" onClick={this.goBack}>
                {this.props.lan === "en" ? "Go Back" : "Indietro"}
              </div>
            </CSSTransition>
          </TransitionGroup>
        )}

        {!isMobile && (
          <TransitionGroup>
            <CSSTransition
              classNames={this.state.animationBackArrow}
              key={this.props.location.key}
              timeout={{ enter: 1000, exit: 1000 }}
              in={this.state.inProp}
              appear
              unmountOnExit={true}
            >
              <div className="apartmentBackButton" onClick={this.goBack}></div>
            </CSSTransition>
          </TransitionGroup>
        )}

        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "60vh",
            }}
          >
            <Loader type="Rings" color="white" height={100} width={100} />
          </div>
        ) : (
          <Fragment>
            <TransitionGroup>
              <CSSTransition
                classNames={this.state.animationDownText}
                key={this.props.location.key}
                timeout={{ enter: 1000, exit: 1000 }}
                in={this.state.inProp}
                appear
                unmountOnExit={true}
              >
                <Fragment>
                  <div className="apartmentTitle">
                    {this.props.lan === "en" ? "Apartment" : "Appartamento"}
                  </div>
                </Fragment>
              </CSSTransition>
            </TransitionGroup>
            <TransitionGroup>
              <CSSTransition
                classNames={this.state.animationDownText}
                key={this.props.location.key}
                timeout={{ enter: 1000, exit: 1000 }}
                in={this.state.inProp}
                appear
                unmountOnExit={true}
              >
                <Fragment>
                  <div className="apartmentNumber">
                    <span style={{ fontSize: "48px", lineHeight: "60px" }}>
                      N°{" "}
                    </span>
                    {apartment.identificativo}
                  </div>
                </Fragment>
              </CSSTransition>
            </TransitionGroup>
          </Fragment>
        )}

        {!isMobile && (
          <div className="availabilityAndContact">
            <span className="aptInfo">
              {apartment && !!apartment.disponibile
                ? this.props.lan === "en"
                  ? "Available"
                  : "Disponibile"
                : this.props.lan === "en"
                ? "Unavailable"
                : "Non Disponibile"}
            </span>
            {apartment && apartment.disponibile && (
              <span className="aptInfo">
                {this.props.lan === "en" ? "From" : "Da"}{" "}
                {!loading &&
                  new Date(apartment.disponibilita).toLocaleDateString()}
              </span>
            )}
            <span className="aptInfoContattaci" onClick={this.goContact}>
              {this.props.lan === "en" ? "Contact us" : "Contattaci"}
            </span>
          </div>
        )}
        {!loading &&
          (!isMobile ? (
            <Fragment>
              <div className="infoTable">
                <div className="leftPart">
                  <div className="leftPart1">
                    {translateFloorName(
                      `Piano ${apartment.piano}`,
                      this.props.lan
                    )}
                  </div>
                  <hr className="hr1" />
                  <div className="leftPart2">
                    {translateAptType(apartment.tipo, this.props.lan)}
                  </div>
                  <hr className="hr2" />
                  {apartment.terrazza && (
                    <>
                      <div className="leftPart3">
                        {!apartment.terrazza && "No"} Terrazza
                      </div>
                      <hr className="hr3" />
                    </>
                  )}
                  <div
                    className="leftPart4"
                    style={{
                      width: "20%",
                      top: !apartment.terrazza ? "17vh" : "",
                      lineHeight: 1.5,
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        this.props.lan === "en"
                          ? apartment.Descrizione_en
                          : apartment.descrizione,
                    }}
                  />
                </div>
                <hr className="verticalLine" />
                <div className="rightPart">
                  <div className="squaredMeters">
                    {apartment.metratura} m<sup style={{ fontSize: 13 }}>2</sup>
                  </div>
                  <div
                    className="singleApartmentImage"
                    style={{ position: "absolute", left: "52%", top: "22vh" }}
                  >
                    <img
                      style={{ maxWidth: "40%" }}
                      src={
                        "https://backoffice.arboutiqueapartments.it" +
                        apartment.piantina.url
                      }
                    ></img>
                    <img
                      className={"zoomIn"}
                      onClick={() =>
                        this.setState({
                          zoom: true,
                          showHeader: false,
                          imagePopup:
                            "https://backoffice.arboutiqueapartments.it" +
                            apartment.piantina.url,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div
                className="tableSetting"
                style={{ top: this.state.galleryPosition }}
              >
                <Masonry
                  gallery={galleria}
                  onImageClick={(zoomImage, focusImage) =>
                    this.setState({ zoomImage, focusImage })
                  }
                />
              </div>

              {apartment.virtual_toor && (
                <iframe
                  style={{
                    position: "absolute",
                    top: `calc(310vh - 145vh + ${this.state.galleryPosition}px)`,
                    left: "16vw",
                    width: "68vw",
                    height: "38vw",
                  }}
                  frameBorder="0"
                  initload={1}
                  sd={1}
                  allowFullScreen
                  allow="xr-spatial-tracking; gyroscope; accelerometer"
                  scrolling="no"
                  src={apartment.virtual_toor}
                ></iframe>
              )}

              {prevApt && (
                <Transition in={this.state.goingPrevApt} timeout={50}>
                  {(state) => (
                    <div
                      style={{
                        // top: `calc(${apartment.virtual_toor ? 360 : 310}vh - 145vh + ${
                        top: `calc(310vh + ${
                          apartment.virtual_toor ? 45 : 0
                        }vw - 145vh + ${this.state.galleryPosition}px)`,
                      }}
                      className={`previousRoomButton-${state}`}
                      onClick={() =>
                        this.goToApt(prevApt.identificativo, "prev")
                      }
                    >
                      {"Apt " + prevApt.identificativo}
                    </div>
                  )}
                </Transition>
              )}
              {nextApt && (
                <Transition in={this.state.goingNextApt} timeout={50}>
                  {(state) => (
                    <div
                      style={{
                        // top: `calc(${apartment.virtual_toor ? 360 : 310}vh - 145vh + ${
                        top: `calc(310vh + ${
                          apartment.virtual_toor ? 45 : 0
                        }vw - 145vh + ${this.state.galleryPosition}px)`,
                      }}
                      className={`nextRoomButton-${state}`}
                      onClick={() =>
                        this.goToApt(nextApt.identificativo, "next")
                      }
                    >
                      {"Apt " + nextApt.identificativo}
                    </div>
                  )}
                </Transition>
              )}
              <div
                className="absoluteBottomMenu"
                style={{
                  // top: `calc(${apartment.virtual_toor ? 370 : 320}vh - 145vh + ${
                  top: `calc(320vh + ${
                    apartment.virtual_toor ? 45 : 0
                  }vw - 145vh + ${this.state.galleryPosition}px)`,
                }}
              >
                <Footer {...this.props} />
              </div>
            </Fragment>
          ) : (
            <div
              style={{
                position: "absolute",
                marginTop: 0,
                width: "100%",
                top: "85vh",
                padding: "40px 0",
              }}
            >
              <div style={{ padding: "0 15%" }}>
                <div>
                  <div style={{ fontSize: 32, color: "#91855d" }}>
                    {translateFloorName(
                      `Piano ${apartment.piano}`,
                      this.props.lan
                    )}
                  </div>
                  <div
                    style={{
                      height: 2,
                      backgroundColor: "#91855d",
                      marginTop: 16,
                      width: "100%",
                    }}
                  />
                  <div
                    style={{ fontSize: 32, color: "#91855d", paddingTop: 16 }}
                  >
                    {apartment.tipo}
                  </div>
                  <div
                    style={{
                      height: 2,
                      backgroundColor: "#91855d",
                      marginTop: 16,
                      width: "100%",
                    }}
                  />
                  {apartment.terrazza && (
                    <>
                      <div
                        style={{
                          fontSize: 32,
                          color: "#91855d",
                          paddingTop: 16,
                        }}
                      >
                        {!apartment.terrazza && "No"} Terrazza
                      </div>
                      <div
                        style={{
                          height: 2,
                          backgroundColor: "#91855d",
                          marginTop: 16,
                          width: "100%",
                        }}
                      />
                    </>
                  )}
                  <div
                    // className="leftPart4"
                    style={{
                      width: "100%",
                      marginTop: 24,
                      color: "#333",
                      lineHeight: "24px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        this.props.lan === "en"
                          ? apartment.Descrizione_en
                          : apartment.descrizione,
                    }}
                  />
                </div>
                <div
                  style={{
                    height: 2,
                    backgroundColor: "#91855d",
                    marginTop: 16,
                    width: "100%",
                  }}
                />
                <div>
                  <div
                    style={{ fontSize: 32, color: "#91855d", paddingTop: 16 }}
                  >
                    {apartment.metratura} m<sup style={{ fontSize: 13 }}>2</sup>
                  </div>
                  <div style={{ marginTop: 32 }}>
                    <img
                      onClick={() =>
                        this.setState({
                          zoom: true,
                          imagePopup:
                            "https://backoffice.arboutiqueapartments.it" +
                            apartment.piantina.url,
                          showHeader: false,
                        })
                      }
                      style={{ width: "100%" }}
                      src={
                        "https://backoffice.arboutiqueapartments.it" +
                        apartment.piantina.url
                      }
                    ></img>
                  </div>
                </div>
              </div>

              <div
                id="gallery"
                onScroll={this.handleGalleryScrollDebounced}
                style={{
                  marginTop: 100,
                  display: "flex",
                  flexDirection: "row",
                  overflowX: "scroll",
                  scrollSnapType: "x mandatory",
                }}
              >
                {galleria.map((image, i) => (
                  <img
                    key={`gallery_mobile_img_${i}`}
                    onClick={() => this.handleZoomImage(i + 1, 0)}
                    // onClick={() => this.setState({ zoomImage: i + 1, focusImage: 0 })}
                    style={{
                      minWidth: "80vw",
                      height: this.state.focusImage === i ? 300 : 260,
                      objectFit: "cover",
                      marginTop: this.state.focusImage === i ? 0 : 20,
                      marginBottom: this.state.focusImage === i ? 0 : 20,
                      transition: "height 200ms ease, margin 200ms ease",
                      scrollSnapAlign: "center",
                    }}
                    src={image}
                  />
                ))}
              </div>

              {apartment.virtual_toor && (
                <iframe
                  style={{
                    marginTop: 80,
                    width: "100vw",
                    height: "40vh",
                  }}
                  frameborder="0"
                  initload={1}
                  sd={1}
                  allowfullscreen
                  allow="xr-spatial-tracking; gyroscope; accelerometer"
                  scrolling="no"
                  src={apartment.virtual_toor}
                ></iframe>
              )}

              <div style={{ margin: "80px 0", display: "flex" }}>
                {prevApt ? (
                  <Transition in={this.state.goingPrevApt} timeout={50}>
                    {(state) => (
                      <div
                        className={`previousRoomButtonMobile-${state}`}
                        onClick={() =>
                          this.goToApt(prevApt.identificativo, "prev")
                        }
                      >
                        {prevApt.identificativo}
                      </div>
                    )}
                  </Transition>
                ) : (
                  <div style={{ width: "50vw" }} />
                )}
                {nextApt && (
                  <Transition in={this.state.goingNextApt} timeout={50}>
                    {(state) => (
                      <div
                        className={`nextRoomButtonMobile-${state}`}
                        onClick={() =>
                          this.goToApt(nextApt.identificativo, "next")
                        }
                      >
                        {nextApt.identificativo}
                      </div>
                    )}
                  </Transition>
                )}
              </div>
            </div>
          ))}

        {this.state.zoom && (
          <TransitionGroup>
            <CSSTransition
              classNames="fadeShort"
              key={this.props.location.key}
              timeout={{ enter: 200, exit: 1000 }}
              in={this.state.inProp}
              unmountOnExit={true}
              appear
            >
              <div
                onClick={() => this.handleZoom()}
                style={{
                  position: "fixed",
                  touchAction: "none",
                  zIndex: 1000,
                  height: isMobile ? "100%" : "100vh",
                  width: "100vw",
                  backgroundColor: "rgba(0,0,0,0.9)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ maxWidth: "90%", maxHeight: "90%" }}
                  src={
                    "https://backoffice.arboutiqueapartments.it" +
                    apartment.piantina.url
                  }
                ></img>
                <div
                  className="crossOverlay"
                  style={{ right: isMobile ? "10vw" : "3vw" }}
                />
              </div>
            </CSSTransition>
          </TransitionGroup>
        )}

        {typeof this.state.zoomImage !== "undefined" && (
          <TransitionGroup>
            <CSSTransition
              classNames="fadeShort"
              // key={this.props.location.key}
              timeout={{ enter: 200, exit: 1000 }}
              in={this.state.inProp}
              unmountOnExit={true}
              appear
            >
              <div
                // onClick={() => this.handleZoom()}
                style={{
                  position: "fixed",
                  touchAction: "none",
                  zIndex: 2000,
                  height: isMobile ? "100vh" : "100vh",
                  width: "100vw",
                  backgroundColor: "rgba(0,0,0,0.9)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "scroll",
                }}
              >
                {isMobile ? (
                  <div
                    ref={(ref) => (this._mobileGalley = ref)}
                    id="zoom_gallery"
                    // onScroll={this.handleZoomGalleryScrollDebounced}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      overflowX: "scroll",
                      scrollSnapType: "x mandatory",
                    }}
                  >
                    {galleria.map((image, i) => (
                      <img
                        key={`gallery_img_${i}`}
                        style={{
                          width: "90vw",
                          marginRight: "2%",
                          marginLeft: "2%",
                          // height: this.state.focusImage === i ? 300 : 260,
                          // marginTop: this.state.focusImage === i ? 0 : 20,
                          // marginBottom: this.state.focusImage === i ? 0 : 20,
                          height: "60vh",
                          objectFit: "contain",
                          transition: "height 500ms ease, margin 500ms ease",
                          scrollSnapAlign: "center",
                        }}
                        src={image}
                      />
                    ))}
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "0 48px",
                    }}
                  >
                    <div
                      onClick={() =>
                        this.handleKeyPress({
                          keyCode: 37,
                          preventDefault: () => {},
                        })
                      }
                      style={{ color: "white", cursor: "pointer" }}
                    >
                      <img src={ArrowBack} />
                    </div>
                    <img
                      key={`gallery_img_${this.state.zoomImage}`}
                      style={{
                        width: "90%",
                        height: "80vh",
                        objectFit: "contain",
                        marginRight: "2%",
                        marginLeft: "2%",
                        transition: "height 500ms ease, margin 500ms ease",
                        scrollSnapAlign: "center",
                      }}
                      src={galleria[this.state.zoomImage]}
                    />
                    <div
                      onClick={() =>
                        this.handleKeyPress({
                          keyCode: 39,
                          preventDefault: () => {},
                        })
                      }
                      style={{ color: "white", cursor: "pointer" }}
                    >
                      <img
                        src={ArrowBack}
                        style={{ transform: "rotate(180deg)" }}
                      />
                    </div>
                  </div>
                )}
                <div
                  className="crossOverlay"
                  onClick={this.resetZoomImage}
                  style={{ right: isMobile ? "10vw" : "3vw" }}
                />
              </div>
            </CSSTransition>
          </TransitionGroup>
        )}

        {isOpen && (
          <Lightbox
            mainSrc={imagePopup}
            onCloseRequest={() => this.setState({ isOpen: false })}
            enableZoom={false}
          />
        )}

        {/* <div>
            <iframe
            frameborder="0"
            allowfullscreen
            allow="xr-spatial-tracking; gyroscope; accelerometer"
            scrolling="no"
            src="https://kuula.co/share/collection/7YxW9?fs=1&vr=0&zoom=1&sd=1&gyro=0&initload=0&thumbs=3&info=0&logo=1&logosize=40"
            ></iframe>
        </div> */}
      </div>
    );
  }
}

const gallery = [
  require("../img/riempitivi/WEB/Horizontal WEB/apartment-bed-bedroom-545034.jpg"),
  require("../img/riempitivi/WEB/Horizontal WEB/nirzar-pangarkar-CswKfD546Z8-unsplash.jpg"),
  require("../img/riempitivi/WEB/Horizontal WEB/erick-lee-hodge-el_V6z_h5nA-unsplash.jpg"),
  require("../img/riempitivi/WEB/Horizontal WEB/crew-1pJjYDJW5HM-unsplash.jpg"),
  require("../img/riempitivi/WEB/Horizontal WEB/erick-lee-hodge-el_V6z_h5nA-unsplash.jpg"),
  require("../img/riempitivi/WEB/Horizontal WEB/kari-shea-zUNQBoXlV5w-unsplash.jpg"),
  require("../img/riempitivi/WEB/Vertical WEB/kaylee-garrett-GDh2CXyQjmM-unsplash.jpg"),
  require("../img/riempitivi/WEB/Vertical WEB/element5-digital-2cwqJgtQ3D0-unsplash.jpg"),
  require("../img/riempitivi/WEB/Horizontal WEB/apartment-bed-bedroom-545034.jpg"),
  require("../img/riempitivi/WEB/Horizontal WEB/nirzar-pangarkar-CswKfD546Z8-unsplash.jpg"),
  require("../img/riempitivi/WEB/Horizontal WEB/erick-lee-hodge-el_V6z_h5nA-unsplash.jpg"),
  require("../img/riempitivi/WEB/Horizontal WEB/crew-1pJjYDJW5HM-unsplash.jpg"),
  require("../img/riempitivi/WEB/Horizontal WEB/erick-lee-hodge-el_V6z_h5nA-unsplash.jpg"),
  require("../img/riempitivi/WEB/Horizontal WEB/kari-shea-zUNQBoXlV5w-unsplash.jpg"),
  require("../img/riempitivi/WEB/Vertical WEB/kaylee-garrett-GDh2CXyQjmM-unsplash.jpg"),
  require("../img/riempitivi/WEB/Vertical WEB/element5-digital-2cwqJgtQ3D0-unsplash.jpg"),
  // require("../img/riempitivi/WEB/Horizontal WEB/apartment-bed-bedroom-545034.jpg"),
  // require("../img/riempitivi/WEB/Horizontal WEB/nirzar-pangarkar-CswKfD546Z8-unsplash.jpg"),
  // require("../img/riempitivi/WEB/Horizontal WEB/erick-lee-hodge-el_V6z_h5nA-unsplash.jpg"),
  // require("../img/riempitivi/WEB/Horizontal WEB/crew-1pJjYDJW5HM-unsplash.jpg"),
  // require("../img/riempitivi/WEB/Horizontal WEB/erick-lee-hodge-el_V6z_h5nA-unsplash.jpg"),
  // require("../img/riempitivi/WEB/Horizontal WEB/kari-shea-zUNQBoXlV5w-unsplash.jpg"),
  // require("../img/riempitivi/WEB/Vertical WEB/kaylee-garrett-GDh2CXyQjmM-unsplash.jpg"),
  // require("../img/riempitivi/WEB/Vertical WEB/element5-digital-2cwqJgtQ3D0-unsplash.jpg"),
  // require("../img/riempitivi/WEB/Horizontal WEB/apartment-bed-bedroom-545034.jpg"),
  // require("../img/riempitivi/WEB/Horizontal WEB/nirzar-pangarkar-CswKfD546Z8-unsplash.jpg"),
  // require("../img/riempitivi/WEB/Horizontal WEB/erick-lee-hodge-el_V6z_h5nA-unsplash.jpg"),
  // require("../img/riempitivi/WEB/Horizontal WEB/crew-1pJjYDJW5HM-unsplash.jpg"),
  // require("../img/riempitivi/WEB/Horizontal WEB/erick-lee-hodge-el_V6z_h5nA-unsplash.jpg"),
  // require("../img/riempitivi/WEB/Horizontal WEB/kari-shea-zUNQBoXlV5w-unsplash.jpg"),
  // require("../img/riempitivi/WEB/Vertical WEB/kaylee-garrett-GDh2CXyQjmM-unsplash.jpg"),
  // require("../img/riempitivi/WEB/Vertical WEB/element5-digital-2cwqJgtQ3D0-unsplash.jpg"),
];

const Masonry = (props) => {
  // console.log("masonry props", props);
  const gallery = props.gallery || gallery;
  const breakpoints = {
    1: 1,
    2: 2,
    3: 2,
    4: 2,
    5: 3,
    6: 3,
    7: 3,
    8: 3,
    9: 3,
    10: 4,
    11: 4,
    12: 4,
    13: 4,
    14: 4,
    15: 4,
  };
  const numCols = breakpoints[gallery.length] || 5;
  const cols = gallery.reduce((acc, curr, i) => {
    const ind = i % numCols;
    acc[ind] = [...(acc[ind] || []), curr];
    return acc;
  }, []);
  const _handleImageClick = (index) => {
    props.onImageClick(index, 0);
  };
  return (
    <div
      style={{
        position: "relative",
        top: "66vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "70vw", display: "flex", height: "80vh" }}>
        {cols.map((col, colIndex) => {
          return (
            <div
              key={`col_${colIndex}`}
              style={{
                width: `${100 / numCols}%`,
                height: "80vh",
                marginLeft: colIndex !== 0 ? "2%" : 0,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              {col.map((image, imageIndex) => {
                const startingHeight = 100 / col.length;
                const delta =
                  20 / (col.length % 2 === 0 ? col.length : col.length - 1);
                const deltaHeight =
                  col.length > 1
                    ? colIndex % 2 === 0
                      ? imageIndex === col.length - 1
                        ? 0
                        : imageIndex % 2 === 0
                        ? delta
                        : -delta
                      : imageIndex === 0
                      ? 0
                      : imageIndex % 2 === 0
                      ? -delta
                      : delta
                    : 0;
                const margin = 6;
                const height = startingHeight + deltaHeight;
                return (
                  <img
                    key={`image_${imageIndex}_col_${colIndex}`}
                    onClick={() =>
                      _handleImageClick(colIndex + imageIndex * col.length)
                    }
                    src={image}
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      height: `${height}%`,
                      marginTop: imageIndex === 0 ? 0 : `${margin}%`,
                      cursor: "pointer",
                    }}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SingleApartment;
